import React from 'react'
import { Modal } from 'antd';

export const ProfileEditModal = (props) => {
    const [visible, setVisible] = React.useState(false);

    const handleCancel = () => {
        setVisible(false);
        props.handleClose();
    };

    return (
        <>
            <Modal
                visible={props.modalOpen}
                onCancel={handleCancel}
                footer={null}
            >
                <p>{props.valueIntoModal}</p>
            </Modal>
        </>
    );
};