import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import assign from "lodash/assign";
import { Segment, Grid, Form, Button, Menu, TextArea, Checkbox } from "semantic-ui-react";

import * as ProblemActions from "../../actions/ProblemActions";
import { isLoggedIn } from "../../utils/cookieUtil"; 
import NotFound from "../../Components/Error/NotFound";


class AdminProblemCreateContainer extends Component {
  constructor(props) {
    super(props);
    this.saveProblem = this.saveProblem.bind(this);

    this.state = {
      name: '',
      timeLimit: null,
      memoryLimit: null,
    };
  }

  receiveEditorContent(content) {
    this.setState({ content: content });
  }

  saveProblem() {
    this.props.createProblem(this.state);
  }

  onChangeName = (event) => this.setState({ name: event.target.value, nameEdited: true });
  onChangeMemoryLimit = (event) => this.setState({ memoryLimit: event.target.value, memoryEdited: true });
  onChangeTimeLimit = (event) => this.setState({ timeLimit: event.target.value, timeEdited: true });
 
  render() {
    const problemCreateDiv = (
      <>
          <Form>
              <Form.Group widths='equal'>
                  <Form.Input fluid label='Name' value={this.state.name} onChange={this.onChangeName} />
                  <Form.Input fluid label='TimeLimit' placeholder='seconds' value={this.state.timeLimit} onChange={this.onChangeTimeLimit} />
                  <Form.Input fluid label='MemoryLimit' placeholder='megabyte' value={this.state.memoryLimit} onChange={this.onChangeMemoryLimit} />
              </Form.Group>
          </Form>
          <Button
              onClick={this.saveProblem}
              style={{ marginTop: "10px" }}
          >
              Next
          </Button>
      </>
    );

    return isLoggedIn() ? (
      <div className="main-container">
        <Grid stackable columns={1}>
          <Grid.Column width={16}>
            <Segment raised>
              <div className="foc-header">
                <div className="title" style={{ textAlign: "center" }}>
                  <span className="text-style" style={{ fontSize: "22px" }}>
                    Create a New Problem in CodePanja
                  </span>
                </div>
              </div>
              {problemCreateDiv}
            </Segment>
          </Grid.Column>
        </Grid>
      </div>
    ) : 
    <div className="main-container">
      <NotFound statusCode={401} />
    </div>
  }
}

// Connect with "contest" reducer
const mapStateToProps = (state) => {
  return { data: assign({}, state.contest, { user: state.user }) };
};

// Connect with "ContestActions"
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(assign(ProblemActions), dispatch);
};

// Connect reducer and action together
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminProblemCreateContainer);
