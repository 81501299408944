import React, { Component } from "react";
import { Segment } from "semantic-ui-react";

class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.title = "Privacy Policy";
  }

  render() {
    return (
      <div className="main-container">
        <Segment>
          <h2 className="base-color">Privacy Policy</h2>
          <p>Last Updated: 07/01/2022</p>
          <p>
          codepanja.com ("Codepanja," "we," or "us") is dedicated to protecting your privacy. This Privacy Policy outlines how we collect, use, disclose, transfer, and store your information. If you have any questions regarding this policy, please reach out to us.
          </p>
          <p>
          If you do not agree with the collection and use of your information as described in this Privacy Policy, you are not permitted to use Codepanja or any services provided on https://codepanja.com.
          </p>
          <h2 className="base-color">Applicable Law</h2>
          <p>
          Codepanja is based in Japan, specifically in Tokyo. By accessing our content or using our services, you agree to the transfer, collection, storage, and processing of your information in Japan under Japanese law.
          </p>
          <h2 className="base-color">Information You Provide</h2>
          <p>We retain information that you submit through our site, such as through forms, surveys, or other interactive features. This typically includes, but is not limited to, your name and email address.</p>
          <h2 className="base-color">Log Files</h2>
          <p>
          We gather information when you use our services. This may include your IP address, device and software details (such as type and operating system), page views, referral URLs, device identifiers, and carrier information. This data is primarily used to improve user experience.
          </p>
          <h2 className="base-color">Cookies</h2>
          <p>We use cookies and related technologies to remember user preferences and activity. Cookies are small text files created by a web server, delivered via a web browser, and stored on your computer. Most browsers automatically accept cookies. You can modify your browser settings to refuse cookies or to alert you when a cookie is being sent.</p>
          <h2 className="base-color">Third Party Services</h2>
          <p>
          Our site includes links to other websites not owned by Codepanja. Generally, the third-party services we use will only collect, use, and disclose your information as necessary to perform their services. Please note that we are not responsible for the privacy practices of these third-party services.
          </p>
          <h2 className="base-color">Children's Privacy</h2>
          <p>
          Codepanja adheres to laws protecting children's privacy. We do not intentionally collect data from or market to children under 13. We encourage parents and guardians to notify us if they believe a child has provided us with personal information without their consent. We will take steps to delete this information.
          </p>
          <h2 className="base-color">Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy or need any help, You can contact
            us:
          </p>
          <ul>
            <li>By email: atul110121@gmail.com</li>
          </ul>
        </Segment>
      </div>
    );
  }
}

export default PrivacyPolicy;
