import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import assign from "lodash/assign";
import { Form, Button, Icon, Header, Segment, Checkbox, Dimmer, Loader, Input, Modal, Image } from "semantic-ui-react";
import { COUNTRY, ORGANIZATION, DEPT } from "../constants";
import { Dropdown } from "semantic-ui-react";
import { isLoggedIn } from "../utils/cookieUtil";
import { ProfileEditModal } from "../Components/Modal/ProfileEditModal";

import * as UserActions from "../actions/userActions";
import WarningMessage from "../Components/Warning/WarningMessage";

class ProfileEditContainer extends Component {
  constructor(props) {
    super(props);
    this.createCountryOptions = this.createCountryOptions.bind(this);
    this.createDeptOptions = this.createDeptOptions.bind(this);
    this.createOrganizationOptions = this.createOrganizationOptions.bind(this);
    this.onChangeCountry = this.onChangeCountry.bind(this);
    this.onChangeOrganization = this.onChangeOrganization.bind(this);
    this.onChangeDept = this.onChangeDept.bind(this);
    this.onChangeUserName = this.onChangeUserName.bind(this);
    this.onChangeBkashPhoneNumber = this.onChangeBkashPhoneNumber.bind(this);
    this.onChangeFullName = this.onChangeFullName.bind(this);
    this.onChangeRoll = this.onChangeRoll.bind(this);
    this.onChangeGithubUrl = this.onChangeGithubUrl.bind(this);
    this.onChangeLinkedInUrl = this.onChangeLinkedInUrl.bind(this);
    this.onClickSubscribed = this.onClickSubscribed.bind(this);
    this.save = this.save.bind(this);

    this.state = {
      page: 1,
      countries: [],
      organizations: [],
      depts: [],
      countryCode: '',
      userName: '',
      fullName: '',
      organization: '',
      dept: '',
      roll: '',
      bkashPhoneNumber: '',
      githubProfileUrl: '',
      linkedinProfileUrl: '',
      showFileSizeError: false,
      showFileTypeError: false,
      subscribed: false,
      modalOpen: false,
      loading: false,
      isSubmitted: false,
      avatarUrl: null,
      valueIntoModal: 'Successfully updated profile information.'
    };
  }

  componentDidMount() {
    if (!isLoggedIn()) {
      window.location.href = "/";
    }
    this.props.fetchProfileInfo();
    this.createCountryOptions();
    this.createOrganizationOptions();
    this.createDeptOptions();
    document.title = "Profile";
  }

  componentWillReceiveProps(nextProps) {
    const profile = nextProps.data.profileInfo;
    this.setState({
      countryCode: profile.countryCode,
      userName: profile.userName,
      fullName: profile.fullName,
      linkedinProfileUrl: profile.linkedinProfileUrl,
      githubProfileUrl: profile.githubProfileUrl,
      subscribed: profile.subscribed,
      bkashPhoneNumber: profile.bkashPhoneNumber,
      organization: profile.organization,
      dept: profile.dept,
      roll: profile.roll,
      avatarUrl: profile.avatarUrl
    })
  }

  onChangeCountry(event, data) {
    // not working
    this.setState({ countryCode: data.value });
    if (data.value !== 'bd') {
      this.setState({ bkashPhoneNumber: '' });
    }
  }

  onChangeOrganization(event, data) {
    this.setState({ organization: data.value });
  }

  onChangeDept(event, data) {
    this.setState({ dept: data.value });
  }

  onChangeUserName(event, data) {
    if (data.value.length <= 45 && data.value.length >= 2) {
      this.setState({ userName: data.value });
    }
  }

  onChangeFullName(event, data) {
    if (data.value.length <= 45) {
      this.setState({ fullName: data.value });
    }
  }

  onChangeRoll(event, data) {
    if (data.value.length <= 15) {
      this.setState({ roll: data.value });
    }
  }

  onChangeBkashPhoneNumber(event, data) {
    if (data.value.length <= 12) {
      this.setState({ bkashPhoneNumber: data.value });
    }
  }

  onChangeGithubUrl(event, data) {
    if (data.value.length <= 250) {
      this.setState({ githubProfileUrl: data.value });
    }
  }

  onChangeLinkedInUrl(event, data) {
    if (data.value.length <= 250) {
      this.setState({ linkedinProfileUrl: data.value });
    }
  }

  onClickSubscribed(event) {
    this.setState({ subscribed: !this.state.subscribed });
  }

  createCountryOptions() {
    let countryOptions = [];
    COUNTRY.forEach(c => {
      const a = {
        key: c.countryCode,
        value: c.countryCode,
        flag: c.countryCode,
        text: c.name
      };
      countryOptions.push(a);
    });
    this.setState({ countries: countryOptions });
  }

  createOrganizationOptions() {
    let organizationOptions = [];
    ORGANIZATION.forEach(c => {
      const a = {
        key: c.id,
        value: c.name,
        text: c.name
      };
      organizationOptions.push(a);
    });
    this.setState({ organizations: organizationOptions });
  }

  createDeptOptions() {
    let deptOptions = [];
    DEPT.forEach(c => {
      const a = {
        key: c.id,
        value: c.name,
        text: c.name
      };
      deptOptions.push(a);
    });
    this.setState({ depts: deptOptions });
  }

  fileInputRef = React.createRef();

  onFormSubmit = e => {
    e.preventDefault(); // Stop form submit
    // this.state.file contains the file
  };

  fileChange = e => {
    const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    const file = e.target.files[0];
    this.setState({ file: file, fileSizeInKB: file.size / 1024 }, () => {
      if (this.state.fileSizeInKB > 5000) {
        this.setState({ showFileSizeError: true, showFileTypeError: false });
      } else if (file && !acceptedImageTypes.includes(file['type'])) {
        this.setState({ showFileTypeError: true, showFileSizeError: false });
      }
    });
  };

  onCloseErrorModal() {
    this.setState({ showFileSizeError: false, showFileTypeError: false, file: null })
  }

  save() {
    if (this.state.userName && this.state.fullName) {
      this.setState({ loading: true })
      if (this.state.file && !this.state.showFileSizeError && !this.state.showFileTypeError) {
        this.props.uploadAvatar(this.state.file);
      }
      this.props.updateProfileInfo(this.state);
    } else {
      this.setState({ valueIntoModal: 'FullName or UserName can not be empty.' })
    }
    setTimeout(function () {
      this.setState({ modalOpen: true, loading: false })
    }.bind(this), 5000);
  }

  render() {
    const code = this.state.countryCode ? this.state.countryCode : "";
    const fullName = this.state.fullName ? this.state.fullName : "";
    const userName = this.state.userName ? this.state.userName : "";
    const roll = this.state.roll ? this.state.roll : "";
    const dept = this.state.dept ? this.state.dept : "";
    const organization = this.state.organization ? this.state.organization : "";
    const bkashPhoneNumber = this.state.bkashPhoneNumber ? this.state.bkashPhoneNumber : "";
    const githubUrl = this.state.githubProfileUrl ? this.state.githubProfileUrl : "";
    const linkedInUrl = this.state.linkedinProfileUrl ? this.state.linkedinProfileUrl : "";
    const subscribed = this.state.subscribed ? this.state.subscribed : false;

    const loadingDiv = (
      <div style={{ textAlign: "center" }}>
        <Icon loading name='spinner' />
      </div>
    );

    const saveButtonDiv = this.state.loading ? loadingDiv : (
      <Button
        className="button-style"
        hidden={!this.state.userName || !this.state.fullName}
        onClick={() => this.save()}
      >
        Save
      </Button>
    );

    const avatarFileNameDiv =
      this.state.file == null ? null : (
        <div style={{ marginTop: "10px" }}>
          <Icon color="brown" name="angle double up" /> {this.state.file.name}
        </div>
      );

    const errorModal = (
      <Modal
        closeIcon
        open={this.state.showFileSizeError || this.state.showFileTypeError}
        onClose={() => this.onCloseErrorModal()}
        size="tiny"
        dimmer="blurring"
      >
        <Modal.Content>
          <WarningMessage
            title={this.state.showFileSizeError ? "Image size error" : "Image type not supported."}
            message={this.state.showFileSizeError ? "Please upload image less than 5000 KB" : "Please upload png/jpeg image"}
          />
        </Modal.Content>
      </Modal>
    );

    const submitFileDiv = this.state.isSubmitted ?
      <Dimmer active inverted>
        <Loader inverted>Submitting</Loader>
      </Dimmer> : (
        <div className="avatar-upload-div">
          <Form onSubmit={this.onFormSubmit}>
            <Form.Field>
              <Button
                className="button-style"
                size="medium"
                content="Upload Profile Picture"
                labelPosition="left"
                icon="cloud upload"
                onClick={() => this.fileInputRef.current.click()}
              />
              <input
                ref={this.fileInputRef}
                type="file"
                hidden
                onChange={this.fileChange}
              />
            </Form.Field>
          </Form>
          <div style={{ marginTop: 5, color: "#C4C5C6" }}>
            <Icon name="hand point right" />
            Please upload PNG/JPEG image
          </div>
          {avatarFileNameDiv}
        </div>
      );

    const renderDiv = userName === '' ?
      <Dimmer active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer> : (
        [
          <div className="main-container profile-edit" key="value1">
            <Segment raised>
              <Header as="h3" textAlign="center" className="base-color">
                Edit your personal infomation
              </Header>
              <Form>
                <Form.Group widths='equal'>
                  <Form.Input
                    fluid label='Full name'
                    placeholder='Full name'
                    value={fullName}
                    onChange={this.onChangeFullName}
                  />
                  <Form.Input
                    fluid label='Username'
                    placeholder='Username'
                    value={userName}
                    onChange={this.onChangeUserName}
                  />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Form.Input
                    fluid label='Github Profile Link'
                    placeholder='Profile link'
                    value={githubUrl}
                    onChange={this.onChangeGithubUrl}
                  />
                  <Form.Input
                    fluid label='LinkedIn Profile Link'
                    placeholder='Profile link'
                    value={linkedInUrl}
                    onChange={this.onChangeLinkedInUrl}
                  />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Form.Input
                    fluid label='Country'
                  >
                    <Dropdown
                      label="Country"
                      placeholder='Select Country'
                      fluid
                      search
                      selection
                      value={code}
                      options={this.state.countries}
                      onChange={this.onChangeCountry}
                      className="filter-dropdown-color"
                    />
                  </Form.Input>
                  <Form.Input
                    fluid label='Bkash Phone Number to get prize money (Only if you are from Bangladesh)'
                    placeholder='Mobile Number'
                    value={bkashPhoneNumber}
                    onChange={this.onChangeBkashPhoneNumber}
                    disabled={this.state.countryCode !== 'bd'}
                  />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Form.Input
                    fluid label='Organization'
                  >
                    <Dropdown
                      placeholder='Select Organization'
                      fluid
                      search
                      selection
                      clearable
                      value={organization}
                      options={this.state.organizations}
                      onChange={this.onChangeOrganization}
                      className="filter-dropdown-color"
                    />
                  </Form.Input>
                  <Form.Input
                    fluid label='Dept'
                  >
                    <Dropdown
                      placeholder='Select Dept'
                      fluid
                      search
                      selection
                      clearable
                      value={dept}
                      options={this.state.depts}
                      onChange={this.onChangeDept}
                      className="filter-dropdown-color"
                    />
                  </Form.Input>
                  <Form.Input
                    fluid label='ID/Roll'
                    placeholder='Roll'
                    value={roll}
                    onChange={this.onChangeRoll}
                  />
                </Form.Group>

                <span>
                  <div style={{ marginTop: "20px", marginBottom: "10px" }}>
                    <Checkbox onClick={this.onClickSubscribed} checked={subscribed} label="Subscribed for events" />
                  </div>
                </span>
                <Image src={this.state.avatarUrl} size='small' />
                {submitFileDiv}
              </Form>

              <div
                style={{
                  textAlign: "center",
                  marginTop: "10px"
                }}
              >
                {saveButtonDiv}
                {errorModal}
              </div>
            </Segment>
          </div >,
          <ProfileEditModal // The invisible modal itself
            key="value2"
            modalOpen={this.state.modalOpen}
            handleClose={
              () => {
                this.setState({ modalOpen: false })
              }
            }
            valueIntoModal={this.state.valueIntoModal}
          />]
      );

    return <>{renderDiv}</>

  }
}

// Connect with "user" reducer
const mapStateToProps = (state) => {
  return { data: assign({}, state.user) };
};

// Connect with "UserActions"
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(assign(UserActions), dispatch);
};

// Connect reducer and action together
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileEditContainer);
