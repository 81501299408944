import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import assign from "lodash/assign";
import { Segment, Grid, Form, Button, Menu, Dimmer, Icon, Loader, Modal, Checkbox, Table, Dropdown } from "semantic-ui-react";
import * as ProblemActions from "../../actions/ProblemActions";
import WarningMessage from "../../Components/Warning/WarningMessage";
import { isLoggedIn } from "../../utils/cookieUtil";
import NotFound from "../../Components/Error/NotFound";

const ACTIVE_ITEM_BASIC_INFO = 'basicInfo';
const ACTIVE_ITEM_PROBLEM_FILE = 'problemFile';
const ACTIVE_ITEM_TEST_CASE_LIST = 'testCaseList';
const ACTIVE_ITEM_TEST_CASE_NEW = 'testCaseNew';
const ACTIVE_ITEM_TEST_SOLUTION = 'testSolution';
const ACTIVE_ITEM_PROBLEM_SETTER = 'problemSetter';

class AdminProblemDetailContainer extends Component {
    constructor(props) {
        super(props);
        this.saveProblem = this.saveProblem.bind(this);
        this.saveTestCase = this.saveTestCase.bind(this);
        this.addTestCase = this.addTestCase.bind(this);
        this.addProblemFile = this.addProblemFile.bind(this);
        this.addProblemSetter = this.addProblemSetter.bind(this);
        this.handleProblemSetterDropdown = this.handleProblemSetterDropdown.bind(this);

        this.state = {
            description: '',
            name: '',
            timeLimit: '',
            memoryLimit: '',
            activeItem: ACTIVE_ITEM_BASIC_INFO,
            input: '',
            output: '',
            isSampleTestCase: false,
            explanation: '',
            problemFileSizeInKB: 0,
            inputFileSizeInKB: 0,
            outputFileSizeInKB: 0,
            showProblemFileSizeError: false,
            showInputFileSizeError: false,
            showInputFileMissingError: false,
            showOutputFileSizeError: false,
            showOutputFileMissingError: false,
            showProblemFileMissingError: false,
            showIsLoggedInError: false,
            isSaving: false,
            showFileTypeError: false,
            problemFileExists: false,
            problemId: '',
            testCaseList: [],
            problemSetterId: '',
            problemSetterName: '',
            dropdownOpen: false,
        };
    }

    componentDidMount() {
        const pathname = window.location.pathname;
        const problemId = pathname.substring(pathname.lastIndexOf('/') + 1)
        this.props.fetchProblemForAdmin(problemId);
        this.props.fetchProblemSetters();
    }

    componentDidUpdate(prevProps) {
      if (prevProps.data !== this.props.data) {
        if (this.props.data.detail) {
          this.setState({ 
            problemFileExists: this.props.data.detail.docFile !== null ? true : false,
            problemId: this.props.data.detail.id,
            testCaseList: this.props.data.detail.testCaseList
          });
        }
      }
    }

    problemFileButtonRef = React.createRef();
    inputFileButtonRef = React.createRef();
    outputFileButtonRef = React.createRef();

    onFormSubmit = (e) => {
      e.preventDefault(); // Stop form submit
      // this.state.file contains the file
    };

    problemFileChange = (e) => {
      this.setState(
        { problemFile: e.target.files[0], problemFileSizeInKB: e.target.files[0].size / 1024 },
        () => {
          if (this.state.problemFileSizeInKB > 10000) {
            this.setState({ showProblemFileSizeError: true });
            this.setState({ showProblemFileMissingError: true });
          } else {
            this.setState({ showProblemFileMissingError: false });
          }
        }
      );
    };

    inputFileChange = (e) => {
        this.setState(
          { inputFile: e.target.files[0], inputFileSizeInKB: e.target.files[0].size / 1024 },
          () => {
            if (this.state.inputFileSizeInKB > 10000) {
              this.setState({ showInputFileSizeError: true });
              this.setState({ showInputFileMissingError: true });
            } else {
              this.setState({ showInputFileMissingError: false });
            }
          }
        );
    };

    outputFileChange = (e) => {
        this.setState(
          { outputFile: e.target.files[0], outputFileSizeInKB: e.target.files[0].size / 1024 },
          () => {
            if (this.state.outputFileSizeInKB > 10000) {
              this.setState({ showOutputFileSizeError: true });
              this.setState({ showOutputFileMissingError: true });
            } else {
              this.setState({ showOutputFileMissingError: false });
            }
          }
        );
    };

    onRedirectToProblemPage(problemId) {
      window.location.href = '/problems/' + problemId;
    }

    onClickMenu(itemName) {
        this.setState({ activeItem: itemName })
    }

    receiveEditorContent(content) {
        this.setState({ description: content, descriptionEdited: true });
    }

    saveProblem() {
        const detail = this.props.data.detail;
        const request = {
            description: this.state.description ? this.state.description : detail.description,
            name: this.state.name ? this.state.name : detail.name,
            timeLimit: this.state.timeLimit ? this.state.timeLimit : detail.timeLimit,
            memoryLimit: this.state.memoryLimit ? this.state.memoryLimit : detail.memoryLimit
        }
        this.setState({ isSaving: true });
        this.props.updateProblem(detail.id, request);
    }

    saveTestCase() {

    }

    deleteTestCase(id) {
      this.props.deleteTestCase(this.state.problemId, id);
    }


    downloadInputFile(url) {
      const fileName = url.split('/').pop();
      const aTag = document.createElement("a");
      aTag.href = url;
      aTag.setAttribute("download", fileName);
      document.body.appendChild(aTag);
      aTag.click();
      aTag.remove();
    }

    downloadOutputFile(url) {
      const fileName = url.split('/').pop();
      const aTag = document.createElement("a");
      aTag.href = url;
      aTag.setAttribute("download", fileName);
      document.body.appendChild(aTag);
      aTag.click();
      aTag.remove();
    }

    addTestCase() {
        if (!isLoggedIn()) {
          this.setState({ showIsLoggedInError: true });
        } else if (this.state.inputFileSizeInKB > 10000) {
          this.setState({ showInputFileSizeError: true });
        } else if (this.state.outputFileSizeInKB > 10000) {
          this.setState({ showOutputFileSizeError: true });
        } else if (!this.state.inputFile) {
          this.setState({ showInputFileMissingError: true });
        } else if (!this.state.outputFile) {
          this.setState({ showOutputFileMissingError: true });
        } else {
          const request = {
            inputFile: this.state.inputFile,
            outputFile: this.state.outputFile,
            isSampleTestCase: this.state.isSampleTestCase,
          };
          this.setState({ isSaving: true });
          this.props.addTestCase(this.props.data.detail.id, request);
        }
    }

    addProblemFile() {
      if (this.state.problemFile)
      if (!isLoggedIn()) {
        this.setState({ showIsLoggedInError: true });
      } else if (this.state.problemFileSizeInKB > 10000) {
        this.setState({ showProblemFileSizeError: true });
      } else if (!this.state.problemFile) {
        this.setState({ showProblemFileMissingError: true });
      } else {
        if (this.state.problemFile.type !== 'application/pdf') {
          this.setState({ showFileTypeError: true });
          return;
        }
        const request = {
          problemFile: this.state.problemFile
        };
        this.setState({ isSaving: true });
        this.props.addProblemFile(this.props.data.detail.id, request);
      }
  }

    setFileSizeErrorModalOpen() {
        this.setState({ showInputFileSizeError: true });
        this.setState({ showOutputFileSizeError: true });
        this.setState({ showProblemFileSizeError: true });
    }

    onCloseErrorModal() {
      window.location.reload();
    }

    showFileSizeErrorModal() {
        return this.state.showInputFileSizeError 
        || this.state.showOutputFileSizeError
        || this.state.showProblemFileSizeError;
    }

    addProblemSetter() {
      this.props.addProblemSetter(this.state.problemId, this.state.problemSetterId);
    }

    onChangeName = (event) => this.setState({ name: event.target.value, nameEdited: true });
    onChangeMemoryLimit = (event) => this.setState({ memoryLimit: event.target.value, memoryEdited: true });
    onChangeTimeLimit = (event) => this.setState({ timeLimit: event.target.value, timeEdited: true });
    onChangeInput = (event) => this.setState({ input: event.target.value });
    onChangeOutput = (event) => this.setState({ output: event.target.value });
    onToggleSampleTest = (event) => this.setState({ isSampleTestCase: !this.state.isSampleTestCase });
    onChangeExplanation = (event) => this.setState({ explanation: event.target.value });
    onToggleDropdown= (event) => this.setState({ dropdownOpen: !this.state.dropdownOpen });

    handleProblemSetterDropdown(user) {
      this.setState({ problemSetterId: user.id });
      this.setState({ problemSetterName: user.fullName });
      this.setState({ dropdownOpen: !this.state.dropdownOpen });
    }

    render() {
        const { activeItem } = this.state;
        const detail = this.props.data.detail;
        const problemSetters = this.props.data.user.problemSetters;
        const name = this.state.nameEdited ? this.state.name : detail ? detail.name : '';
        const memoryLimit = this.state.memoryEdited ? this.state.memoryLimit : detail ? detail.memoryLimit : '';
        const timeLimit = this.state.timeEdited ? this.state.timeLimit : detail ? detail.timeLimit : '';
        const statusCode = this.props.data.statusCode;
        
        const pageMenu = (
            <Menu secondary className="contest-menu">
                <Menu.Item
                    className="contest-page-menu"
                    name='Basic Info'
                    active={activeItem === ACTIVE_ITEM_BASIC_INFO}
                    onClick={() => this.onClickMenu(ACTIVE_ITEM_BASIC_INFO)}
                />
                <Menu.Item
                    className="contest-page-menu"
                    name='Problem File'
                    active={activeItem === ACTIVE_ITEM_PROBLEM_FILE}
                    onClick={() => this.onClickMenu(ACTIVE_ITEM_PROBLEM_FILE)}
                />
                <Menu.Item
                    className="contest-page-menu"
                    name='List Test Cases'
                    active={activeItem === ACTIVE_ITEM_TEST_CASE_LIST}
                    onClick={() => this.onClickMenu(ACTIVE_ITEM_TEST_CASE_LIST)}
                />
                <Menu.Item
                    className="contest-page-menu"
                    name='Create Test Case'
                    active={activeItem === ACTIVE_ITEM_TEST_CASE_NEW}
                    onClick={() => this.onClickMenu(ACTIVE_ITEM_TEST_CASE_NEW)}
                />
                <Menu.Item
                    className="contest-page-menu"
                    name='Test your solution'
                    active={activeItem === ACTIVE_ITEM_TEST_SOLUTION}
                    onClick={() => this.onClickMenu(ACTIVE_ITEM_TEST_SOLUTION)}
                />
                <Menu.Item
                    className="contest-page-menu"
                    name='Problem Setter'
                    active={activeItem === ACTIVE_ITEM_PROBLEM_SETTER}
                    onClick={() => this.onClickMenu(ACTIVE_ITEM_PROBLEM_SETTER)}
                />
            </Menu>
        );

        const problemDetailDiv = this.state.isSaving ?
          <Dimmer active inverted>
              <Loader inverted>Saving</Loader>
          </Dimmer> : (
            <>
                <Form>
                    <Form.Group widths='equal'>
                        <Form.Input fluid label='Name' value={name} onChange={this.onChangeName} />
                        <Form.Input fluid label='TimeLimit' placeholder='seconds' value={timeLimit} onChange={this.onChangeTimeLimit} />
                        <Form.Input fluid label='MemoryLimit' placeholder='megabyte' value={memoryLimit} onChange={this.onChangeMemoryLimit} />
                    </Form.Group>

                </Form>
                <Button
                    onClick={this.saveProblem}
                    style={{ marginTop: "10px" }}
                >
                    Save
                </Button>
            </>
        );

        const testCaseListDiv = this.state.testCaseList.map((testCase) => {  
          return (
            <Table.Body key={testCase.id}>
              <Table.Row>
                <Table.Cell>{testCase.number}</Table.Cell>
                <Table.Cell>{testCase.sampleTestCase ? "Sample Test" : "Not Sample Test"}</Table.Cell>
                <Table.Cell>
                  <div>
                    <Button
                        style={{ background: "#f4f1f1", paddingLeft: "3.5em" }}
                        size="mini"
                        content="Download"
                        labelPosition="left"
                        icon="cloud download"
                        onClick={() => this.downloadInputFile(testCase.inputFileSignedUrl)}
                    />
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <div>
                      <Button
                        style={{ background: "#f4f1f1", paddingLeft: "3.5em" }}
                        size="mini"
                        content="Download"
                        labelPosition="left"
                        icon="cloud download"
                        onClick={() => this.downloadOutputFile(testCase.outputFileSignedUrl)}
                      />
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <div>
                    <Button
                        style={{ background: "#f4f1f1", paddingLeft: "3.5em" }}
                        size="mini"
                        content="Delete"
                        labelPosition="left"
                        icon="delete"
                        onClick={() => this.deleteTestCase(testCase.id)}
                    />
                  </div>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          );
        });

        const testCasesDiv = this.state.testCaseList.length > 0 ?
          <Table basic="very" unstackable className="table-scrollable">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className="base-color"># No</Table.HeaderCell>
                <Table.HeaderCell className="base-color">Type</Table.HeaderCell>
                <Table.HeaderCell className="base-color">Input File</Table.HeaderCell>
                <Table.HeaderCell className="base-color">Output File</Table.HeaderCell>
                <Table.HeaderCell className="base-color">Delete</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {testCaseListDiv}
          </Table> : <div>No test case created yet.</div>;;

        const inputFileMissingError = !this.state.showInputFileMissingError ? null : (
          <div style={{ marginTop: 5, color: "#C4C5C6" }}>
            <Icon name="hand point right" />
            Input file is missing
          </div>
        );

        const outputFileMissingError = !this.state.showOutputFileMissingError ? null : (
          <div style={{ marginTop: 5, color: "#C4C5C6" }}>
            <Icon name="hand point right" />
            Output file is missing
          </div>
        );

        const problemFileMissingError = !this.state.showProblemFileMissingError ? null : (
          <div style={{ marginTop: 5, color: "#C4C5C6" }}>
            <Icon name="hand point right" />
            Problem File is missing
          </div>
        );

        const problemFileTypeError = !this.state.showFileTypeError ? null : (
          <div style={{ marginTop: 5, color: "#C4C5C6" }}>
            <Icon name="hand point right" />
            Only pdf file is supported
          </div>
        );
    
    
        const notLoggedInError = !this.state.showIsLoggedInError ? null : (
            <div style={{ marginTop: 5, color: "#C4C5C6" }}>
              <Icon name="hand point right" />
              Please login to create a problem
            </div>
          );

        const inputFileNameDiv =
        this.state.inputFile == null ? null : (
            <div style={{ marginTop: "10px" }}>
            <Icon color="brown" name="angle double up" /> {this.state.inputFile.name}
            </div>
        );

        const outputFileNameDiv =
        this.state.outputFile == null ? null : (
            <div style={{ marginTop: "10px" }}>
            <Icon color="brown" name="angle double up" /> {this.state.outputFile.name}
            </div>
        );

        const problemFileNameDiv =
        this.state.problemFile == null ? null : (
            <div style={{ marginTop: "10px" }}>
            <Icon color="brown" name="angle double up" /> {this.state.problemFile.name}
            </div>
        );

        const fileSizeErrorModal = (
            <Modal
              closeIcon
              open={this.showFileSizeErrorModal()}
              onClose={() => this.onCloseErrorModal()}
              onOpen={() => this.setFileSizeErrorModalOpen()}
              size="tiny"
              dimmer="blurring"
            >
              <Modal.Content>
                <WarningMessage
                  title="File size error"
                  message="Please submit a file less than 10 MB"
                />
              </Modal.Content>
            </Modal>
          );

        const addProblemFileDiv = this.state.isSaving ?
          <Dimmer active inverted>
              <Loader inverted>Saving</Loader>
          </Dimmer> : (
              <div style={{ marginTop: "10px" }}>
                  <div style={{ marginBottom: "10px" }}>
                    {this.state.problemFileExists
                    ? <div>
                        <Icon name="hand point right" />
                        <span>The problem file already exists. Please re-upload to change it.</span>
                        <a
                          style={{marginLeft: "10px"}}
                          href="#" 
                          onClick={() => this.onRedirectToProblemPage(this.state.problemId)}
                        >
                          Check here !
                        </a>
                      </div>
                    : <div>
                        <Icon name="hand point right" />
                        <span>No problem file exists. Please upload one.</span>
                      </div>
                    }
                  </div>
                  <Form onSubmit={this.onFormSubmit}>
                      <Form.Field>
                      <Button
                          id="file-upload-button"
                          style={{ background: "#ededed" }}
                          size="medium"
                          content="Select pdf file..."
                          labelPosition="left"
                          icon="file"
                          onClick={() => this.problemFileButtonRef.current.click()}
                      />
                      <input
                          ref={this.problemFileButtonRef}
                          type="file"
                          accept=".pdf"
                          hidden
                          onChange={this.problemFileChange}
                      />
                      </Form.Field>
                  </Form>
              </div>
        );

        const submitInputFileDiv = this.state.isSaving ?
            <Dimmer active inverted>
                <Loader inverted>Saving</Loader>
            </Dimmer> : (
                <div style={{ marginTop: "10px" }}>
                    <Form onSubmit={this.onFormSubmit}>
                        <Form.Field>
                        <Button
                            id="file-upload-button"
                            style={{ background: "#ededed" }}
                            size="medium"
                            content="Input File"
                            labelPosition="left"
                            icon="file"
                            onClick={() => this.inputFileButtonRef.current.click()}
                        />
                        <input
                            ref={this.inputFileButtonRef}
                            type="file"
                            hidden
                            onChange={this.inputFileChange}
                        />
                        </Form.Field>
                    </Form>
                </div>
        );

        const submitOutputFileDiv = this.state.isSaving ?
            <Dimmer active inverted>
                <Loader inverted>Saving</Loader>
            </Dimmer> : (
                <div style={{ marginTop: "10px" }}>
                    <Form onSubmit={this.onFormSubmit}>
                        <Form.Field>
                        <Button
                            id="file-upload-button"
                            style={{ background: "#ededed" }}
                            size="medium"
                            content="Output File"
                            labelPosition="left"
                            icon="file"
                            onClick={() => this.outputFileButtonRef.current.click()}
                        />
                        <input
                            ref={this.outputFileButtonRef}
                            type="file"
                            hidden
                            onChange={this.outputFileChange}
                        />
                        </Form.Field>
                    </Form>
                </div>
        );

        const sampleTestCaseCheckBox = (
          <Checkbox
            label='Sample Test Case'
            style={{ marginTop: 10 }}
            onChange={this.onToggleSampleTest}
          />
        );

        const testCaseSaveButtonDiv = (
          <div style={{ marginTop: "10px" }}>
            <Button
                id="solution-submit-button"
                style={{ background: "#ededed" }}
                size="medium"
                content="Save"
                labelPosition="left"
                icon="cloud upload"
                onClick={this.addTestCase}
            />
          </div>
        );

        const problemFileSaveButtonDiv = (
          <div style={{ marginTop: "10px" }}>
            <Button
                id="solution-submit-button"
                style={{ background: "#ededed" }}
                size="medium"
                content="Save"
                labelPosition="left"
                icon="cloud upload"
                onClick={this.addProblemFile}
            />
          </div>
        );

        const newTestCaseDiv = (
            <>
                {fileSizeErrorModal}
                {submitInputFileDiv}
                {inputFileNameDiv}
                {submitOutputFileDiv}
                {outputFileNameDiv}
                {sampleTestCaseCheckBox}
                {testCaseSaveButtonDiv}
                {notLoggedInError}
                {inputFileMissingError}
                {outputFileMissingError}
            </>
        );

        const testSolutionDiv = 
        <div>
          {this.state.testCaseList.length > 0 
            ? <>
                <Icon name="hand point right" />
                <span>Submit your solution in problem page.</span>
                <a
                  style={{marginLeft: "10px"}}
                  href="#" 
                  onClick={() => this.onRedirectToProblemPage(this.state.problemId)}
                >
                  Check here !
                </a>
              </> 
            : <p>You haven't set any test case yet. Please add test cases first.</p>}
        </div>;


        const dropdownItems = problemSetters.map((user) => (
          <Dropdown.Item
            key={user.id}
            className="filter-dropdown-color-item"
            text={user.fullName}
            onClick={ () => this.handleProblemSetterDropdown(user)}
          />
        ));

        const problemSetterDiv = (
          <div>
            <div style={{marginBottom: '20px'}}>
              {detail && detail.authorName ? 
              <span>Current Problem Setter : {detail.authorName}</span> : 'No problem setter available'}
            </div>
            <Dropdown
              text={this.state.problemSetterName}
              search
              selection
              button
              placeholder='Search'
              className="tiny filter-dropdown-color-margin-right"
              open={this.state.dropdownOpen}
              onClick={this.onToggleDropdown}
            >
              <Dropdown.Menu>
                {dropdownItems}
              </Dropdown.Menu>
            </Dropdown>
            <Button
              className="button-style"
              disabled={!this.state.problemSetterId}
              onClick={() => this.addProblemSetter()} 
            >
              Save
            </Button>
          </div>        
        );

        const problemFileDiv = (
          <>
          {fileSizeErrorModal}
          {addProblemFileDiv}
          {problemFileNameDiv}
          {problemFileSaveButtonDiv}
          {problemFileMissingError}
          {problemFileTypeError}
          </>
        );

        let displayItem = problemDetailDiv;
        
        if (this.state.activeItem === ACTIVE_ITEM_BASIC_INFO) {
          displayItem = problemDetailDiv;
        } else if (this.state.activeItem === ACTIVE_ITEM_PROBLEM_FILE) {
          displayItem = problemFileDiv;
        } else if (this.state.activeItem === ACTIVE_ITEM_TEST_CASE_LIST) {
          displayItem = testCasesDiv;
        } else if (this.state.activeItem === ACTIVE_ITEM_TEST_CASE_NEW) {
          displayItem = newTestCaseDiv;
        } else if (this.state.activeItem === ACTIVE_ITEM_TEST_SOLUTION) {
          displayItem = testSolutionDiv;
        } else if (this.state.activeItem === ACTIVE_ITEM_PROBLEM_SETTER) {
          displayItem = problemSetterDiv;
        }

        return (
            <div className="main-container">
              {statusCode != null ? (
                <NotFound statusCode={statusCode} />
                ) : 
                <Grid stackable columns={1}>
                    <Grid.Column width={16}>
                        <Segment raised>
                            {pageMenu}
                            {displayItem}
                        </Segment>
                    </Grid.Column>
                </Grid>
              }
            </div>
        );
    }
}

// Connect with "contest" reducer
const mapStateToProps = (state) => {
    return { data: assign({}, state.problem, { user: state.user }) };
};

// Connect with "ContestActions"
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(assign(ProblemActions), dispatch);
};

// Connect reducer and action together
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminProblemDetailContainer);
