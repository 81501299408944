import { Slide } from "react-slideshow-image";
import React from "react";
import "react-slideshow-image/dist/styles.css";
import styles from "./Slider.module.css";

export default function Slider(props) {
  
  const {images} = props;

  const isSingleImage = images.length === 1;

  return (
    <div className={`${styles.container} ${isSingleImage ? styles.singleImage : ""}`}>
      {isSingleImage ? (
        <div className={styles.slide}>
          <div style={{ backgroundImage: `url(${images[0].imageUrl})` }}></div>
        </div>
      ) : (
        <Slide easing="ease">
          {images.map((image, index) => (
            <div className={styles.slide} key={index}>
              <div style={{ backgroundImage: `url(${image.imageUrl})` }}></div>
            </div>
          ))}
        </Slide>
      )}
    </div>
  );
}
