import React, { Component } from "react";
import { Link } from "react-router-dom";
import { isLoggedIn, isTeamUser } from "../../utils/cookieUtil";
import LogoutMenu from "./LogoutMenu";
import Logo from "./Logo";

import {
  Button,
  Input,
  Container,
  Icon,
  Menu,
  Responsive,
  Segment,
  Sidebar,
} from "semantic-ui-react";

import AuthContainer from "../../containers/AuthContainer";
import LogoMobile from "./LogoMobile";
import LogoMobileRight from "./LogoMobileRight";

class DesktopHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenLoginModal: false,
      activeItem: "",
      problemName: ''
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pathStack.length !== this.props.pathStack.length) {
      const pathnames = this.props.pathStack;
      const pathname = pathnames.length > 0 ? pathnames[pathnames.length - 1] : "";
      this.setState({ activeItem: pathname });
    }
  }

  componentDidMount() {
    const pathnames = this.props.pathStack;
    const pathname = pathnames.length > 0 ? pathnames[pathnames.length - 1] : "";

    if (pathname.includes("problems")) {
      this.setState({ activeItem: "problems" });
    } else if (pathname.includes("contests")) {
      this.setState({ activeItem: "contests" });
    } else if (pathname.includes("submissions")) {
      this.setState({ activeItem: "submissions" });
    } else if (pathname.includes("ranks")) {
      this.setState({ activeItem: "ranks" });
    }
  }

  show = (dimmer) => () => this.setState({ dimmer, isOpenLoginModal: true });
  close = () => this.setState({ isOpenLoginModal: false });

  hideFixedMenu = () => this.setState({ fixed: false });
  showFixedMenu = () => this.setState({ fixed: true });

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  handleSearchIconClick = (e) => {
    if (this.state.problemName) {
      window.location.href = "/problems?name=" + this.state.problemName;
    }
  };

  render() {
    const { children } = this.props;
    const { isOpenLoginModal, dimmer } = this.state;
    const { activeItem } = this.state;

    const currentTime = new Date();
    const currentUTCHours = currentTime.getUTCHours();
  
    // service is available from 6 AM to 10 PM (Bangladesh Time)
    // const isServiceAvailableHours = currentUTCHours >= 0 && currentUTCHours < 16;

    const isServiceAvailableHours = true;

    const rightSideMenu = !isLoggedIn() ? (
      <Button
        as={Link} to="/" onClick={this.show("blurring")}
        className="navbar-menu"
      >
        Log in
      </Button>
    ) : (
      <LogoutMenu />
    );

    return (
      <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>

        <Menu secondary>
          <Link to="/">
            <Logo />
          </Link>
          {
            !isTeamUser() && 
              <Menu.Item
                as={Link}
                to="/problems"
                name="problems"
                active={activeItem === "problems"}
                onClick={this.handleItemClick}
                className="navbar-menu"
              />
          }
          
          <Menu.Item
            as={Link}
            to="/contests"
            name="contests"
            active={activeItem === "contests"}
            onClick={this.handleItemClick}
            className="navbar-menu"
          />

          {
            !isTeamUser() && 
              <Menu.Item
                as={Link}
                to="/submissions"
                name="submissions"
                active={activeItem === "submissions"}
                onClick={this.handleItemClick}
                className="navbar-menu"
              />
          }

          {
            !isTeamUser() && 
              <Menu.Item
                as={Link}
                to="/ranks"
                name="ranking"
                active={activeItem === "ranks"}
                onClick={this.handleItemClick}
                className="navbar-menu"
              />
          }
          
          {!isServiceAvailableHours ? null :
            <Menu.Menu position="right">
              <Menu.Item style={{ paddingRight: 0 }}>
                {rightSideMenu}
              </Menu.Item>
            </Menu.Menu>
          }
        </Menu>
        {children}
        <AuthContainer
          isOpenLoginModal={isOpenLoginModal}
          dimmer={dimmer}
          close={this.close}
        />
      </Responsive>
    );
  }
}

class MobileHeader extends Component {
  state = {
    isOpenLoginModal: false,
    sideBarMinHeight: 0,
    activeItem: "",
    problemName: ''
  };

  componentDidMount() {
    const pathnames = this.props.pathStack;
    const pathname = pathnames.length > 0 ? pathnames[pathnames.length - 1] : "";

    if (pathname.includes("problems")) {
      this.setState({ activeItem: "problems" });
    } else if (pathname.includes("contests")) {
      this.setState({ activeItem: "contests" });
    } else if (pathname.includes("submissions")) {
      this.setState({ activeItem: "submissions" });
    } else if (pathname.includes("ranks")) {
      this.setState({ activeItem: "ranks" });
    }
  }

  show = (dimmer) => () => this.setState({ dimmer, isOpenLoginModal: true });
  close = () => this.setState({ isOpenLoginModal: false });

  handleSidebarHide = () => {
    this.setState({ sidebarOpened: false, sideBarMinHeight: 0 });
    document.querySelector('.nav-header').style.height = 'auto';
    document.querySelector('.pusher').style.marginLeft = 'auto';
  }

  handleToggle = () => {
    document.querySelector('.nav-header').style.height = '100%';
    document.querySelector('.ui.left.sidebar').style.width = '100%';
    document.querySelector('.pusher').style.marginLeft = '16%';
    this.setState({ sidebarOpened: true, sideBarMinHeight: 155 });
  }

  render() {
    const { children } = this.props;
    const { sidebarOpened } = this.state;
    const { isOpenLoginModal, dimmer } = this.state;

    const logOutMenu = isLoggedIn() ? (
      <LogoutMenu />
    ) : (
      <Menu.Item>
        <div onClick={this.show("blurring")}>
          <i className="sign in alternate icon"></i> Login
        </div>
      </Menu.Item>
    );

    return (
      <Responsive
        as={Sidebar.Pushable}
        getWidth={getWidth}
        maxWidth={Responsive.onlyMobile.maxWidth}
      >
        <Sidebar
          as={Menu}
          animation="push"
          onHide={this.handleSidebarHide}
          vertical
          visible={sidebarOpened}
        >
          <Link to="/">
            <LogoMobile />
            <Icon 
              name = "close" 
              size="large"
              style={{marginLeft: '65%'}}
              onClick={this.handleSidebarHide}
            />
          </Link>
          {
            !isTeamUser() &&
              <Menu.Item
              as={Link}
              to="/problems"
              onClick={this.handleSidebarHide}
              className="navbar-menu"
            >
              Problems
            </Menu.Item>
          }
          {
            !isTeamUser() && 
              <Menu.Item
              as={Link}
              to="/submissions"
              onClick={this.handleSidebarHide}
              className="navbar-menu"
            >
              Submissions
            </Menu.Item>
          }
          
          <Menu.Item
            as={Link}
            to="/contests"
            onClick={this.handleSidebarHide}
            className="navbar-menu"
          >
            Contests
          </Menu.Item>

          {
            !isTeamUser() && 
              <Menu.Item
              as={Link}
              to="/ranks"
              onClick={this.handleSidebarHide}
              className="navbar-menu"
            >
              Ranking
            </Menu.Item>
          }
          
          {logOutMenu}
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment
            textAlign="center"
            style={{
              minHeight: this.state.sideBarMinHeight,
            }}
            vertical
          >
            <Container className="nav-responsive-menu">
              <Menu secondary size="large">
                <Menu.Item onClick={this.handleToggle}>
                  <Icon name="sidebar" />
                </Menu.Item>
                <Menu.Item position="right">
                  <LogoMobileRight />
                </Menu.Item>
              </Menu>
            </Container>
          </Segment>
          {children}
        </Sidebar.Pusher>
        <AuthContainer
          isOpenLoginModal={isOpenLoginModal}
          dimmer={dimmer}
          close={this.close}
        />
      </Responsive>
    );
  }
}

const getWidth = () => {
  const isSSR = typeof window === "undefined";
  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth;
};

export { DesktopHeader, MobileHeader };
