import React from 'react';
import { Segment, Image, Icon } from "semantic-ui-react";
import { COUNTRY } from "../../constants";
import DefaultAvatarUrl from '../../../src/default_avatar.png';
import DefaultFlag from '../DefaultFlag';

export default class UserBasicInfo extends React.Component {
    constructor(props) {
        super(props);
    }

    openInNewTab = (url) => {
        if (url == null || !url || url.length === 0) return;
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    render() {
        const { userDetail } = this.props;
        const countryInfo = COUNTRY.filter(function (country) { return country.countryCode == userDetail.countryCode });
        const countryName = countryInfo && countryInfo.length > 0 ? countryInfo[0].name : "";
        let avatarUrl = DefaultAvatarUrl;
        if (userDetail.avatarUrl) {
            avatarUrl = userDetail.avatarUrl;
        }
        const subscribed = userDetail.subscribed ? userDetail.subscribed : false;
        return (
            <Segment>
                <Image src={avatarUrl} size='medium' rounded />
                <Segment>
                    <h4 className="text-style">{userDetail.fullName}</h4>
                    <div style={{ marginBottom: "10px" }}>{userDetail && userDetail.organization ? userDetail.organization : null}</div>
                    {countryInfo && countryInfo.length > 0 ? <div>{countryName} <DefaultFlag countryCode={userDetail.countryCode} /></div> : null}
                    <div style={{ marginTop: "10px" }}>
                        <Icon name="wi-fi" style={{ color: userDetail.onlineStatus === "1" ? "#51c41a" : "none" }} />
                        Currently {userDetail.onlineStatus === "1" ? "online" : "offline"}
                    </div>
                    <div style={{ marginTop: "10px" }}>
                        <Icon name="linkedin" />
                        <a
                            href="#"
                            onClick={() => this.openInNewTab(userDetail.linkedinProfileUrl)}
                            style={{ color: "#C4C5C6" }}
                        >Linkedin Profile</a>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                        <Icon name="github" />
                        <a
                            href="#"
                            style={{ color: "#C4C5C6" }}
                            onClick={() => this.openInNewTab(userDetail.githubProfileUrl)}
                        >Github Profile</a>
                    </div>
                    <div style={{ marginTop: "5px", marginBottom: "10px" }}>
                        {userDetail.subscribed ? (subscribed ? "Email subscribed" : "Email unsubscribed") : ""}
                    </div>
                </Segment>
            </Segment>
        )
    }
}