import React, { Component } from "react";
import { Grid, Label, Segment } from "semantic-ui-react";
import ratingIconImage from '../../assets/icons/rating_new1.png';
import contestCreateImage from '../../assets/icons/contest6.png';
import AvatarWithFlag from "../AvatarWithFlag"; 
import DefaultAvatar from "../DefaultAvatar";

class ContestListSideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandCategoryNames: [],
    };
  }

  onClickUser(id) {
    window.location.href = "/users/" + id;
  }

  render() {
    const { ratingUsers } = this.props;

    const listDiv = ratingUsers.map((user, index) => {
      const avatarDiv = user.avatarUrl ? 
        <AvatarWithFlag url={user.avatarUrl} countryCode={user.countryCode} /> 
        : <DefaultAvatar />;
      
        return (
          <div key={index}>
            <Grid
              stackable
              columns={3}
              onClick={() => this.onClickUser(user.id)}
            >
              <Grid.Row>
                  <Grid.Column width={16}>
                      {avatarDiv}
                      <a href="#" onClick={() => this.onClickUser(user.id)}>
                          <span className="author-name">
                              {user.userName}
                          </span>
                      </a>
                      <span style={{float: 'right', marginTop: '7px'}}>{user.contestRating}</span>
                  </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        );
    });

    return (
      <div>
          <Segment raised>
            <h4 className="title-name">
                <img style={{width: '8%', marginRight: '10px'}} src={contestCreateImage} /> 
                Host Contests
            </h4>
            <p>
              Join CodePanja's contest-hosting adventure, absolutely free of charge!
            </p>
            <p>Let us know <a className="button-style" href="https://forms.gle/KRRZPcHKniukCW1e6" target="_blank">here!</a></p>
          </Segment>
          <Segment raised>
            <h4 className="title-name" style={{marginBottom: '25px'}}>
                <img style={{width: '8%', marginRight: '10px'}} src={ratingIconImage} /> 
                Top Rated Contestants
            </h4>
            {listDiv}
          </Segment>
      </div>
    );
  }
}

export default ContestListSideBar;
