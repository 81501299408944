import { SUBMISSION } from "../actions/actions";

const initialState = {
  list: null,
  detail: null,
  details: null,
  statusCode: null
};

const submissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMISSION.SET_SUBMISSION_LIST:
      return { ...state, list: action.payload };
    case SUBMISSION.SET_SUBMISSION_DETAILS:
      return { ...state, details: action.payload };
    case SUBMISSION.SET_SUBMISSION_DETAIL:
      return { ...state, detail: action.payload };
    case SUBMISSION.SET_STATUS_CODE:
      return { ...state, statusCode: action.payload };
    default:
      return state;
  }
};

export default submissionReducer;
