import React from 'react';
import { Popup } from 'semantic-ui-react';
import avatar from '../../src/default_avatar.png';
function DefaultAvatar() {
    return <Popup
        trigger={
            <span>
                <img src={avatar} style={{ width: "40px", height: "40px", marginRight: '.5em', borderRadius: '50%' }} />
            </span>
        }
        content={"Default Avatar"}
        size='mini'
    />;
}

export default DefaultAvatar;