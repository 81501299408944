import request from "superagent";
import { TIMEOUT } from "../constants/";
import { getJwtToken } from "../utils/cookieUtil";

export const METHOD_TYPE = {
  GET: "GET",
  POST: "POST",
  // SOMEHOW PUT request via superagent doesn't work.
  // It causes cors error, so don't use put in superagent for now
  // Use POST instead of PUT
  PUT: "PUT",
  DELETE: "DELETE",
};

export default function callAPI(apiPath, method, params = {}, options = {}) {
  let domain = '/api';
  domain = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_DOMAIN + '/api' : domain;
  const endpoint = apiPath[0] === "/" ? `${domain}${apiPath}` : apiPath;

  const timeout =
    typeof options.timeout === "undefined" ? TIMEOUT : options.timeout;
  return new Promise((resolve, reject) => {
    const req = request(method, endpoint)
      .timeout(timeout)
      .set('Accept', 'application/json;charset=UTF-8')
      .set('Authorization', 'Bearer ' + getJwtToken())
      .withCredentials(true);

    if (method === METHOD_TYPE.GET) {
      req.query(params);
    }

    if (method === METHOD_TYPE.POST || method === METHOD_TYPE.PUT) {
      if (typeof params.file === "undefined") {
        req.send(params);
      } else {
        Object.keys(params).forEach((key) => {
          if (key !== "file" && params[key] !== null) {
            req.field(key, params[key]);
          }
        });
        req.attach("file", params.file);
      }
    }

    if (options.onProgress) {
      req.on("progress", options.onProgress);
    }

    req
      .then((res) => {
        if (res.ok) {
          resolve(res);
        } else {
          reject(res);
        }
      })
      .catch((e) => {
        reject({
          message: e.message,
          status: e.status,
          response: e.response,
        });
      });
  });
}
