import ReactCookie from 'react-cookie';
import { USER } from "../actions/actions";
import { isLoggedIn, isTeamUser } from "../utils/cookieUtil";

const initialState = {
  loggedInUserId: isLoggedIn() ? ReactCookie.load('id') : null,
  loggedInUserName: isLoggedIn() ? ReactCookie.load('userName') : null,
  permission: isLoggedIn() ? ReactCookie.load('permission') : null,
  detail: {},
  profileInfo: {},
  problems: [],
  contests: [],
  problemSetters: [],
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case USER.SET_USER_DETAIL:
      return { ...state, detail: action.payload };
    case USER.SET_PROFILE_INFO:
      return { ...state, profileInfo: action.payload };
    case USER.SET_AUTHOR_PROBLEMS:
      return { ...state, problems: action.payload };
    case USER.SET_AUTHOR_CONTESTS:
      return { ...state, contests: action.payload };
    case USER.SET_PROBLEM_SETTERS:
      return { ...state, problemSetters: action.payload };
    default:
      return state;
  }
};

export default user;