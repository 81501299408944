import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import assign from "lodash/assign";
import { isLoggedIn } from "../utils/cookieUtil";
import Countdown from "../Components/Countdown";
import CountdownFinished from "../Components/CountdownFinished";
import { Segment, Grid, Menu, Icon, Button, List, Label } from "semantic-ui-react";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import * as ContestActions from "../actions/ContestActions";
import SubmissionList from "../Components/Submission/SubmissionList";
import Standings from "../Components/Standings";
import ContestProblems from "../Components/Contest/ContestProblems";
import NotFound from "../Components/Error/NotFound";
import contestCreateImage from '../assets/icons/contest6.png';
import ContestOverview from "../Components/Contest/ContestOverview";

class ContestDetailContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: 'overview',
      contestId: ''
    };
  }

  componentDidMount() {
    this.props.updatePath();
    const pathname = window.location.pathname;
    const contestId = pathname.substring(pathname.lastIndexOf('/') + 1);

    // use this logic for standing page
    // const parts = pathname.split('/');
    // const contestId = parts[2]; // Assuming the contest ID is the third part of the path

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    this.setState({ contestId: contestId })
    this.props.fetchContest(contestId);
    this.props.fetchContestStandings(contestId);
    this.props.fetchContestProblemSetters(contestId);
    this.props.fetchContestExtraInfo(contestId);

    if (isLoggedIn()) {
      this.props.fetchContestProblemsForLoggedInUser(contestId);
      this.props.fetchContestUserRegistrationInfo(contestId);
    } else {
      this.props.fetchContestProblems(contestId);
    }

    if (urlParams.has("filter")) {
      const filterValue = urlParams.get("filter");
      if (filterValue === "standings") {
        this.setState({ activeItem: "standings" })
        this.props.fetchContestStandings(contestId);
      }
    }

    this.interval = setInterval(() => {
      this.fetchData();
    }, 30000); // every 30 seconds
  }

  componentWillReceiveProps(nextProps) {
    const detail = nextProps.data.detail;
    if (detail) {
      document.title = 'Contests | ' + detail.name;
    }
  }

  fetchData() {
    this.props.fetchContest(this.state.contestId);
    this.props.fetchContestStandings(this.state.contestId);
    this.props.fetchContestProblemSetters(this.state.contestId);
    const contest = this.props.data.detail;
    if (contest != null && contest.ended) {
      clearInterval(this.interval);
    }
  }

  onClickMenu(itemName) {
    this.setState({ activeItem: itemName })
    if (itemName === "submissions") {
      this.props.fetchContestSubmissions(this.state.contestId);
    } else if (itemName === "problems") {
      if (isLoggedIn()) {
        this.props.fetchContestProblemsForLoggedInUser(this.state.contestId);
      } else {
        this.props.fetchContestProblems(this.state.contestId);
      }
    } else if (itemName === "standings") {
      this.props.fetchContestStandings(this.state.contestId);
    } else if (itemName === "overview") {
      this.props.fetchContestProblemSetters(this.state.contestId);
    }
  }

  onClickProblem(problemId, contestId, ended) {
    if (ended) {
      window.location.href = "/problems/" + problemId;
    } else {
      window.location.href = "/problems/" + problemId + "?contestId=" + contestId;
    }
  };

  startContest = () => {
    this.props.startRecruitContest(this.state.contestId);
  };

  confirmRegister(contestId, userId) {
    this.props.registerUserToContest(contestId, userId);
  }

  onClickRegister(contest) {
    const loggedInUserId = this.props.data.user.loggedInUserId;
    Modal.confirm({
      title: "Register to participate in this contest.",
      icon: <ExclamationCircleOutlined />,
      content: "Name : " + contest.name,
      okText: "Yes",
      cancelText: "No",
      onOk: () => this.confirmRegister(contest.id, loggedInUserId),
    });
  }

  findContestTimeDetail = (utcStartTime) => {
    const localStartTime = new Date(utcStartTime).toLocaleString();
    // Extract date components
    const options = { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric', 
      hour: 'numeric', 
      minute: 'numeric', 
      hour12: true, 
      timeZoneName: 'short'
    };

    // Format the date
    const formattedDate = localStartTime.toLocaleString('en-US', options);
    return formattedDate;
  }

  onClickStart() {
    Modal.confirm({
      title: "Final confirmation. Are you ready to start?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: () => this.startContest(),
    });
  }

  render() {
    const { activeItem } = this.state;
    const { 
      detail, 
      problems,
      submissions, 
      standings, 
      userRegistrationInfo, 
      problemSetters,
      extraInfo
    } = this.props.data;

    const errorCode = this.props.data.errorCode;
    const name = detail != null ? detail.name : '';
    const ended = detail != null ? detail.ended : false;
    const started = detail != null ? detail.started : false;
    const isRecruitContest = detail != null ? detail.status === 40 : false;
    const frozen = detail != null ? detail.frozen : false;
    const registered = userRegistrationInfo != null ? userRegistrationInfo.registered : false;
    const manualRegistration = detail != null ? detail.manualRegistration : false;
    
    const contestMenu = (
      <Menu secondary style={{height: 'auto'}}>
        <Menu.Item
          className="navbar-menu"
          name='Overview'
          active={activeItem === 'overview'}
          onClick={() => this.onClickMenu('overview')}
        />
        <Menu.Item
          className="navbar-menu"
          name='Problems'
          active={activeItem === 'problems'}
          onClick={() => this.onClickMenu('problems')}
        />
        <Menu.Item
          className="navbar-menu"
          name='Standings'
          active={activeItem === 'standings'}
          onClick={() => this.onClickMenu('standings')}
        />
        {registered && (started || ended) ? 
        <Menu.Item
          className="navbar-menu"
          name='Submissions'
          active={activeItem === 'submissions'}
          onClick={() => this.onClickMenu('submissions')}
        /> : null}
      </Menu>
    );

    const contestMenuDiv = started ? contestMenu : null;

    let displayItem = null;

    if (activeItem === "problems" && ((registered && started) || ended)) {
      displayItem =
        <ContestProblems
          problems={problems}
          ended={ended}
          frozen={frozen}
          contestId={this.state.contestId}
          registered={registered}
          onClickProblem={this.onClickProblem}
        />;
    } else if (activeItem === "submissions" && registered && (started || ended)) {
      displayItem =
        <SubmissionList
          submissions={submissions}
          showFiltering={false}
          showLoading={false}
          contestId={this.state.contestId}
        />;
    } else if (activeItem === "standings" && started) {
      displayItem = 
        <Standings
          standings={standings}
        />
    } else if (activeItem === "overview") {
      displayItem = 
        <ContestOverview
          detail={detail}
          problemSetters={problemSetters}
          problems={problems}
          extraInfo={extraInfo}
        />
    }

    const rightDiv = ended ? <CountdownFinished /> : started ? <Countdown contest={detail} /> :
      isRecruitContest ?
        <Button
          size="large"
          style={{marginTop: '20px'}}
          className="button-style"
          icon='play'
          content='Start'
          onClick={() => this.onClickStart()}/>
        : detail && <Countdown contest={detail} />;

    const contestRegistrationDiv = 
      registered ? ended ? 
        <Label className="button-style">
          Participated
        </Label> : 
        <Label className="button-style">
          Registered
        </Label> :
        userRegistrationInfo && !manualRegistration ?
        <div>
          <Button
            size='large'
            onClick={() => this.onClickRegister(detail)}
            className="button-style"
          >
            Register
          </Button>
        </div> : null;
    
    return (
      errorCode != null ? (
        <div className="main-container">
          <NotFound statusCode={errorCode} />
        </div>
      ) : (
      <div className="main-container">
        <Grid stackable columns={1}>
          <Grid.Column width={16}>
            <Segment raised>
              <div className="foc-header">
                <div className="title">
                  <img style={{width: "35px", marginRight: '10px'}} src={contestCreateImage} />
                  <span className="text-style" style={{ fontSize: "22px" }}>{name}</span>
                </div>
                <div className="contest-timer" style={{ float: "right" }}>
                  {rightDiv}
                </div>
              </div>
              {isLoggedIn() && !ended ?
                contestRegistrationDiv :
                !ended ?
                <span>      
                  <p style={{color: '#F9C31C'}}>
                    <Icon name='info' />
                    To participate, please login and register to the contest.
                  </p> 
                </span> : null
              }
              {frozen ?
                <div className="frozen-note">
                  <strong>The contest standings is now frozen and solutions will be judged after the contest is finished.</strong>
                </div>
                : null}
              {isRecruitContest && !started ?
                <div style={{marginTop: '30px'}}>
                  <Icon name='hand point right outline' />
                  <span style={{fontSize: '15px'}}>Please check the following rules carefully.</span>
                  <List bulleted size='large'>
                    <List.Item>Start the test before {this.findContestTimeDetail(detail.startTime)}</List.Item>
                    <List.Item>The test duration is 2 hours.</List.Item>
                    <List.Item>The test will be started when you click the start button.</List.Item>
                    <List.Item>After the test is started the problems will be visible.</List.Item>
                  </List>
                </div>
                : null}
              {contestMenuDiv}
              <div style={{marginBottom: "20px"}}></div>
              {displayItem}
            </Segment>
          </Grid.Column>
        </Grid>

        <div
          style={{
            textAlign: "center",
            marginTop: "30px",
            marginBottom: "30px",
          }}
        >
        </div>
      </div > )
    );
  }
}

// Connect with "contest" reducer
const mapStateToProps = (state) => {
  return { data: assign({}, state.contest, { user: state.user }) };
};

// Connect with "ContestActions"
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(assign(ContestActions), dispatch);
};

// Connect reducer and action together
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContestDetailContainer);
