import callAPI from "../utils/callAPI";

export const resetPassword = (password, token) => {
  return (dispatch) => {
    callAPI("/callBack/mailToken/" + token + "/reset-password", "POST", {
      password: password
    }).then((response) => {
      window.location.href = "/activation?type=passwordChanged";
    }).catch((error) => {
    });
  };
};
