import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import assign from "lodash/assign";

import { Grid } from "semantic-ui-react";
import SubmittedCode from "../Components/Submission/SubmittedCode";
import * as SubmissionActions from "../actions/SubmissionActions";
import SubmissionDetails from "../Components/Submission/SubmissionDetails";
import SubmissionDetail from "../Components/Submission/SubmissionDetail";
import { JWT_NORMAL_USER_TOKEN_COOKIE_NAME } from "../constants";
import { getCookie } from "../utils/cookieUtil";

class SubmissionDetailContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFileSizeError: false
    };
  }

  componentDidMount() {
    this.props.updatePath();
    const pathname = window.location.pathname;
    const submissionId = pathname.substring(pathname.lastIndexOf('/') + 1)

    const searchParams = {
      userId: getCookie(JWT_NORMAL_USER_TOKEN_COOKIE_NAME) ? this.props.data.user.loggedInUserId : null,
      problemId: null,
      status: null
    };

    this.props.fetchSubmission(submissionId, searchParams);
    this.props.fetchSubmissionDetails(submissionId);

    this.interval = setInterval(() => {
      this.fetchData(submissionId, searchParams);
    }, 3000);
  }

  componentWillReceiveProps(nextProps) {
    const detail = nextProps.data.detail;
    if (detail) {
      document.title = 'Submissions | ' + detail.problemName;
    }
  }

  fetchData(submissionId, searchParams) {
    this.props.fetchSubmission(submissionId, searchParams);
    this.props.fetchSubmissionDetails(submissionId);
    const submission = this.props.data.detail;
    if (submission) {
      if (submission.frozen
        || (submission.status !== 'In Queue' && submission.status !== 'Processing')) {
        clearInterval(this.interval);
      }
    }
  }

  render() {
    const details = this.props.data.details;
    const submission = this.props.data.detail;
    const sourceCode = submission ? submission.sourceCode : '';

    return (
      <div className="main-container">
        <Grid stackable columns={1}>
          <SubmissionDetail
            submission={submission}
          />
        </Grid>

        <Grid stackable columns={2}>
          <SubmissionDetails
            details={details}
            submission={submission}
          />
          <SubmittedCode
            details={details}
            code={sourceCode}
            language="cpp"
          />
        </Grid>
      </div >
    );
  }
}

// Connect with "submission" reducer
const mapStateToProps = (state) => {
  return { data: assign({}, state.submission, { user: state.user }) };
};

// Connect with "SubmissionActions"
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(assign(SubmissionActions), dispatch);
};

// Connect reducer and action together
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmissionDetailContainer);
