import React, { Component } from "react";
import { Label } from "semantic-ui-react";

class StatusLabel extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let status = this.props.status;
        let className = 'accepted-color';
        if (status === 'Accepted' || status === 'Solved') {
            className = 'accepted-color';
        } else if (status === 'In Queue') {
            className = 'in-queue-color';
        } else if (status === 'Processing') {
            className = 'processing-color';
        } else if (status === 'Unsolved') {
            className = 'error-color';
        } else {
            className = 'error-color';
        }
        return (
            <Label className={className}>
                {status}
            </Label>
        );
    }
}

export default StatusLabel;
