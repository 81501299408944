import callAPI from "../utils/callAPI";
import processCallApiException from "../utils/processCallAPIException";
import { AUTH } from "./actions";
import { removeJWTCookie, setCookieAfterLoginForTeamUser } from "../utils/cookieUtil";
import { setCookieAfterLoginForGeneralUser } from "../utils/cookieUtil";
import { LOCAL_STORAGE_ITEM_AVATAR_URL, USER_TYPE_VALUE_TEAM } from "../constants";

export const doSignIn = (email, password, userType) => {
  const request = {
    emailOrUserName: email,
    password: password,
    userType: userType
  };
  return (dispatch) => {
    if (userType === USER_TYPE_VALUE_TEAM) {
      callAPI('/teams/signIn', "POST", request)
      .then((response) => {
        const data = response.body.data;
        setCookieAfterLoginForTeamUser(data);
        window.location.href = "/";
      })
      .catch((error) => {
        const res = error.response;
        dispatch({
          type: AUTH.SET_SIGN_IN_STATUS,
          payload: { isSignInFailed: true, error: res.body.message },
        });
        removeJWTCookie();
      });
    } else {
      callAPI('/users/signIn', "POST", request)
      .then((response) => {
        const data = response.body.data;
        setCookieAfterLoginForGeneralUser(data);
        // set avatarUrl in local storage
        // cookie value has restriction over some characters
        if (data.avatarUrl) {
          localStorage.setItem(LOCAL_STORAGE_ITEM_AVATAR_URL, data.avatarUrl);
        }
        window.location.href = "/";
      })
      .catch((error) => {
        const res = error.response;
        dispatch({
          type: AUTH.SET_SIGN_IN_STATUS,
          payload: { isSignInFailed: true, error: res.body.message },
        });
        removeJWTCookie();
      });
    }
  };
};

export const doSignUp = (requestObject) => {
  return (dispatch) => {
    const request = {
      email: requestObject.email,
      password: requestObject.password,
      userName: requestObject.userName,
      fullName: requestObject.fullName,
      avaterUrl: "",
      countryCode: requestObject.countryCode,
      subscribed: requestObject.subscribed ? "1" : "0"
    };
    callAPI("/users", "POST", request)
      .then((response) => {
        window.location.href = "/activation?type=accountRegistered";
      })
      .catch((error) => {
        dispatch({
          type: AUTH.SET_SIGN_UP_ERROR,
          payload: error.response.body.message,
        });
      })
      .catch(processCallApiException(dispatch));
  };
};

export const sendPasswordChangeEmail = (email) => {
  return (dispatch) => {
    callAPI("/users/password-change", "POST", { email: email })
      .then((response) => {
        window.location.href = "/activation?type=resetPasswordEmailSent";
      })
      .catch(processCallApiException(dispatch));
  };
};

export const resetErrorStatus = () => {
  return (dispatch) => {
    dispatch({
      type: AUTH.RESET_ERROR_MESSAGE,
      payload: false,
    });
  };
};
