import ReactCookie from "react-cookie";
import { 
  JWT_NORMAL_USER_TOKEN_COOKIE_NAME, 
  JWT_TEAM_USER_TOKEN_COOKIE_NAME, 
  USER_TYPE_VALUE_TEAM, 
  USER_TYPE,
  USER_ID,
  USER_NAME,
  USER_GOOGLE_PROFILE_PIC,
  USER_PERMISSION,
  LOCAL_STORAGE_ITEM_AVATAR_URL
} from "../constants";

export const removeJWTCookie = () => {
  ReactCookie.remove(JWT_NORMAL_USER_TOKEN_COOKIE_NAME, { path: '/' });
  ReactCookie.remove(JWT_NORMAL_USER_TOKEN_COOKIE_NAME, { path: '/', domain: '.codepanja.com' });

  ReactCookie.remove(JWT_TEAM_USER_TOKEN_COOKIE_NAME, { path: '/' });
  ReactCookie.remove(JWT_TEAM_USER_TOKEN_COOKIE_NAME, { path: '/', domain: '.codepanja.com' });

  ReactCookie.remove(USER_ID, { path: '/' });
  ReactCookie.remove(USER_ID, { path: '/', domain: '.codepanja.com'});

  ReactCookie.remove(USER_NAME, { path: '/' });
  ReactCookie.remove(USER_NAME, { path: '/', domain: '.codepanja.com' });

  ReactCookie.remove(USER_PERMISSION, { path: '/' });
  ReactCookie.remove(USER_PERMISSION, { path: '/', domain: '.codepanja.com' });

  ReactCookie.remove(USER_GOOGLE_PROFILE_PIC, { path: '/' });
  ReactCookie.remove(USER_GOOGLE_PROFILE_PIC, { path: '/', domain: '.codepanja.com' });

  ReactCookie.remove(USER_TYPE, { path: '/' });
  ReactCookie.remove(USER_TYPE, { path: '/', domain: '.codepanja.com' });
  
  localStorage.removeItem(LOCAL_STORAGE_ITEM_AVATAR_URL);
};

// Google Profile Picture is set by Backend API Logic
export const setCookieAfterLoginForGeneralUser = (data) => {
  setCookie(USER_ID, data.id);
  setCookie(USER_NAME, data.userName);
  setCookie(USER_PERMISSION, data.permission);
};

export const setCookieAfterLoginForTeamUser = (data) => {
  setCookie(USER_ID, data.id);
  setCookie(USER_NAME, data.userName);
  setCookie(USER_PERMISSION, data.permission);
  setCookie(USER_TYPE, data.userType);
};

export const removeCookieBeforeLogin = () => {
  removeJWTCookie();
};

export const getCookie = (name) => {
  return ReactCookie.load(name);
};

export const setCookie = (key, value) => {
  ReactCookie.save(key, value, { path: '/' });
};

export const isTeamUser = () => {
  return ReactCookie.load(JWT_TEAM_USER_TOKEN_COOKIE_NAME) && ReactCookie.load(USER_TYPE) && ReactCookie.load(USER_TYPE) === USER_TYPE_VALUE_TEAM;
};

export const isLoggedIn = () => {
  return ReactCookie.load(JWT_NORMAL_USER_TOKEN_COOKIE_NAME) != null || ReactCookie.load(JWT_TEAM_USER_TOKEN_COOKIE_NAME) != null;
};

export const getJwtToken = () => {
  if (ReactCookie.load(JWT_NORMAL_USER_TOKEN_COOKIE_NAME) != null) {
    return getCookie(JWT_NORMAL_USER_TOKEN_COOKIE_NAME);
  } else if (ReactCookie.load(JWT_TEAM_USER_TOKEN_COOKIE_NAME) != null) {
    return getCookie(JWT_TEAM_USER_TOKEN_COOKIE_NAME);
  }
};
