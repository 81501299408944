import React from 'react';
import { Popup } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

function AvatarPopup(props) {
    const userUrl = `/users/${props.id}`;

    return (
        <Popup
            trigger={
                <Link to={userUrl}>
                    <span>
                        <img src={props.url} style={{ width: "50px", height: "50px", marginRight: '.5em', borderRadius: '5%' }} alt="User Avatar" />
                    </span>
                </Link>
            }
            content={props.popupValue}
            size='mini'
        />
    );
}

export default AvatarPopup;
