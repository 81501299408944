import { COMMON } from "../actions/actions";

export default (dispatch, isFatal) => (error) => {
  if (error.status === 400) {
    dispatch({ type: COMMON.SET_IS_BAD_REQUEST, payload: true });
  } else if (error.status === 401) {
    dispatch({ type: COMMON.SET_IS_UNAUTHORIZED_REQUEST, payload: true });
  } else if (error.status === 403) {
    dispatch({ type: COMMON.SET_IS_FORBIDDEN, payload: true });
  } else if (error.status === 404) {
    dispatch({ type: COMMON.SET_IS_NOTFOUND, payload: true });
  } else if (error.status === 500) {
    dispatch({ type: COMMON.SET_IS_INTERNAL_ERROR, payload: true });
  }
  throw error;
};
