import { PROBLEM } from "../actions/actions";

const initialState = {
  list: [],
  detail: null,
  statusCode: null,
  sampleTestRunSubmissionId: null,
  sampleTestRunData: null
};

const problemReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROBLEM.SET_PROBLEM_LIST:
      return { ...state, list: action.payload };
    case PROBLEM.SET_PROBLEM_DETAIL:
      return { ...state, detail: action.payload };
    case PROBLEM.SET_PROBLEM_SAMPLE_TEST_RUN_SUBMISSION_ID:
      return { ...state, sampleTestRunSubmissionId: action.payload };
    case PROBLEM.SET_PROBLEM_SAMPLE_TEST_RUN_DATA:
      return { ...state, sampleTestRunData: action.payload };
    case PROBLEM.SET_STATUS_CODE:
      return { ...state, statusCode: action.payload };
    default:
      return state;
  }
};

export default problemReducer;
