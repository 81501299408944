import callAPI from "../utils/callAPI";
import { USER } from "./actions";
import { removeJWTCookie, setCookie } from "../utils/cookieUtil";
import processCallAPIException from "../utils/processCallAPIException";
import { LOCAL_STORAGE_ITEM_AVATAR_URL } from "../constants";

export const fetchUser = (userId) => {
  return (dispatch) => {
    callAPI("/users/" + userId, "GET")
      .then((response) => {
        dispatch({
          type: USER.SET_USER_DETAIL,
          payload: response.body.data,
        });
      })
      .catch((error) => {
      });
  };
};

export const fetchLoggedInUser = () => {
  return (dispatch) => {
    callAPI("/users/profileInfo", "GET")
      .then((response) => {
        dispatch({
          type: USER.SET_USER_DETAIL,
          payload: response.body.data,
        });
      })
      .catch((error) => {
      });
  };
};

export const logout = (userId) => {
  // if userId is undefined for some reason
  if (!userId) {
    removeJWTCookie();
    window.location.href = "/";
  }
  return (dispatch) => {
    callAPI("/users/logout/" + userId, "POST")
      .then((response) => {
        removeJWTCookie();
        window.location.href = "/";
      })
      .catch((error) => {
      });
  };
};

export const fetchProfileInfo = () => {
  return (dispatch) => {
    callAPI("/users/profileInfo", "GET")
      .then((response) => {
        dispatch({
          type: USER.SET_PROFILE_INFO,
          payload: response.body.data,
        });
      })
      .catch((error) => {
      });
  };
};

export const fetchProblemsForAuthor = (userId) => {
  return (dispatch) => {
    callAPI("/problems/with-author/" + userId, "GET")
      .then((response) => {
        dispatch({
          type: USER.SET_AUTHOR_PROBLEMS,
          payload: response.body.data,
        });
      })
      .catch(processCallAPIException(dispatch));
  };
};

export const fetchContestsForAuthor = (userId) => {
  return (dispatch) => {
    callAPI("/contests/with-author/" + userId, "GET")
      .then((response) => {
        dispatch({
          type: USER.SET_AUTHOR_CONTESTS,
          payload: response.body.data,
        });
      })
      .catch(processCallAPIException(dispatch));
  };
};

export const uploadAvatar = (avatarFile) => {
  return () => {
    callAPI("/users/avatar", "POST", { file: avatarFile })
      .then((response) => {
        // set avatarUrl in local storage
        // cookie value has restriction over some characters
        localStorage.setItem(LOCAL_STORAGE_ITEM_AVATAR_URL, response.body.data.avatarUrl);
      })
      .catch((error) => {
        // Save error in reducer state
      });
  };
};

export const updateProfileInfo = (requestObject) => {
  return (dispatch) => {
    const request = {
      userName: requestObject.userName,
      fullName: requestObject.fullName,
      countryCode: requestObject.countryCode,
      linkedinProfileUrl: requestObject.linkedinProfileUrl,
      githubProfileUrl: requestObject.githubProfileUrl,
      subscribed: requestObject.subscribed ? "1" : "0",
      bkashPhoneNumber: requestObject.bkashPhoneNumber,
      organization: requestObject.organization,
      dept: requestObject.dept,
      roll: requestObject.roll
    };

    callAPI("/users/profileInfo", "POST", request)
      .then((response) => {
        dispatch({
          type: USER.SET_PROFILE_INFO,
          payload: response.body.data,
        });
      })
      .catch((error) => {
      });
  };
};