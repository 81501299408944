import React, { Component } from "react";
import {
  Segment,
  Button,
  Form,
  Icon,
  Modal,
  Dimmer,
  Loader,
  Card,
  Image,
  Divider
} from "semantic-ui-react";
import WarningMessage from "../Warning/WarningMessage";
import { isLoggedIn } from "../../../src/utils/cookieUtil";

class ContestImages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showFileSizeError: false,
      showLanguageMissingError: false,
      showFileMissingError: false,
      showIsLoggedInError: false,
      languageId: "",
      contestId: "",
      problemId: "",
      isSubmitted: false,
      languageNote: '',
      ideOpened: false,
      screenClientWidthBeforeIdeOpen: null
    };
  }

  componentDidMount() {
    
  }

  onClickDeleteImage(extraInfo) {
    this.props.deleteContestInfo(extraInfo);
  }

  onUploadImage(contestId) {
    if (!isLoggedIn()) {
      this.setState({ showIsLoggedInError: true });
    } else if (this.state.fileSizeInKB > 10000) {
      this.setState({ showFileSizeError: true });
    } else if (!this.state.file) {
      this.setState({ showFileMissingError: true });
    } else {
      const data = {
        sourceFile: this.state.file,
        contestId: contestId
      };
      this.setState({ isSubmitted: true });
      this.props.uploadContestImage(data);
    }
  }


  fileInputRef = React.createRef();

  onFormSubmit = (e) => {
    e.preventDefault(); // Stop form submit
    // this.state.file contains the file
  };

  fileChange = (e) => {
    this.setState(
      { file: e.target.files[0], fileSizeInKB: e.target.files[0].size / 1024 },
      () => {
        if (this.state.fileSizeInKB > 10000) {
          this.setState({ showFileSizeError: true });
          this.setState({ showFileMissingError: true });
        } else {
          this.setState({ showFileMissingError: false });
        }
      }
    );
  };


  render() {
    const images = this.props.images;
    const contest = this.props.contest;

    console.log('images', images)

    const fileSizeErrorModal = (
      <Modal
        closeIcon
        open={this.state.showFileSizeError}
        onClose={() => this.setFileSizeErrorModalOpen(false)}
        onOpen={() => this.setFileSizeErrorModalOpen(true)}
        size="tiny"
        dimmer="blurring"
      >
        <Modal.Content>
          <WarningMessage
            title="File size error"
            message="Please submit a file less than 200 KB"
          />
        </Modal.Content>
      </Modal>
    );

    const submitFileNameDiv =
      this.state.file == null ? null : (
        <div style={{ marginTop: "10px" }}>
          <Icon color="brown" name="angle double up" /> {this.state.file.name}
        </div>
      );

    const submitFileDiv = this.state.isSubmitted ?
      <Dimmer active inverted>
        <Loader inverted>Submitting</Loader>
      </Dimmer> : (
        <div style={{ marginTop: "10px" }}>
          <Form onSubmit={this.onFormSubmit}>
            <Form.Field>
              <Button
                className="button-style"
                id="file-upload-button"
                size="medium"
                content="Image File"
                labelPosition="left"
                icon="file"
                onClick={() => this.fileInputRef.current.click()}
              />
              <input
                ref={this.fileInputRef}
                type="file"
                hidden
                onChange={this.fileChange}
              />
            </Form.Field>
          </Form>
          <div style={{ marginTop: "10px" }}>
              <Button
                className="button-style"
                id="solution-submit-button"
                style={{ background: "#ededed" }}
                size="medium"
                content="Submit"
                labelPosition="left"
                icon="cloud upload"
                onClick={() =>
                  this.onUploadImage(contest.id)
                }
              />
            </div>
        </div>
      );

    const imageListDiv = (
      <div>
        <Divider/>
        <h2 className="base-color" style={{marginTop: '20px', textAlign: 'center'}}>Existing Images</h2>
        <Card.Group style={{marginTop: '50px'}}>
          {images.map((image, index) => (
            <Card 
              key={index}
              size="medium"
            >
              <Image src={image.imageUrl} wrapped ui={false} />
              <Icon
                name='close'
                size='large'
                onClick={() => this.onClickDeleteImage(image)}
                style={{ cursor: 'pointer', position: 'absolute', top: '-20px', right: '-20px', color: 'red' }}
              />
            </Card>
          ))}
        </Card.Group>
      </div> 
    );
  
    const renderDiv = (
        <Segment raised>
          <div className="problem-submit-div">
            <h3 className="text-style" style={{ textAlign: "center" }}>
              Upload Image
            </h3>
            {submitFileDiv}
            {submitFileNameDiv}
            {images && images.length > 0 ? imageListDiv : null}
          </div>
        </Segment>
    );

    return <div>{renderDiv}</div>;
  }
}

export default ContestImages;
