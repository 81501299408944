import React, { Component } from "react";
import { Header, Icon, Segment } from "semantic-ui-react";

class ActivationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "",
    };
  }

  componentDidMount() {
    document.title = "Account activation";
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.get("type")) {
      this.setState({ type: urlParams.get("type") });
    }
  }

  render() {
    let text = "";
    let icon = <Icon name="check circle" color="green" />;

    if (this.state.type.includes("invalid")) {
      icon = <Icon name="close" color="red" />;
    }

    if (this.state.type === "accountRegistered") {
      text =
        "Account was created successfully. \n Please check your email to activate.";
    } else if (this.state.type === "accountActivated") {
      text = "Account is activated. Please login";
    } else if (this.state.type === "resetPasswordEmailSent") {
      text =
        "Password reset instruction is sent to the email. \n Please check your email.";
    } else if (this.state.type === "passwordChanged") {
      text =
        "Password was changed successfully. Please login using new password.";
    } else if (this.state.type === "invalidToken") {
      text = "Requested token is invalid. Please try again."
    }

    return (
      <div className="main-container">
        <Segment>
          <Header icon size="tiny" textAlign="center">
            {icon}
            <h3 className="base-color">{text}</h3>
          </Header>
        </Segment>
      </div>
    );
  }
}

export default ActivationContainer;
