import React from 'react';
import ReactCountryFlag from "react-country-flag";

function DefaultFlag(props) {
    return <ReactCountryFlag
        countryCode={props.countryCode.toUpperCase()}
        svg
        style={{
            width: '1.5em',
            height: '1.5em',
            marginLeft: '5px',
            borderRadius: '0.4em'
        }}
    />;
}
export default DefaultFlag;