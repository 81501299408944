import callAPI from "../utils/callAPI";
import { STATISTICS } from "./actions";
import processCallApiException from "../utils/processCallAPIException";

export const fetchStatistics = () => {
  return (dispatch) => {
    callAPI("/statistics/search", "GET")
      .then((response) => {
        dispatch({
          type: STATISTICS.SET_STATISTICS,
          payload: response.body.data,
        });
      }).catch(processCallApiException(dispatch));
  };
};