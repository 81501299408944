import { CONTEST, PROBLEM } from "../actions/actions";

const initialState = {
  list: [],
  ratingUsers: [],
  submissions: [],
  allProblems: [],
  problems: [],
  standings: [],
  problemSetters: [],
  extraInfo: [],
  userRegistrationInfo: null,
  detail: null,
  errorCode: null
};

const contestReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONTEST.SET_CONTEST_LIST:
      return { ...state, list: action.payload };
    case CONTEST.SET_CONTEST_RATING_USERS:
      return { ...state, ratingUsers: action.payload };
    case CONTEST.SET_CONTEST_DETAIL:
      return { ...state, detail: action.payload };
    case CONTEST.SET_CONTEST_SUBMISSIONS:
      return { ...state, submissions: action.payload };
    case CONTEST.SET_CONTEST_PROBLEM_SETTERS:
      return { ...state, problemSetters: action.payload };
    case CONTEST.SET_CONTEST_PROBLEMS:
      return { ...state, problems: action.payload };
    case PROBLEM.SET_PROBLEM_LIST_FOR_ADMIN:
      return { ...state, allProblems: action.payload };
    case CONTEST.SET_CONTEST_STANDINGS:
      return { ...state, standings: action.payload };
    case CONTEST.SET_CONTEST_EXTRA_INFO:
      return { ...state, extraInfo: action.payload };
    case CONTEST.SET_ERROR_CODE:
      return { ...state, errorCode: action.payload };
    case CONTEST.SET_CONTEST_USER_REGISTRATION_INFO:
      return { ...state, userRegistrationInfo: action.payload };
    default:
      return state;
  }
};

export default contestReducer;
