export const SIGNIN_MODAL = {
  signInTitle: "Login",
  signUpTitle: "Create account",
  signInWarningMessage:
    "Email or password is wrong. Please use the correct one.",
  forgotPasswordTitle:
    "Enter your email address to receive a password reset link.",
  checkBoxText:
    "Get emails from CodePanja about contests and event. If you change your mind, you can unsubscribe at any time.",
};

export const JWT_NORMAL_USER_TOKEN_COOKIE_NAME = "defaultToken";

export const JWT_TEAM_USER_TOKEN_COOKIE_NAME = "teamToken";

export const USER_TYPE = "userType";
export const USER_TYPE_VALUE_NORMAL = "normal_user";
export const USER_TYPE_VALUE_TEAM = "team_user";
export const USER_ID = "id";
export const USER_NAME = "userName";
export const USER_GOOGLE_PROFILE_PIC = "googleProfilePictureUrl";
export const USER_PERMISSION = "permission";
export const LOCAL_STORAGE_ITEM_AVATAR_URL = "avatarUrl";

// set timeout of api
export const TIMEOUT = 60000; // 1 min

export const IDE_THEME = [
  {
    "id": 1,
    "text": "Monokai",
    "value": "monokai"
  },
  {
    "id": 2,
    "text": "Xcode",
    "value": "xcode"
  },
  {
    "id": 3,
    "text": "Solarized Dark",
    "value": "solarized_dark"
  },
  {
    "id": 4,
    "text": "Solarized Light",
    "value": "solarized_light"
  }
];

export const IDE_FONT_SIZE = [
  {
    "id": 1,
    "value": 15,
    "text": "15"
  },
  {
    "id": 2,
    "value": 16,
    "text": "16"
  },
  {
    "id": 3,
    "value": 17,
    "text": "17"
  },
  {
    "id": 4,
    "value": 18,
    "text": "18"
  },
  {
    "id": 5,
    "value": 19,
    "text": "19"
  },
  {
    "id": 6,
    "value": 20,
    "text": "20"
  },
  {
    "id": 7,
    "value": 21,
    "text": "21"
  },
  {
    "id": 8,
    "value": 22,
    "text": "22"
  },
  {
    "id": 9,
    "value": 23,
    "text": "23"
  },
  {
    "id": 10,
    "value": 24,
    "text": "24"
  },
  {
    "id": 11,
    "value": 25,
    "text": "25"
  }
];

export const LANGUAGE = [
  {
    "id": 50,
    "value": 50,
    "text": "C (GCC 9.2.0)",
    "ide_mode": "c_cpp",
    "ide_placeholder": '#include <stdio.h>\n\nint main(void) { \n   printf("Hello World!"); \n   return 0;\n}'
  },
  {
    "id": 54,
    "value": 54,
    "text": "C++ (GCC 9.2.0)",
    "ide_mode": "c_cpp",
    "ide_placeholder": '#include <iostream>\n\nint main() {\n  std::cout << "hello, world" << std::endl;\n  return 0;\n}'
  },
  {
    "id": 62,
    "value": 62,
    "text": "Java (13.0.1)",
    "ide_mode": "java",
    "ide_placeholder": 'import java.util.Scanner;\n\n//The class name must be Main\npublic class Main {\n  public static void main(String[] args) {\n    // sample code \n    Scanner in = new Scanner(System.in); // Standard input\n    System.out.println(in.nextInt() + in.nextInt()); // Standard output\n  }\n}'
  },
  {
    "id": 70,
    "value": 70,
    "text": "Python (2.7.17)",
    "ide_mode": "python",
    "ide_placeholder": 'print("hello, world")'
  },
  {
    "id": 71,
    "value": 71,
    "text": "Python (3.8.1)",
    "ide_mode": "python",
    "ide_placeholder": 'print("hello, world")'
  },
  {
    "id": 60,
    "value": 60,
    "text": "Go (1.13.5)",
    "ide_mode": "golang",
    "ide_placeholder": 'package main\n\nimport "fmt"\n\nfunc main() {\n    fmt.Println("hello, world")\n}'
  },
  {
    "id": 72,
    "value": 72,
    "text": "Ruby (2.7.0)",
    "ide_mode": "ruby",
    "ide_placeholder": 'puts "hello, world"'
  },
  {
    "id": 63,
    "value": 63,
    "text": "JavaScript (Node.js 12.14.0)",
    "ide_mode": "javascript",
    "ide_placeholder": 'console.log("hello, world");'
  },
  {
    "id": 74,
    "value": 74,
    "text": "TypeScript (3.7.4)",
    "ide_mode": "typescript",
    "ide_placeholder": 'console.log("hello, world");'
  },
  {
    "id": 46,
    "value": 46,
    "text": "Bash (5.0.0)",
    "ide_mode": "bash",
    "ide_placeholder": 'echo "hello, world"'
  }
];

export const CONTEST_STATUS = [
  { key: 'v', text: 'Visible', value: 10 },
  { key: 'n', text: 'Not Visible', value: 20 },
  { key: 't', text: 'Test', value: 30 },
  { key: 't', text: 'Recruit', value: 40 }
];

export const CONTEST_REGISTRATION_WAYS = [
  { key: 'manual', text: 'Manually By Admin', value: true },
  { key: 'anyone', text: 'Anyone', value: false }
];


export const CONTEST_RATED_STATUS = [
  { key: 'r', text: 'Rated', value: 10 },
  { key: 'n', text: 'Not Rated', value: 20 }
];

export const COUNTRY = [
  { name: 'Afghanistan', countryCode: 'af' },
  { name: 'Aland Islands', countryCode: 'ax' },
  { name: 'Albania', countryCode: 'al' },
  { name: 'Algeria', countryCode: 'dz' },
  { name: 'American Samoa', countryCode: 'as' },
  { name: 'Andorra', countryCode: 'ad' },
  { name: 'Angola', countryCode: 'ao' },
  { name: 'Anguilla', countryCode: 'ai' },
  { name: 'Antigua', countryCode: 'ag' },
  { name: 'Argentina', countryCode: 'ar' },
  { name: 'Armenia', countryCode: 'am' },
  { name: 'Aruba', countryCode: 'aw' },
  { name: 'Australia', countryCode: 'au' },
  { name: 'Austria', countryCode: 'at' },
  { name: 'Azerbaijan', countryCode: 'az' },
  { name: 'Bahamas', countryCode: 'bs' },
  { name: 'Bahrain', countryCode: 'bh' },
  { name: 'Bangladesh', countryCode: 'bd' },
  { name: 'Barbados', countryCode: 'bb' },
  { name: 'Belarus', countryCode: 'by' },
  { name: 'Belgium', countryCode: 'be' },
  { name: 'Belize', countryCode: 'bz' },
  { name: 'Benin', countryCode: 'bj' },
  { name: 'Bermuda', countryCode: 'bm' },
  { name: 'Bhutan', countryCode: 'bt' },
  { name: 'Bolivia', countryCode: 'bo' },
  { name: 'Bosnia', countryCode: 'ba' },
  { name: 'Botswana', countryCode: 'bw' },
  { name: 'Bouvet Island', countryCode: 'bv' },
  { name: 'Brazil', countryCode: 'br' },
  { name: 'British Virgin Islands', countryCode: 'vg' },
  { name: 'Brunei', countryCode: 'bn' },
  { name: 'Bulgaria', countryCode: 'bg' },
  { name: 'Burkina Faso', countryCode: 'bf' },
  { name: 'Burma', countryCode: 'mm', alias: 'Myanmar' },
  { name: 'Burundi', countryCode: 'bi' },
  { name: 'Caicos Islands', countryCode: 'tc' },
  { name: 'Cambodia', countryCode: 'kh' },
  { name: 'Cameroon', countryCode: 'cm' },
  { name: 'Canada', countryCode: 'ca' },
  { name: 'Cape Verde', countryCode: 'cv' },
  { name: 'Cayman Islands', countryCode: 'ky' },
  { name: 'Central African Republic', countryCode: 'cf' },
  { name: 'Chad', countryCode: 'td' },
  { name: 'Chile', countryCode: 'cl' },
  { name: 'China', countryCode: 'cn' },
  { name: 'Christmas Island', countryCode: 'cx' },
  { name: 'Cocos Islands', countryCode: 'cc' },
  { name: 'Colombia', countryCode: 'co' },
  { name: 'Comoros', countryCode: 'km' },
  { name: 'Congo', countryCode: 'cd' },
  { name: 'Congo Brazzaville', countryCode: 'cg' },
  { name: 'Cook Islands', countryCode: 'ck' },
  { name: 'Costa Rica', countryCode: 'cr' },
  { name: 'Cote Divoire', countryCode: 'ci' },
  { name: 'Croatia', countryCode: 'hr' },
  { name: 'Cuba', countryCode: 'cu' },
  { name: 'Cyprus', countryCode: 'cy' },
  { name: 'Czech Republic', countryCode: 'cz' },
  { name: 'Denmark', countryCode: 'dk' },
  { name: 'Djibouti', countryCode: 'dj' },
  { name: 'Dominica', countryCode: 'dm' },
  { name: 'Dominican Republic', countryCode: 'do' },
  { name: 'Ecuador', countryCode: 'ec' },
  { name: 'Egypt', countryCode: 'eg' },
  { name: 'El Salvador', countryCode: 'sv' },
  { name: 'Equatorial Guinea', countryCode: 'gq' },
  { name: 'Eritrea', countryCode: 'er' },
  { name: 'Estonia', countryCode: 'ee' },
  { name: 'Ethiopia', countryCode: 'et' },
  { name: 'Europeanunion', countryCode: 'eu' },
  { name: 'Falkland Islands', countryCode: 'fk' },
  { name: 'Faroe Islands', countryCode: 'fo' },
  { name: 'Fiji', countryCode: 'fj' },
  { name: 'Finland', countryCode: 'fi' },
  { name: 'France', countryCode: 'fr' },
  { name: 'French Guiana', countryCode: 'gf' },
  { name: 'French Polynesia', countryCode: 'pf' },
  { name: 'French Territories', countryCode: 'tf' },
  { name: 'Gabon', countryCode: 'ga' },
  { name: 'Gambia', countryCode: 'gm' },
  { name: 'Georgia', countryCode: 'ge' },
  { name: 'Germany', countryCode: 'de' },
  { name: 'Ghana', countryCode: 'gh' },
  { name: 'Gibraltar', countryCode: 'gi' },
  { name: 'Greece', countryCode: 'gr' },
  { name: 'Greenland', countryCode: 'gl' },
  { name: 'Grenada', countryCode: 'gd' },
  { name: 'Guadeloupe', countryCode: 'gp' },
  { name: 'Guam', countryCode: 'gu' },
  { name: 'Guatemala', countryCode: 'gt' },
  { name: 'Guinea', countryCode: 'gn' },
  { name: 'Guinea-Bissau', countryCode: 'gw' },
  { name: 'Guyana', countryCode: 'gy' },
  { name: 'Haiti', countryCode: 'ht' },
  { name: 'Heard Island', countryCode: 'hm' },
  { name: 'Honduras', countryCode: 'hn' },
  { name: 'Hong Kong', countryCode: 'hk' },
  { name: 'Hungary', countryCode: 'hu' },
  { name: 'Iceland', countryCode: 'is' },
  { name: 'India', countryCode: 'in' },
  { name: 'Indian Ocean Territory', countryCode: 'io' },
  { name: 'Indonesia', countryCode: 'id' },
  { name: 'Iran', countryCode: 'ir' },
  { name: 'Iraq', countryCode: 'iq' },
  { name: 'Ireland', countryCode: 'ie' },
  { name: 'Israel', countryCode: 'il' },
  { name: 'Italy', countryCode: 'it' },
  { name: 'Jamaica', countryCode: 'jm' },
  { name: 'Jan Mayen', countryCode: 'sj', alias: 'Svalbard' },
  { name: 'Japan', countryCode: 'jp' },
  { name: 'Jordan', countryCode: 'jo' },
  { name: 'Kazakhstan', countryCode: 'kz' },
  { name: 'Kenya', countryCode: 'ke' },
  { name: 'Kiribati', countryCode: 'ki' },
  { name: 'Kuwait', countryCode: 'kw' },
  { name: 'Kyrgyzstan', countryCode: 'kg' },
  { name: 'Laos', countryCode: 'la' },
  { name: 'Latvia', countryCode: 'lv' },
  { name: 'Lebanon', countryCode: 'lb' },
  { name: 'Lesotho', countryCode: 'ls' },
  { name: 'Liberia', countryCode: 'lr' },
  { name: 'Libya', countryCode: 'ly' },
  { name: 'Liechtenstein', countryCode: 'li' },
  { name: 'Lithuania', countryCode: 'lt' },
  { name: 'Luxembourg', countryCode: 'lu' },
  { name: 'Macau', countryCode: 'mo' },
  { name: 'Macedonia', countryCode: 'mk' },
  { name: 'Madagascar', countryCode: 'mg' },
  { name: 'Malawi', countryCode: 'mw' },
  { name: 'Malaysia', countryCode: 'my' },
  { name: 'Maldives', countryCode: 'mv' },
  { name: 'Mali', countryCode: 'ml' },
  { name: 'Malta', countryCode: 'mt' },
  { name: 'Marshall Islands', countryCode: 'mh' },
  { name: 'Martinique', countryCode: 'mq' },
  { name: 'Mauritania', countryCode: 'mr' },
  { name: 'Mauritius', countryCode: 'mu' },
  { name: 'Mayotte', countryCode: 'yt' },
  { name: 'Mexico', countryCode: 'mx' },
  { name: 'Micronesia', countryCode: 'fm' },
  { name: 'Moldova', countryCode: 'md' },
  { name: 'Monaco', countryCode: 'mc' },
  { name: 'Mongolia', countryCode: 'mn' },
  { name: 'Montenegro', countryCode: 'me' },
  { name: 'Montserrat', countryCode: 'ms' },
  { name: 'Morocco', countryCode: 'ma' },
  { name: 'Mozambique', countryCode: 'mz' },
  { name: 'Namibia', countryCode: 'na' },
  { name: 'Nauru', countryCode: 'nr' },
  { name: 'Nepal', countryCode: 'np' },
  { name: 'Netherlands', countryCode: 'nl' },
  { name: 'Netherlandsantilles', countryCode: 'an' },
  { name: 'New Caledonia', countryCode: 'nc' },
  { name: 'New Guinea', countryCode: 'pg' },
  { name: 'New Zealand', countryCode: 'nz' },
  { name: 'Nicaragua', countryCode: 'ni' },
  { name: 'Niger', countryCode: 'ne' },
  { name: 'Nigeria', countryCode: 'ng' },
  { name: 'Niue', countryCode: 'nu' },
  { name: 'Norfolk Island', countryCode: 'nf' },
  { name: 'North Korea', countryCode: 'kp' },
  { name: 'Northern Mariana Islands', countryCode: 'mp' },
  { name: 'Norway', countryCode: 'no' },
  { name: 'Oman', countryCode: 'om' },
  { name: 'Pakistan', countryCode: 'pk' },
  { name: 'Palau', countryCode: 'pw' },
  { name: 'Palestine', countryCode: 'ps' },
  { name: 'Panama', countryCode: 'pa' },
  { name: 'Paraguay', countryCode: 'py' },
  { name: 'Peru', countryCode: 'pe' },
  { name: 'Philippines', countryCode: 'ph' },
  { name: 'Pitcairn Islands', countryCode: 'pn' },
  { name: 'Poland', countryCode: 'pl' },
  { name: 'Portugal', countryCode: 'pt' },
  { name: 'Puerto Rico', countryCode: 'pr' },
  { name: 'Qatar', countryCode: 'qa' },
  { name: 'Reunion', countryCode: 're' },
  { name: 'Romania', countryCode: 'ro' },
  { name: 'Russia', countryCode: 'ru' },
  { name: 'Rwanda', countryCode: 'rw' },
  { name: 'Saint Helena', countryCode: 'sh' },
  { name: 'Saint Kitts and Nevis', countryCode: 'kn' },
  { name: 'Saint Lucia', countryCode: 'lc' },
  { name: 'Saint Pierre', countryCode: 'pm' },
  { name: 'Saint Vincent', countryCode: 'vc' },
  { name: 'Samoa', countryCode: 'ws' },
  { name: 'San Marino', countryCode: 'sm' },
  { name: 'Sandwich Islands', countryCode: 'gs' },
  { name: 'Sao Tome', countryCode: 'st' },
  { name: 'Saudi Arabia', countryCode: 'sa' },
  { name: 'Scotland', countryCode: 'gb sct' },
  { name: 'Senegal', countryCode: 'sn' },
  { name: 'Serbia', countryCode: 'cs' },
  { name: 'Serbia', countryCode: 'rs' },
  { name: 'Seychelles', countryCode: 'sc' },
  { name: 'Sierra Leone', countryCode: 'sl' },
  { name: 'Singapore', countryCode: 'sg' },
  { name: 'Slovakia', countryCode: 'sk' },
  { name: 'Slovenia', countryCode: 'si' },
  { name: 'Solomon Islands', countryCode: 'sb' },
  { name: 'Somalia', countryCode: 'so' },
  { name: 'South Africa', countryCode: 'za' },
  { name: 'South Korea', countryCode: 'kr' },
  { name: 'Spain', countryCode: 'es' },
  { name: 'Sri Lanka', countryCode: 'lk' },
  { name: 'Sudan', countryCode: 'sd' },
  { name: 'Suriname', countryCode: 'sr' },
  { name: 'Swaziland', countryCode: 'sz' },
  { name: 'Sweden', countryCode: 'se' },
  { name: 'Switzerland', countryCode: 'ch' },
  { name: 'Syria', countryCode: 'sy' },
  { name: 'Taiwan', countryCode: 'tw' },
  { name: 'Tajikistan', countryCode: 'tj' },
  { name: 'Tanzania', countryCode: 'tz' },
  { name: 'Thailand', countryCode: 'th' },
  { name: 'Timorleste', countryCode: 'tl' },
  { name: 'Togo', countryCode: 'tg' },
  { name: 'Tokelau', countryCode: 'tk' },
  { name: 'Tonga', countryCode: 'to' },
  { name: 'Trinidad', countryCode: 'tt' },
  { name: 'Tunisia', countryCode: 'tn' },
  { name: 'Turkey', countryCode: 'tr' },
  { name: 'Turkmenistan', countryCode: 'tm' },
  { name: 'Tuvalu', countryCode: 'tv' },
  { name: 'U.A.E.', countryCode: 'ae', alias: 'United Arab Emirates' },
  { name: 'Uganda', countryCode: 'ug' },
  { name: 'Ukraine', countryCode: 'ua' },
  { name: 'United Kingdom', countryCode: 'gb', alias: 'uk' },
  { name: 'United States', countryCode: 'us', alias: 'America' },
  { name: 'Uruguay', countryCode: 'uy' },
  { name: 'US Minor Islands', countryCode: 'um' },
  { name: 'US Virgin Islands', countryCode: 'vi' },
  { name: 'Uzbekistan', countryCode: 'uz' },
  { name: 'Vanuatu', countryCode: 'vu' },
  { name: 'Vatican City', countryCode: 'va' },
  { name: 'Venezuela', countryCode: 've' },
  { name: 'Vietnam', countryCode: 'vn' },
  { name: 'Wales', countryCode: 'gb wls' },
  { name: 'Wallis and Futuna', countryCode: 'wf' },
  { name: 'Western Sahara', countryCode: 'eh' },
  { name: 'Yemen', countryCode: 'ye' },
  { name: 'Zambia', countryCode: 'zm' },
  { name: 'Zimbabwe', countryCode: 'zw' }
];

export const ORGANIZATION = [
  { id: 1, name: 'University of Dhaka' },
  { id: 2, name: 'University of Rajshahi' },
  { id: 3, name: 'Bangladesh Agricultural University' },
  { id: 4, name: 'Bangladesh University of Engineering & Technology' },
  { id: 5, name: 'University of Chittagong' },
  { id: 6, name: 'Jahangirnagar University' },
  { id: 7, name: 'Islamic University, Bangladesh' },
  { id: 8, name: 'Shahjalal University of Science and Technology' },
  { id: 9, name: 'Khulna University' },
  { id: 10, name: 'Bangabandhu Sheikh Mujib Medical Universityname:' },
  { id: 11, name: 'Bangabandhu Sheikh Mujibur Rahman Agricultural University' },
  { id: 12, name: 'Hajee Mohammad Danesh Science & Technology University' },
  { id: 13, name: 'Mawlana Bhashani Science and Technology University' },
  { id: 14, name: 'Patuakhali Science and Technology University' },
  { id: 15, name: 'Sher-e-Bangla Agricultural University' },
  { id: 16, name: 'Dhaka University of Engineering & Technology' },
  { id: 17, name: 'Rajshahi University of Engineering & Technology' },
  { id: 18, name: 'Chittagong University of Engineering & Technology' },
  { id: 19, name: 'Khulna University of Engineering & Technology' },
  { id: 20, name: 'Jagannath University' },
  { id: 21, name: 'Jatiya Kabi Kazi Nazrul Islam University' },
  { id: 22, name: 'Chittagong Veterinary and Animal Sciences University' },
  { id: 23, name: 'Sylhet Agricultural University' },
  { id: 24, name: 'Comilla University' },
  { id: 25, name: 'Noakhali Science and Technology University' },
  { id: 26, name: 'Jessore University of Science & Technology' },
  { id: 27, name: 'Pabna University of Science and Technology' },
  { id: 28, name: 'Bangladesh University of Professionals' },
  { id: 29, name: 'Begum Rokeya University' },
  { id: 30, name: 'Bangladesh University of Textiles' },
  { id: 31, name: 'University of Barisal' },
  { id: 32, name: 'Bangabandhu Sheikh Mujibur Rahman Science and Technology University' },
  { id: 33, name: 'Islamic Arabic University' },
  { id: 34, name: 'Bangabandhu Sheikh Mujibur Rahman Maritime University' },
  { id: 35, name: 'Rangamati Science and Technology University' },
  { id: 36, name: 'Dhaka International University' },
  { id: 37, name: 'Ahsanullah University of Science and Technology' },
  { id: 38, name: 'BRAC University' },
  { id: 39, name: 'East West University' },
  { id: 40, name: 'North South University' },
  { id: 41, name: 'American International University-Bangladesh' },
  { id: 42, name: 'Independent University, Bangladesh' },
  { id: 43, name: 'Bangladesh University of Business and Technology' },
  { id: 44, name: 'Gono Bishwabidyalay' },
  { id: 45, name: 'Hamdard University Bangladesh' },
  { id: 46, name: 'International Islamic University, Chittagong' },
  { id: 47, name: 'Chittagong Independent University { id: CIU)' },
  { id: 48, name: 'University of Science & Technology Chittagong' },
  { id: 49, name: 'Begum Gulchemonara Trust University' },
  { id: 50, name: 'East Delta University' },
  { id: 51, name: 'Bangladesh Army University of Science and Technology' },
  { id: 52, name: 'Bangladesh Army International University of Science & Technology' },
  { id: 53, name: 'Britannia University' },
  { id: 54, name: 'Feni University' },
  { id: 55, name: 'Bangladesh Army University of Engineering & Technology' },
  { id: 56, name: 'Premier University, Chittagong' },
  { id: 57, name: 'Exim Bank Agricultural University Bangladesh' },
  { id: 58, name: 'Southern University, Bangladesh' },
  { id: 59, name: 'Port City International University' },
  { id: 60, name: 'Coxs Bazar International University' },
  { id: 61, name: 'Notre Dame University Bangladesh' },
  { id: 62, name: 'Asian University of Bangladesh' },
  { id: 63, name: 'Asa University Bangladesh' },
  { id: 64, name: 'Atish Dipankar University of Science and Technology' },
  { id: 65, name: 'Bangladesh Islami University' },
  { id: 66, name: 'Bangladesh University' },
  { id: 67, name: 'Central Womens University' },
  { id: 68, name: 'City University, Bangladesh' },
  { id: 69, name: 'Daffodil International University' },
  { id: 70, name: 'Eastern University, Bangladesh' },
  { id: 71, name: 'Green University of Bangladesh' },
  { id: 72, name: 'IBAIS University' },
  { id: 73, name: 'Sonargaon University' },
  { id: 74, name: 'International University of Business Agriculture and Technology' },
  { id: 75, name: 'Manarat International University' },
  { id: 76, name: 'Millennium University' },
  { id: 77, name: 'Northern University, Bangladesh' },
  { id: 78, name: 'North Western University, Bangladesh' },
  { id: 79, name: 'Peoples University of Bangladesh' },
  { id: 80, name: 'Presidency University' },
  { id: 81, name: 'Pundra University of Science and Technology' },
  { id: 82, name: 'Prime University' },
  { id: 83, name: 'European University of Bangladesh' },
  { id: 84, name: 'Primeasia University' },
  { id: 85, name: 'Queens University' },
  { id: 86, name: 'Rajshahi Science & Technology University' },
  { id: 87, name: 'Royal University of Dhaka' },
  { id: 88, name: 'Shanto-Mariam University of Creative Technology' },
  { id: 89, name: 'Southeast University' },
  { id: 90, name: 'Stamford University Bangladesh' },
  { id: 91, name: 'State University of Bangladesh' },
  { id: 92, name: 'United International University' },
  { id: 93, name: 'University of Asia Pacific { id: Bangladesh)' },
  { id: 94, name: 'University of Development Alternative' },
  { id: 95, name: 'University of Information Technology and Sciences' },
  { id: 96, name: 'University of Liberal Arts Bangladesh' },
  { id: 97, name: 'Fareast International University' },
  { id: 98, name: 'University of South Asia, Bangladesh' },
  { id: 99, name: 'Uttara University' },
  { id: 100, name: 'Victoria University of Bangladesh' },
  { id: 101, name: 'Varendra University' },
  { id: 102, name: 'World University of Bangladesh' },
  { id: 103, name: 'Leading University' },
  { id: 104, name: 'Metropolitan University' },
  { id: 105, name: 'North East University Bangladesh' },
  { id: 106, name: 'Sylhet International University' },
  { id: 107, name: 'Khwaja Yunus Ali University' },
  { id: 108, name: 'Global University Bangladesh' },
  { id: 109, name: 'University of Creative Technology Chittagong' },
  { id: 110, name: 'Z H Sikder University of Science & Technology' },
  { id: 111, name: 'Central University of Science and Technology' },
  { id: 112, name: 'Canadian University of Bangladesh' },
  { id: 113, name: 'First Capital University of Bangladesh' },
  { id: 114, name: 'Ishaka International University' },
  { id: 115, name: 'Northern University of Business & Technology, Khulna' },
  { id: 116, name: 'North Bengal International University' },
  { id: 117, name: 'Ranada Prasad Shaha University' },
  { id: 118, name: 'Islamic University of Technology' },
  { id: 119, name: 'Asian University for Women' },
  { id: 120, name: 'Bangladesh Open University' },
  { id: 121, name: 'National University of Bangladesh' },
  { id: 122, name: 'Islamic Arabic University' }
];

export const DEPT = [
  { id: 1, name: 'CSE' },
  { id: 2, name: 'EEE' },
  { id: 3, name: 'ICE' },
  { id: 4, name: 'ETE' },
  { id: 5, name: 'ECE' },
  { id: 7, name: 'MATH' },
  { id: 8, name: 'PHYSICS' },
  { id: 9, name: 'CHEMISTRY' }
];