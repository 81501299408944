import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import assign from "lodash/assign";
import { Segment, Table, Icon, Pagination, Dropdown, Label, Statistic } from "semantic-ui-react";
import * as RanklistActions from "../actions/RanklistActions";
import { COUNTRY } from "../constants";
import DefaultAvatar from "../Components/DefaultAvatar";
import NotFound from "../Components/Error/NotFound";
import totalSubmissionImage from '../assets/icons/submit_new2.png';
import totalAcceptedImage from '../assets/icons/success_new3.png';
import AvatarWithFlag from "../Components/AvatarWithFlag";

class RanklistContainer extends Component {
  constructor(props) {
    super(props);
    this.onClickUser = this.onClickUser.bind(this);
    this.state = {
      page: 1,
      filterValue: "Max Solved"
    };
  }

  componentDidMount() {
    document.title = "Ranks";
    this.props.updatePath();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    let sortBy = 'Max Solved';

    if (urlParams.has("filter")) {
      sortBy = urlParams.get("filter");
      this.setState({ filterValue: urlParams.get("filter") });
    }

    const searchParams = {
      sortBy: sortBy
    };

    this.props.fetchRankList(searchParams);
  }

  setPageNum = (event, { activePage }) => {
    this.setState({ page: activePage });
  };

  onClickUser(userId) {
    window.location.href = "/users/" + userId;
  }

  getCountryName(countryCode) {
    const countryInfo = COUNTRY.filter(function (country) { return country.countryCode == countryCode });
    const countryName = countryInfo && countryInfo.length > 0 ? countryInfo[0].name : "";
    return countryName;
  }

  onChangeDropDown(event, data) {
    window.location.href = "/ranks" + "?filter=" + data.text;
  }

  render() {
    const errorCode = this.props.data.errorCode;
    const ranks = this.props.data.list;
    const showFiltering = true;

    const itemsPerPage = 15;
    const totalPages = parseInt((ranks.length / itemsPerPage) + ((ranks.length % itemsPerPage) > 0 ? 1 : 0));
    const items = ranks.slice(
      (this.state.page - 1) * itemsPerPage,
      (this.state.page - 1) * itemsPerPage + itemsPerPage
    );

    const itemStart = (this.state.page - 1) * itemsPerPage;

    const statisticValue = {
      marginLeft: "10px",
      textAlign: "center",
      fontSize: ".85vw",
      fontWeight: "900",
      color: "#C4C5C6"
    };

    const RANK_LIST = items.map((rank, index) => {
      const avatarDiv = rank.avatarUrl ? 
        <AvatarWithFlag url={rank.avatarUrl} countryCode={rank.countryCode} /> 
        : <DefaultAvatar />;
      return (
        <Table.Body key={index} className="base-color">
          <Table.Row>
            <Table.Cell>{itemStart + index + 1}</Table.Cell>
            <Table.Cell style={{ width: "20%" }}>
              <>
                {avatarDiv}
                <a
                  style={{ display: "inline-block" }}
                  href="#"
                  onClick={() => this.onClickUser(rank.userId)}
                >
                  <span className="author-name">
                    {rank.userName}
                  </span>
                </a>
              </>
            </Table.Cell>
            <Table.Cell textAlign="center">
              <div>
                  <img width={25} height={25} src={totalSubmissionImage} />
                  <span style={statisticValue}>{rank.totalSubmission}</span>
              </div>
            </Table.Cell>
            <Table.Cell textAlign="center">
              <div>
                  <img width={25} height={25} src={totalAcceptedImage} />
                  <span style={statisticValue}>{rank.totalAccepted}</span>
              </div>
            </Table.Cell>
            {/* <Table.Cell textAlign="center">
              <Statistic size="mini">
                <div style={statisticValue}>{rank.contestRating}</div>
              </Statistic>
            </Table.Cell> */}
          </Table.Row>
        </Table.Body>
      );
    });

    const rankListDiv = ranks.length > 0 ?
      <Table basic="very" unstackable className="table-scrollable">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className="base-color">#</Table.HeaderCell>
            <Table.HeaderCell className="base-color">User</Table.HeaderCell>
            <Table.HeaderCell className="base-color" style={{ textAlign: "center" }}>Submissions</Table.HeaderCell>
            <Table.HeaderCell className="base-color" style={{ textAlign: "center" }}>Accepted</Table.HeaderCell>
            {/* <Table.HeaderCell className="base-color" style={{ textAlign: "center" }}>Contest Rating</Table.HeaderCell> */}
          </Table.Row>
        </Table.Header>
        {RANK_LIST}
      </Table> : null;

    const paginationDiv = ranks.length <= itemsPerPage ? null :
      <div className="pagination-div">
        <Pagination
          className="pagination-custom"
          activePage={this.state.page}
          totalPages={totalPages}
          size='mini'
          siblingRange={1}
          prevItem={false}
          nextItem={false}
          onPageChange={this.setPageNum}
        />
      </div>


    const filterDiv = (
      <Dropdown
        text={this.state.filterValue}
        search selection
        button
        className="tiny filter-dropdown-color"
      >
        <Dropdown.Menu>
          {/* <Dropdown.Item className="filter-dropdown-color-item" text="Rating" onClick={this.onChangeDropDown} /> */}
          <Dropdown.Item className="filter-dropdown-color-item" text="Max Solved" onClick={this.onChangeDropDown} />
        </Dropdown.Menu>
      </Dropdown>
    );

    const headerDiv = !showFiltering ? null :
      <div className="foc-header">
        <div className="title">
          <h3 className="title-name" style={{paddingBottom: "5px"}}>Ranks</h3>
        </div>
        <div className="filter-item">{filterDiv}</div>
      </div>;

    const noDataDiv =
      <div style={{ textAlign: "center" }}>
        <Icon loading name='spinner' />
      </div>;

    return (
      errorCode != null ? (
        <div className="main-container">
          <NotFound statusCode={errorCode} />
        </div>
      ) :
        <div className="main-container">
          <Segment raised>
            {headerDiv}
            {ranks.length > 0 ? rankListDiv : noDataDiv}
          </Segment>
          {paginationDiv}
        </div>
    );
  }
}

// Connect with "rankList" reducer
const mapStateToProps = (state) => {
  return { data: assign({}, state.rankList) };
};

// Connect with "RanklistActions"
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(assign(RanklistActions), dispatch);
};

// Connect reducer and action together
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RanklistContainer);
