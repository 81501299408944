import { RANK_LIST } from "../actions/actions";

const initialState = {
  list: [],
  errorCode: null
};

const rankListReducer = (state = initialState, action) => {
  switch (action.type) {
    case RANK_LIST.SET_RANK_LIST:
      return { ...state, list: action.payload };
    case RANK_LIST.SET_ERROR_CODE:
      return { ...state, errorCode: action.payload };
    default:
      return state;
  }
};

export default rankListReducer;
