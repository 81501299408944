import callAPI from "../utils/callAPI";
import processCallAPIException from "../utils/processCallAPIException";
import { RANK_LIST, HEADER } from "./actions";

export const fetchRankList = (params) => {
  return (dispatch) => {
    callAPI("/ranks/search", "GET", params)
      .then((response) => {
        dispatch({
          type: RANK_LIST.SET_RANK_LIST,
          payload: response.body.data,
        });
      }).catch((error) => {
        dispatch({
          type: RANK_LIST.SET_ERROR_CODE,
          payload: error.status,
        });
      }).catch(processCallAPIException(dispatch));
  };
};

export const updatePath = () => {
  return (dispatch) => {
    dispatch({
      type: HEADER.SET_PATH,
      payload: "ranks",
    });
  };
};
