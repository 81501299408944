import callAPI from "../utils/callAPI";
import { CONTEST, PROBLEM, HEADER } from "./actions";
import processCallApiException from "../utils/processCallAPIException";
import { isTeamUser } from "../utils/cookieUtil";
import { DagreGraph } from "@ant-design/charts";

export const updatePath = () => {
  return (dispatch) => {
    dispatch({
      type: HEADER.SET_PATH,
      payload: "contests",
    });
  };
};

export const startRecruitContest = (contestId) => {
  return (dispatch) => {
    callAPI("/contests/" + contestId + "/start", "POST")
      .then((response) => {
        window.location.reload();
      }).catch((error) => {
      });
  };
};

export const fetchContests = (params) => {
  return (dispatch) => {
    callAPI("/contests/search", "GET", params)
      .then((response) => {
        dispatch({
          type: CONTEST.SET_CONTEST_LIST,
          payload: response.body.data,
        });
      })
      .catch(processCallApiException(dispatch));
  };
};

export const registerUserToContest = (contestId, userId) => {
  return (dispatch) => {
    callAPI("/contests/" + contestId + "/users/" + userId, "POST")
      .then((response) => {
        window.location.href = "/contests/" + contestId;
      })
      .catch(processCallApiException(dispatch));
  };
};

export const fetchRatingUsers = (count) => {
  return (dispatch) => {
    callAPI("/users/ratings", "GET", {count})
      .then((response) => {
        dispatch({
          type: CONTEST.SET_CONTEST_RATING_USERS,
          payload: response.body.data,
        });
      })
      .catch(processCallApiException(dispatch));
  };
};


export const fetchContestsForLoggedInUser = (params) => {
  const endpoint = "/contests/search/" + (isTeamUser() ? "with-team-user" : "with-user");
  return (dispatch) => {
    callAPI(endpoint, "GET", params)
      .then((response) => {
        dispatch({
          type: CONTEST.SET_CONTEST_LIST,
          payload: response.body.data,
        });
      }).catch((error) => {
        dispatch({
          type: CONTEST.SET_ERROR_CODE,
          payload: error.status,
        });
      }).catch(processCallApiException(dispatch));
  };
};

export const fetchAllContestsForAdmin = () => {
  return (dispatch) => {
    callAPI("/admin/contests", "GET")
      .then((response) => {
        dispatch({
          type: CONTEST.SET_CONTEST_LIST,
          payload: response.body.data,
        });
      })
      .catch(processCallApiException(dispatch));
  };
};

export const createContestRating = (contestId) => {
  return (dispatch) => {
    callAPI("/admin/contests/" + contestId + "/rating", "POST")
      .then((response) => {
        window.location.reload();
      })
      .catch(processCallApiException(dispatch));
  };
};


export const fetchContestForAdmin = (contestId) => {
  return (dispatch) => {
    callAPI("/admin/contests/" + contestId, "GET")
      .then((response) => {
        dispatch({
          type: CONTEST.SET_CONTEST_DETAIL,
          payload: response.body.data,
        });
      })
      .catch(processCallApiException(dispatch));
  };
};

export const fetchContest = (contestId) => {
  return (dispatch) => {
    callAPI("/contests/" + contestId, "GET")
      .then((response) => {
        dispatch({
          type: CONTEST.SET_CONTEST_DETAIL,
          payload: response.body.data,
        });
      }).catch((error) => {
        dispatch({
          type: CONTEST.SET_ERROR_CODE,
          payload: error.status,
        });
      }).catch(processCallApiException(dispatch));
  };
};

export const fetchContestProblems = (contestId) => {
  return (dispatch) => {
    callAPI("/contests/" + contestId + "/problems", "GET")
      .then((response) => {
        dispatch({
          type: CONTEST.SET_CONTEST_PROBLEMS,
          payload: response.body.data,
        });
      })
      .catch(processCallApiException(dispatch));
  };
};

export const fetchContestProblemsForLoggedInUser = (contestId) => {
  return (dispatch) => {
    const endpoint = "/contests/" + contestId + (isTeamUser() ? "/team/problems" : "/user/problems");
    callAPI(endpoint, "GET")
      .then((response) => {
        dispatch({
          type: CONTEST.SET_CONTEST_PROBLEMS,
          payload: response.body.data,
        });
      })
      .catch(processCallApiException(dispatch));
  };
};

export const fetchContestSubmissions = (contestId) => {
  return (dispatch) => {
    const endpoint = "/contests/" + contestId + (isTeamUser() ? "/team/submissions" : "/submissions");
    callAPI(endpoint, "GET")
      .then((response) => {
        dispatch({
          type: CONTEST.SET_CONTEST_SUBMISSIONS,
          payload: response.body.data,
        });
      })
      .catch(processCallApiException(dispatch));
  };
};

export const fetchContestProblemSetters = (contestId) => {
  return (dispatch) => {
    const endpoint = "/contests/" + contestId + "/problem-setters";
    callAPI(endpoint, "GET")
      .then((response) => {
        dispatch({
          type: CONTEST.SET_CONTEST_PROBLEM_SETTERS,
          payload: response.body.data,
        });
      })
      .catch(processCallApiException(dispatch));
  };
};

export const fetchContestStandings = (contestId) => {
  return (dispatch) => {
    const endpoint = "/contests/" + contestId + (isTeamUser() ? "/team/standings" : "/standings");
    callAPI(endpoint, "GET")
      .then((response) => {
        dispatch({
          type: CONTEST.SET_CONTEST_STANDINGS,
          payload: response.body.data,
        });
      })
      .catch(processCallApiException(dispatch));
  };
};

export const fetchContestUserRegistrationInfo = (contestId) => {
  return (dispatch) => {
    const endpoint = "/contests/" + contestId + (isTeamUser() ? "/team/is-registered" : "/is-registered");
    callAPI(endpoint, "GET")
      .then((response) => {
        dispatch({
          type: CONTEST.SET_CONTEST_USER_REGISTRATION_INFO,
          payload: response.body.data,
        });
      })
      .catch(processCallApiException(dispatch));
  };
};

export const removeProblem = (contestId, problemId) => {
  return (dispatch) => {
    callAPI("/admin/contests/" + contestId + "/problems/" + problemId, "DELETE")
      .then((response) => {
        window.location.reload();
      })
      .catch(processCallApiException(dispatch));
  };
};

export const addProblem = (contestId, problemId, serialName) => {
  const requestBody = { problemId: problemId, serialName: serialName };
  return (dispatch) => {
    callAPI("/admin/contests/" + contestId + "/problems", "POST", requestBody)
      .then((response) => {
        window.location.reload();
      })
      .catch(processCallApiException(dispatch));
  };
};

export const fetchAllProblemsForAdmin = (contestId) => {
  const params = { contestId: contestId };
  return (dispatch) => {
    callAPI("/admin/problems", "GET", params)
      .then((response) => {
        dispatch({
          type: PROBLEM.SET_PROBLEM_LIST_FOR_ADMIN,
          payload: response.body.data,
        });
      })
      .catch(processCallApiException(dispatch));
  };
};

export const fetchContestExtraInfo = (contestId) => {
  return (dispatch) => {
    callAPI("/contests/" + contestId + "/extra-info", "GET")
      .then((response) => {
        dispatch({
          type: CONTEST.SET_CONTEST_EXTRA_INFO,
          payload: response.body.data,
        });
      })
      .catch(processCallApiException(dispatch));
  };
};

export const updateContestDetail = (contestId, data) => {
  return (dispatch) => {
    const contestRequest = {
      name: data.name,
      description: data.description,
      status: data.status,
      ratedStatus: data.ratedStatus,
      manualRegistration: data.manualRegistration ? '1' : '0',
      prizeMoney: data.prizeMoney,
      startTime: data.startTime,
      endTime: data.endTime,
    };

    callAPI("/admin/contests/" + contestId, "POST", contestRequest)
      .then((response) => {
        window.location.reload();
      })
      .catch(processCallApiException(dispatch));
  };
};

export const deleteContestInfo = (info) => {
  return (dispatch) => {
    callAPI("/admin/contests/extra-info/" + info.id, "DELETE")
      .then((response) => {
        window.location.reload();
      })
      .catch(processCallApiException(dispatch));
  };
};

export const uploadContestImage = (data) => {
  return (dispatch) => {
    const sourceFile = data.sourceFile;
    const contestId = data.contestId;

    const imageObject = {
      file: sourceFile
    };

    const endpoint = "/admin/contests/" + contestId + "/imageFile";
    callAPI(endpoint, 'POST', imageObject)
      .then((response) => {
        const data = response.body.data;
        // redirect to submission detail page
        window.location.href = "/admin-contests/" + contestId;
      })
      .catch((error) => {
        dispatch({
          type: PROBLEM.SET_PROBLEM_SUBMISSION_ERROR,
          payload: "a",
        });
      }).catch(processCallApiException(dispatch));
  };
};

export const createContestDetail = (data) => {
  return (dispatch) => {
    const contestRequest = {
      name: data.name,
      description: data.description,
      status: data.status,
      manualRegistration: data.manualRegistration ? '1' : '0',
      ratedStatus: data.ratedStatus,
      prizeMoney: data.prizeMoney,
      startTime: data.startTime,
      endTime: data.endTime,
    };

    callAPI("/admin/contests", "POST", contestRequest)
      .then((response) => {
        const contestId = response.body.data.id;
        window.location.href = "/admin-contests/" + contestId;
      })
      .catch(processCallApiException(dispatch));
  };
};
