import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import assign from "lodash/assign";
import * as SubmissionActions from "../actions/SubmissionActions";

import SubmissionList from "../Components/Submission/SubmissionList";
import NotFound from "../Components/Error/NotFound";

class SubmissionListContainer extends Component {
  constructor(props) {
    super(props);
    this.onClickProblem = this.onClickProblem.bind(this);
    this.onClickDetail = this.onClickDetail.bind(this);
    this.onClickUser = this.onClickUser.bind(this);
    this.state = {
      page: 1,
      problemId: null
    };
  }

  componentDidMount() {
    document.title = "Submissions";
    this.props.updatePath();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    let searchParams = {
      userId: null,
      problemId: null,
      status: null
    };

    searchParams.userId = urlParams.get("userId") || null;
    searchParams.problemId = urlParams.get("problemId") || null;
    searchParams.status = urlParams.get("status") || null;

    if (searchParams.status === "All" || searchParams.status === "Filtering") {
      searchParams.status = null;
    }
    if (urlParams.get("of") && urlParams.get("of") === "My Submissions") {
      searchParams.userId = this.props.data.loggedInUserId;
    }
    if (searchParams.problemId !== null) {
      this.setState({ problemId: searchParams.problemId });
    }
    this.props.fetchSubmissions(searchParams);
  }

  setPageNum = (event, { activePage }) => {
    this.setState({ page: activePage });
  };

  onClickProblem(problemId) {
    window.location.href = "/problems/" + problemId;
  };

  onClickDetail(submissionId) {
    window.location.href = "/submissions/" + submissionId;
  }

  onClickUser(userId) {
    window.location.href = "/users/" + userId;
  }

  render() {
    const submissions = this.props.data.list;
    const statusCode = this.props.data.statusCode;

    return (
      statusCode != null ? (
        <div className="main-container">
          <NotFound statusCode={statusCode} />
        </div>
      ) :
        <div className="main-container">
          <SubmissionList
            submissions={submissions}
            showFiltering={true}
            showLoading={true}
          />
        </div>
    );
  }
}

// Connect with "submission" reducer
const mapStateToProps = (state) => {
  return { data: assign({}, state.submission, state.user) };
};

// Connect with "SubmissionActions"
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(assign(SubmissionActions), dispatch);
};

// Connect reducer and action together
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmissionListContainer);
