import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import assign from "lodash/assign";
import { Segment, Grid, Button, Dimmer, Loader, Statistic, Image, List, Icon, Table, Pagination } from "semantic-ui-react";

import LanguagePie from "../Components/Chart/LanguagePie";

import * as UserActions from "../actions/userActions";
import UserBasicInfo from "../Components/User/UserBasicInfo";

class UserContainer extends Component {
    constructor(props) {
        super(props);
        this.onClickProfileEdit = this.onClickProfileEdit.bind(this);
        this.setProblemPageNum = this.setProblemPageNum.bind(this);
        this.setContestPageNum = this.setContestPageNum.bind(this);

        this.state = {
            showEditButton: false,
            problems: [],
            contests: [],
            problemPage: 1,
            contestPage: 1
        };
    }

    componentDidMount() {
        const pathname = window.location.pathname;
        const userId = pathname.substring(pathname.lastIndexOf('/') + 1)
        if (this.props.data.loggedInUserId === userId) {
            this.setState({ showEditButton: true });
        }
        this.props.fetchUser(userId);
        this.props.fetchProblemsForAuthor(userId);
        this.props.fetchContestsForAuthor(userId);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
          this.setState({ 
            problems: this.props.data.problems ? this.props.data.problems : [],
            contests: this.props.data.contests ? this.props.data.contests : []
          });
        }
    }

    componentWillReceiveProps(nextProps) {
        const detail = nextProps.data.detail;
        if (detail) {
          document.title = 'Profile | ' + detail.userName;
        }
    }

    onClickProfileEdit() {
        window.location.href = "/profile";
    }

    onClickProblemView(problemId) {
        window.location.href = "/problems/" + problemId;
    }

    onClickContestView(contestId) {
        window.location.href = "/contests/" + contestId;
    }

    onClickProblemUpdate(problemId) {
        window.location.href = "/admin-problems/" + problemId;
    }

    onClickContestUpdate(contestId) {
        window.location.href = "/admin-contests/" + contestId;
    }

    setProblemPageNum = (event, { activePage }) => {
        this.setState({ problemPage: activePage });
    };

    setContestPageNum = (event, { activePage }) => {
        this.setState({ contestPage: activePage });
    };

    findContestStatusName(statusId) {
        if (statusId === 10) {
            return 'Public';
        } else if (statusId === 20) {
            return 'Hidden';
        } else if (statusId === 30) {
            return 'Test';
        } else if (statusId === 40) {
            return 'Recruit';
        } else {
            return 'Unknown';
        }
    }

    findProblemStatusName(statusId) {
        if (statusId === 10) {
            return 'Public';
        } else if (statusId === 15) {
            return 'Contest only';
        } if (statusId === 50) {
            return 'Admin review pending';
        } else {
            return 'Hidden';
        }
    }

    filterName(name) {
        if (name.length > 15) {
            return name.slice(0,15) + ".....";
        } else {
            return name;
        }
    }

    render() {
        const userDetail = this.props.data.detail;
        const languageSubmissions = userDetail.languageSubmissions ? userDetail.languageSubmissions : [];

        const itemsPerPage = 10;

        const totalProblemPageCount = parseInt(
            this.state.problems.length / itemsPerPage +
            (this.state.problems.length % itemsPerPage > 0 ? 1 : 0)
        );

        const problemsPerPage = this.state.problems.slice(
            (this.state.problemPage - 1) * itemsPerPage,
            (this.state.problemPage - 1) * itemsPerPage + itemsPerPage
        );

        const totalContestPageCount = parseInt(
            this.state.contests.length / itemsPerPage +
            (this.state.contests.length % itemsPerPage > 0 ? 1 : 0)
        );

        const contestsPerPage = this.state.contests.slice(
            (this.state.contestPage - 1) * itemsPerPage,
            (this.state.contestPage - 1) * itemsPerPage + itemsPerPage
        );
        
        
        const statisticStyle = {
            fontWeight: "600 !important",
            color: "#C4C5C6",
            textTransform: "none"
        };

        const statisticStyleCustom = {
            fontSize: '1em',
            fontWeight: '700',
            color: "#C4C5C6"
        };

        const statisticValue = {
            fontSize: "1.2rem !important",
            textAlign: "center",
        };

        const problemList = problemsPerPage.map((problem) => { 
            return (
                <Table.Body key={problem.id}>
                  <Table.Row>
                    <Table.Cell><a href='#' onClick={() => this.onClickProblemView(problem.id)}>{this.filterName(problem.name)}</a></Table.Cell>
                    <Table.Cell className="base-color">{this.findProblemStatusName(problem.status)}</Table.Cell>
                    <Table.Cell textAlign="right">
                        <Button size="mini" className="button-style" onClick={() => this.onClickProblemUpdate(problem.id)}>Update</Button>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              );
        });

        const contestList = contestsPerPage.map((contest) => { 
            return (
                <Table.Body key={contest.id}>
                  <Table.Row>
                    <Table.Cell><a href='#' onClick={() => this.onClickContestView(contest.id)}>{this.filterName(contest.name)}</a></Table.Cell>
                    <Table.Cell className="base-color">{this.findContestStatusName(contest.status)}</Table.Cell>
                    <Table.Cell textAlign="right">
                        <Button size="mini" className="button-style" onClick={() => this.onClickContestUpdate(contest.id)}>Update</Button>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
            );
        });

        const problemsPaginationDiv =
        totalProblemPageCount > 1 ? (
            <div className="pagination-div">
                <Pagination
                    className="pagination-custom"
                    activePage={this.state.problemPage}
                    totalPages={totalProblemPageCount}
                    size='mini'
                    siblingRange={1}
                    prevItem={false}
                    nextItem={false}
                    onPageChange={this.setProblemPageNum}
                />
            </div>
        ) : null;

        const contestsPaginationDiv =
        totalContestPageCount > 1 ? (
            <div className="pagination-div">
                <Pagination
                    className="pagination-custom"
                    activePage={this.state.contestPage}
                    totalPages={totalContestPageCount}
                    size='mini'
                    siblingRange={1}
                    prevItem={false}
                    nextItem={false}
                    onPageChange={this.setContestPageNum}
                />
            </div>
        ) : null;

        const problemListDiv = 
            this.state.problems.length > 0 ? 
            <Table basic="very" unstackable className="table-scrollable">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell className="base-color">Name</Table.HeaderCell>
                        <Table.HeaderCell className="base-color">Status</Table.HeaderCell>
                        <Table.HeaderCell className="base-color" textAlign="right">Action</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                {problemList}
            </Table> 
            : <div>No problem created yet</div>;

        const contestListDiv = 
            this.state.contests.length > 0 ? 
            <Table basic="very" unstackable className="table-scrollable">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell className="base-color">Name</Table.HeaderCell>
                        <Table.HeaderCell className="base-color">Status</Table.HeaderCell>
                        <Table.HeaderCell className="base-color" textAlign="right">Action</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                {contestList}
            </Table> 
            : <div>No contest created yet</div>;;

        const paymentMethodDiv =
            userDetail.countryCode === 'bd' ?
                userDetail.bkashPhoneNumber ?
                    <Segment raised>
                        <p>Prize money payment method : bKash</p>
                        <p>Phone Number : {userDetail.bkashPhoneNumber}</p>
                    </Segment> :
                    <Segment raised>
                        <p style={{ padding: "5px", borderRadius: "5px" }}>
                            No payment method was set to receive prize money.
                            Edit profile info and set a payment method.
                        </p>
                    </Segment> : null;
        
        const languageSubmissionDiv = languageSubmissions.length > 0 ?
            <Grid stackable columns={1} >
                <Grid.Column width={16}>
                    <Segment raised>
                        <LanguagePie data={languageSubmissions} />
                    </Segment>
                </Grid.Column>
            </Grid> : null;

        return !userDetail.userName ?
            <Dimmer active inverted>
                <Loader inverted>Loading</Loader>
            </Dimmer> : (
                <div className="main-container">
                    <Grid stackable columns={2}>
                        <Grid.Column width={4}>
                            <UserBasicInfo userDetail={userDetail} />
                            {this.state.showEditButton ?
                                <>
                                    {paymentMethodDiv}
                                    <Button
                                        className="button-style"
                                        style={{ width: "100%"}}
                                        onClick={() => this.onClickProfileEdit()}
                                    >
                                        Edit profile
                                    </Button>
                                </> : null
                            }
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid stackable columns={2}>
                                <Grid.Column width={10}>
                                    <Segment raised>
                                        <Segment.Group horizontal style={{ margin: 0, border: "none", boxShadow: "none", borderLeft: "none", backgroundColor: "#2E3136" }}>
                                            <Segment style={{ borderLeft: "none" }}>
                                                <Statistic size="small">
                                                    <Statistic.Label style={statisticStyle}>
                                                        Rank
                                                    </Statistic.Label>
                                                    <div style={statisticValue}>{userDetail.rank}</div>
                                                </Statistic>
                                            </Segment>
                                            <Segment style={{ borderLeft: "none" }}>
                                                <Statistic size="small">
                                                    <Statistic.Label style={statisticStyle}>
                                                        Accepted
                                                    </Statistic.Label>
                                                    <div style={statisticValue}>{userDetail.totalAccepted}</div>
                                                </Statistic>
                                            </Segment>
                                            <Segment style={{ borderLeft: "none" }}>
                                                <Statistic size="small">
                                                    <Statistic.Label style={statisticStyle}>
                                                        Submission
                                                    </Statistic.Label>
                                                    <div style={statisticValue}>{userDetail.totalSubmission}</div>
                                                </Statistic>
                                            </Segment>
                                        </Segment.Group>
                                    </Segment>

                                </Grid.Column>
                                <Grid.Column width={6}>
                                    <Segment raised>
                                        <Segment.Group horizontal style={{ margin: 0, border: "none", boxShadow: "none", borderLeft: "none", backgroundColor: "#2E3136" }}>
                                            <Segment style={{ borderLeft: "none" }}>
                                                <Statistic size="small">
                                                    <Statistic.Label style={statisticStyle}>
                                                        Contests
                                                    </Statistic.Label>
                                                    <div style={statisticValue}>{userDetail.totalContest}</div>
                                                </Statistic>
                                            </Segment>
                                            <Segment style={{ borderLeft: "none" }}>
                                                <Statistic size="small">
                                                    <Statistic.Label style={statisticStyle}>
                                                        Rating
                                                    </Statistic.Label>
                                                    <div style={statisticValue}>{userDetail.contestRating}</div>
                                                </Statistic>
                                            </Segment>
                                        </Segment.Group>
                                    </Segment>
                                </Grid.Column>
                            </Grid>

                            {languageSubmissionDiv}

                            {this.state.showEditButton ?
                                <Grid stackable columns={2}>
                                    <Grid.Column width={8}>
                                        <Segment raised>
                                            <div style={{marginBottom: "2em"}}>
                                                <span style={statisticStyleCustom}>Your Problems</span>
                                                <a style={{float: 'right'}}href="/admin-problems/new">Create a problem?</a>
                                            </div>
                                            {problemListDiv}
                                        </Segment>
                                        {problemsPaginationDiv}
                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        <Segment raised>
                                            <div style={{marginBottom: "2em"}}>
                                                <span style={statisticStyleCustom}>Your Contests</span>
                                                {/* <a style={{float: 'right'}}href="/admin-contests/new">Create a contest?</a> */}
                                            </div>
                                            {contestListDiv}
                                        </Segment>
                                        {contestsPaginationDiv}
                                    </Grid.Column>
                                </Grid>
                            : null}

                        </Grid.Column>
                    </Grid>
                </div >
            );
    }
}

// Connect with "user" reducer
const mapStateToProps = (state) => {
    return { data: assign({}, state.user) };
};

// Connect with "UserActions"
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(assign(UserActions), dispatch);
};

// Connect reducer and action together
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserContainer);
