import PropTypes from "prop-types";
import React from "react";
import { DesktopHeader, MobileHeader } from "./index";
import { Container } from "semantic-ui-react";
import { connect } from "react-redux";
import assign from "lodash/assign";

function ResponsiveHeader({ children, data }) {
  return (
    <div className="nav-header">
      <Container>
        <DesktopHeader pathStack={data.pathStack}>{children}</DesktopHeader>
        <MobileHeader pathStack={data.pathStack}>{children}</MobileHeader>
      </Container>
    </div>
  );
}

ResponsiveHeader.propTypes = {
  children: PropTypes.node,
};

DesktopHeader.propTypes = {
  children: PropTypes.node,
};

MobileHeader.propTypes = {
  children: PropTypes.node,
};

const mapStateToProps = (state) => {
  return { data: assign({}, state.header) };
};

export default connect(
  mapStateToProps,
  null
)(ResponsiveHeader);

