import React from "react";
import { Segment, Grid} from "semantic-ui-react";

import CodeSnippet from "../../Components/CodeSnippet";

export default class SubmittedCode extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { code, language, details } = this.props;

        return code && details && details.length > 0 ? 
        <Grid.Column width={7}>
            <Segment raised>
                <h3 style={{textAlign: "center"}}> Source Code</h3>
                <CodeSnippet code={code} language={language}/>
            </Segment>
        </Grid.Column> : null;
    }
}