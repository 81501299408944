import React, { Component } from 'react';
import ReactCountryFlag from "react-country-flag";
import { Popup } from 'semantic-ui-react';
import { COUNTRY } from '../constants';

class AvatarWithFlag extends Component {

  constructor(props) {
    super(props);
  }


  getCountryName(countryCode){
    const countryInfo = COUNTRY.filter(function (country) { return country.countryCode == countryCode });
    const countryName = countryInfo && countryInfo.length > 0 ? countryInfo[0].name : "";
    return countryName;
  }

  render() {
    const avatarStyle = {
      verticalAlign: 'middle', 
      backgroundColor: "#d8e0e8",
      width: "40px",
      height: "40px",
      borderRadius: '50%',
      position: 'relative',
      marginRight: '5px'
    };
  
    const flagStyle = {
      position: 'absolute',
      bottom: '-5px',
      right: '-3px',
      width: '1.5em',
      height: '1.5em',
      borderRadius: '0.4em'
    };

    return (
      <div style={{ display: 'inline-block', position: 'relative' }}>
          <img src={this.props.url} alt="Avatar" style={avatarStyle} />
  
          <Popup
            trigger={
              <span>
                <ReactCountryFlag
                  countryCode={this.props.countryCode.toUpperCase()}
                  svg
                  style={flagStyle}
              />
              </span>
            }
            content={this.getCountryName(this.props.countryCode)}
            size='mini'
          />
      </div>
    );
  }
}

export default AvatarWithFlag;
