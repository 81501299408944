import React, { useMemo } from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

// Refactored DocumentViewer to a memoized functional component
const DocumentViewer = React.memo((props) => {
    // Create a memoized array of documents using useMemo
    const docs = useMemo(
        () => [
            {
                uri: props.uri,
                fileType: props.fileType,
                fileName: makeFileName(props.name),
            },
        ],
        [props.uri, props.fileType, props.name]
    );

    // Function to generate a fileName from the provided name
    function makeFileName(name) {
        const fileNameWithoutSpaces = name.replace(/ /g, "_");
        const fileNameWithExtension = fileNameWithoutSpaces.endsWith(".pdf")
            ? fileNameWithoutSpaces
            : `${fileNameWithoutSpaces}.pdf`;

        return fileNameWithExtension;
    }

    return (
        <DocViewer
            documents={docs}
            initialActiveDocument={docs[1]}
            pluginRenderers={DocViewerRenderers}
            config={{
                header: {
                    disableHeader: true,
                    disableFileName: true,
                    retainURLParams: true,
                },
                pdfZoom: {
                    defaultZoom: 1, // 1 as default,
                    zoomJump: 0.2, // 0.1 as default,
                },
                pdfVerticalScrollByDefault: true,
            }}
            theme={{
                primary: "#f4f1f1",
                secondary: "#ffffff",
                tertiary: "#2E3136",
                textPrimary: "#ffffff",
                textSecondary: "#f4f1f1",
                disableThemeScrollbar: false,
            }}
            style={{ height: 800, backgroundColor: "#2E3136"}}
        />
    );
});

export default DocumentViewer;
