export const COMMON = {
  SET_IS_NOTFOUND: "COMMON__SET_IS_NOTFOUND",
  SET_IS_INTERNAL_ERROR: "COMMON__SET_IS_INTERNAL_ERROR",

  SET_IS_FORBIDDEN: "COMMON__SET_IS_FORBIDDEN",
  SET_IS_BAD_REQUEST: "COMMON__SET_IS_BAD_REQUEST",
  SET_IS_UNAUTHORIZED_REQUEST: "COMMON__SET_IS_UNAUTHORIZED_REQUEST",

  SET_LOADING: "COMMON__SET_LOADING",

  SET_POPUP: "COMMON__SET_POPUP",
  CLOSE_POPUP: "COMMON__CLOSE_POPUP",
};

export const AUTH = {
  SET_SIGN_IN_STATUS: "AUTH__SET_SIGN_IN_STATUS",
  SET_SIGN_UP_ERROR: "AUTH__SET_SIGN_UP_ERROR",
  RESET_ERROR_MESSAGE: "AUTH__RESET_ERROR_MESSAGE",
};

export const CONTEST = {
  SET_CONTEST_LIST: "CONTEST__SET_CONTEST_LIST",
  SET_CONTEST_EXTRA_INFO: "CONTEST__SET_CONTEST_EXTRA_INFO",
  SET_CONTEST_RATING_USERS: "CONTEST__SET_CONTEST_RATING_USERS",
  SET_CONTEST_DETAIL: "CONTEST__SET_CONTEST_DETAIL",
  SET_CONTEST_PROBLEMS: "CONTEST__SET_CONTEST_PROBLEMS",
  SET_CONTEST_SUBMISSIONS: "CONTEST__SET_CONTEST_SUBMISSIONS",
  SET_CONTEST_PROBLEM_SETTERS: "CONTEST__SET_CONTEST_PROBLEM_SETTERS",
  SET_CONTEST_STANDINGS: "CONTEST__SET_CONTEST_STANDINGS",
  SET_ERROR_CODE: "CONTEST__SET_ERROR_CODE",
  SET_CONTEST_USER_REGISTRATION_INFO: "CONTEST__SET_CONTEST_USER_REGISTRATION_INFO"
};

export const PROBLEM = {
  SET_PROBLEM_LIST: "PROBLEM__SET_PROBLEM_LIST",
  SET_PROBLEM_LIST_FOR_ADMIN: "PROBLEM__SET_PROBLEM_LIST_FOR_ADMIN",
  SET_PROBLEM_DETAIL: "PROBLEM__SET_PROBLEM_DETAIL",
  SET_STATUS_CODE: "PROBLEM__SET_ERROR_CODE",
  SET_PROBLEM_SAMPLE_TEST_RUN_SUBMISSION_ID: "PROBLEM_SAMPLE_TEST_RUN_SUBMISSION_ID",
  SET_PROBLEM_SAMPLE_TEST_RUN_DATA: "PROBLEM_SAMPLE_TEST_RUN_DATA",
  SET_PROBLEM_SUBMISSION_ERROR: "PROBLEM__SET_PROBLEM_SUBMISSION_ERROR"
};

export const CATEGORY = {
  SET_CATEGORY_LIST: "CATEGORY__SET_CATEGORY_LIST",
};


export const USER = {
  SET_USER_DETAIL: "USER__SET_USER_DETAIL",
  SET_PROFILE_INFO: "USER__SET_PROFILE_INFO",
  SET_AUTHOR_PROBLEMS: "USER__SET_AUTHOR_PROBLEMS",
  SET_AUTHOR_CONTESTS: "USER__SET_AUTHOR_CONTESTS",
  SET_PROBLEM_SETTERS: "USER__SET_PROBLEM_SETTERS",
};

export const SUBMISSION = {
  SET_SUBMISSION_LIST: "SUBMISSION__SET_SUBMISSION_LIST",
  SET_SUBMISSION_DETAIL: "SUBMISSION__SET_SUBMISSION_DETAIL",
  SET_SUBMISSION_DETAILS: "SUBMISSION__SET_SUBMISSION_DETAILS",
  SET_STATUS_CODE: "SUBMISSION__SET_ERROR_CODE"
};

export const RANK_LIST = {
  SET_RANK_LIST: "RANKLIST__SET_RANK_LIST",
  SET_ERROR_CODE: "RANKLIST__SET_ERROR_CODE"
};

export const STATISTICS = {
  SET_STATISTICS: "STATISTICS_SET_STATISTICS"
}

export const HEADER = {
  SET_PATH: "HEADER_SET_PATH"
}
