import { STATISTICS } from "../actions/actions";

const inititalState = {
    countUser: null,
    countSubmission: null,
    countProblem: null,
    countContest: null
};

const statisticsReducer = (state = inititalState, action) => {
    switch (action.type) {
        case STATISTICS.SET_STATISTICS:
            return {
                ...state,
                countUser: action.payload.countUser,
                countSubmission: action.payload.countSubmission,
                countProblem: action.payload.countProblem,
                countContest: action.payload.countContest
            };
        default:
            return state;
    }
};

export default statisticsReducer;
