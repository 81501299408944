import CounterReducer from "./counter";
import SignInReducer from "./signIn";
import ContestReducer from "./contest";
import ProblemReducer from "./problem";
import SubmissionReducer from "./submission";
import UserReducer from "./user";
import RankListReducer from "./ranklist";
import CategoryReducer from "./category";
import statisticsReducer from "./statistics";
import headerReducer from "./header";
import { combineReducers } from "redux";

const commonReducer = {
  counter: CounterReducer,
  signIn: SignInReducer,
  user: UserReducer,
  contest: ContestReducer,
  problem: ProblemReducer,
  category: CategoryReducer,
  submission: SubmissionReducer,
  rankList: RankListReducer,
  statistics: statisticsReducer,
  header: headerReducer
};

const generateReducer = () => {
  const reducer = commonReducer;
  return combineReducers(reducer);
};

export default generateReducer();
