import React from "react";
import { Segment, Grid, Table, Icon, Popup } from "semantic-ui-react";
import moment from "moment";
import { isTeamUser } from "../../utils/cookieUtil";
import acceptedStatusImage from '../../assets/icons/accepted2.png';
import wrongStatusImage from '../../assets/icons/wrong5.png';

export default class SubmissionDetail extends React.Component {
    constructor(props) {
        super(props)
    }

    onClickProblem(problemId) {
        if (!isTeamUser()) {
            window.location.href = "/problems/" + problemId;
        }
    };

    onClickUser(userId) {
        if (!isTeamUser()) {
            window.location.href = "/users/" + userId;
        }
    };

    findStatusImage(status) {
        if (status === 'Accepted') {
          return acceptedStatusImage;
        } else {
          return wrongStatusImage;
        }
    }

    isPending(status) {
        return status === 'In Queue' || status === 'Processing';
    }

    render() {
        const { submission } = this.props;
        const problemId = submission ? submission.problemId : '';

        const frozenIconDiv =
            <Popup
                trigger={<Icon name={"wait"} color={"yellow"} />}
                content='In Judge Queue'
                size='mini'
            />;

        const spinnerIcon = <Icon loading name='spinner' color="yellow" />;

        const problemNameDiv = (submission) => submission ?
            <div>
                <h4 className="text-style">
                    <a href="#" onClick={() => this.onClickProblem(problemId)}>
                        <h4 className="text-style">{submission.problemName}</h4>
                    </a>
                </h4>
            </div> : spinnerIcon;

        const userNameDiv = (submission) => submission ?
            <div>
                <h4 className="text-style">
                    <a href="#" onClick={() => this.onClickUser(submission.userId)}>
                        <h4 className="text-style">{submission.userName}</h4>
                    </a>
                </h4>
            </div> : spinnerIcon;

        const statusDiv = (submission) => submission ? this.isPending(submission.status) ? spinnerIcon :
            <div>
                <img className="submission-status-icon" src={this.findStatusImage(submission.status)} /> 
            </div> : null;

        const languageNameDiv = (submission) => submission ?
            <div>
                {submission.language}
            </div> : spinnerIcon;

        const submittedAtDiv = (submission) => submission ?
            <div>
                {moment(submission.submittedAt).fromNow()}
            </div> : spinnerIcon;

        const memoryDiv = (submission) => submission && submission.status !== 'In Queue' ?
            <div>
                {submission.memory} KB
            </div> : submission && submission.frozen ? frozenIconDiv : spinnerIcon;

        const timeDiv = (submission) => submission && submission.status !== 'In Queue' ?
            <div>
                {submission.time} sec
            </div> : submission && submission.frozen ? frozenIconDiv : spinnerIcon;


        return (
            <Grid.Column>
                <Segment raised>
                    <div className="foc-header">
                        <div className="title">
                            <h2 className="text-style">Submission Detail</h2>
                        </div>
                    </div>
                    <div className="frozen-note">
                        {submission && submission.frozen ? <>
                            <strong>This submission is in Judge Queue until the contest is finished.</strong>
                        </> : null}
                    </div>
                    <Table basic='very' unstackable className="table-scrollable base-color">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>User</Table.HeaderCell>
                                <Table.HeaderCell>Problem</Table.HeaderCell>
                                <Table.HeaderCell>Submitted At</Table.HeaderCell>
                                <Table.HeaderCell>Language</Table.HeaderCell>
                                <Table.HeaderCell>Memory</Table.HeaderCell>
                                <Table.HeaderCell>CPU Time</Table.HeaderCell>
                                <Table.HeaderCell textAlign={"right"}>Status</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>{userNameDiv(submission)}</Table.Cell>
                                <Table.Cell>{problemNameDiv(submission)}</Table.Cell>
                                <Table.Cell>{submittedAtDiv(submission)}</Table.Cell>
                                <Table.Cell>{languageNameDiv(submission)}</Table.Cell>
                                <Table.Cell>{memoryDiv(submission)}</Table.Cell>
                                <Table.Cell>{timeDiv(submission)}</Table.Cell>
                                <Table.Cell textAlign={"right"}>
                                    {statusDiv(submission)}
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Segment>
            </Grid.Column>
        )
    }
}