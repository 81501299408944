import React, { Component } from "react";
import AceEditor from 'react-ace';
import "ace-builds/src-noconflict/mode-java";
import 'ace-builds/src-noconflict/mode-c_cpp';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-golang';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/mode-ruby';
import 'ace-builds/src-noconflict/mode-typescript';

import 'ace-builds/src-noconflict/theme-xcode';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/theme-solarized_dark';
import 'ace-builds/src-noconflict/theme-solarized_light';

import { LANGUAGE, IDE_THEME, IDE_FONT_SIZE } from "../../constants";

import {
  Segment,
  Grid,
  Button,
  Icon,
  Dropdown
} from "semantic-ui-react";

import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import SubmissionDetails from "../Submission/SubmissionDetails";

class Ide extends Component {
  constructor(props) {
    super(props);

    this.state = {
      languageId: 50,
      fontSize: 15,
      mode: 'c_cpp',
      theme: 'monokai',
      code: '',
      sampleRunStatus: '',
      displaySampleRunData: false,
      disableSampleRunButton: false
    };
  }

  componentDidMount() {
    this.removeKeyIfExpired();
    if (this.getCodeFromLocalStorage(this.state.languageId)) {
      this.setState({
        code: this.getCodeFromLocalStorage(this.state.languageId)
      });
    } else {
      const selectedLanguage = LANGUAGE.filter(l => l.id === this.state.languageId);
      this.setState({ code: selectedLanguage[0].ide_placeholder });
    }
  }

  fetchData() {
    this.props.fetchSampleTestRunData();
    const sampleTestRunData = this.props.sampleTestRunData;
    if (sampleTestRunData) {
      this.setState({ displaySampleRunData: true });
      const submission = sampleTestRunData.submissionDto;
      if (submission && submission.status !== 'In Queue' && submission.status !== 'Processing') {
        clearInterval(this.interval);
        this.setState({ sampleRunStatus: submission.status, disableSampleRunButton: false });
      }
    } else {
      this.setState({ displaySampleRunData: false, disableSampleRunButton: true });
    }
  }

  onChangeLanguage = (e, data) => {
    const selectedLanguage = LANGUAGE.filter(l => l.id === data.value);
    this.setState({
      languageId: data.value,
      mode: selectedLanguage[0].ide_mode
    });
    const codeFromLocalStorage = this.getCodeFromLocalStorage(data.value);
    if (codeFromLocalStorage) {
      this.setState({ code: codeFromLocalStorage })
    } else {
      this.setState({ code: selectedLanguage[0].ide_placeholder })
    }
  };

  onChangeTheme = (e, { value }) => {
    this.setState({ theme: value });
  };

  onChangeFontSize = (e, { value }) => {
    this.setState({ fontSize: value });
  };

  onChange = (data) => {
    // save in localstorage
    this.setState({ code: data });
    if (this.props.userId) {
      localStorage.setItem(this.getSavedCodeKey(this.state.languageId), data);
      localStorage.setItem(this.getSavedCodeExpiredTimeKey(this.state.languageId), new Date().getTime());
    }
  };

  getCodeFromLocalStorage(languageId) {
    return localStorage.getItem(this.getSavedCodeKey(languageId));
  }

  getSaveKey(languageId) {
    return this.props.userId + '_' + this.props.problemId + '_' + languageId;
  }

  getSavedCodeKey(languageId) {
    return this.getSaveKey(languageId) + '_code';
  }

  getSavedCodeExpiredTimeKey(languageId) {
    return this.getSavedCodeKey(languageId) + '_setupTime';
  }

  onCloseIDE() {
    this.props.onCloseIDE();
  }

  removeKeyIfExpired() {
    const hours = 2;
    const now = new Date().getTime();
    const setupTimeCode = localStorage.getItem(this.getSavedCodeKey(this.state.languageId) + 'setupTime');
    if (setupTimeCode != null) {
      if (now - setupTimeCode > hours * 60 * 60 * 1000) {
        localStorage.removeItem(this.getSavedCodeKey(this.state.languageId));
      }
    }
  }

  onConfirmSubmit() {
    this.props.onSubmitFromIDE(this.state.code, this.state.languageId);
  }

  onClickRunSamples() {
    if (this.props.contestEnded) {
      window.location.reload();
    }
    this.setState({ sampleRunStatus: 'running', displaySampleRunData: false, disableSampleRunButton: true });
    this.props.onRunSamples(this.state.code, this.state.languageId);
    this.interval = setInterval(() => {
      this.fetchData();
    }, 2500);
  }

  onClickSubmit() {
    if (this.props.contestEnded) {
      window.location.reload();
    }
    Modal.confirm({
      title: "Final confirmation. Are you ready to submit?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: () => this.onConfirmSubmit(),
    });
  }

  render() {
    const sampleTestRunData = this.props.sampleTestRunData;
    const languageOptions = LANGUAGE;
    const themeOptions = IDE_THEME;
    const fontSizes = IDE_FONT_SIZE;
    const selectedLanguage = languageOptions.filter(l => l.id === this.state.languageId);
    const selectedTheme = themeOptions.filter(t => t.value === this.state.theme);
    const selectedFontSize = fontSizes.filter(t => t.value === this.state.fontSize);
    const sampleRunDetails = sampleTestRunData ? sampleTestRunData.detailDtoList : [];

    const errorDiv = this.props.isLoggedIn ? null :
      <span style={{ marginLeft: 5, color: "#C4C5C6" }}>
        <Icon name="hand point right" />
        Please login to submit.
      </span>;
    
    const isTestSubmissionDiv =
      this.props.shouldTreatSubmissionAsTest ? (
        <>
        <Segment raised style={{ background: "#E39A9A" }}>
          <p>Problem Status : {this.props.problemStatusName}</p>
          <p>You are the Author of this problem. Submission will be considered as test</p>
        </Segment>
        </>
    ) : null;


    const testRunResultDiv = !this.state.sampleRunStatus ? null : this.state.sampleRunStatus === 'running' ?
      <div style={{ marginTop: "20px", paddingBottom: "40px", textAlign: "center" }}>
        <span style={{ marginRight: "65px" }}>Judging </span>
        <Icon loading name='spinner' />
      </div> : this.state.sampleRunStatus === 'Accepted' ?
        <div style={{ marginTop: "20px", marginBottom: "10px", textAlign: "center" }}>
          <Icon style={{ marginRight: "10px" }} name="check circle" color="green" />
          <span style={{ fontSize: "15px" }}>Passed all sample test cases</span>
        </div> :
        <div style={{ marginTop: "20px", marginBottom: "10px", textAlign: "center" }}>
          <Icon style={{ marginRight: "10px" }} name="close" color="red" />
          <span style={{ fontSize: "15px" }}>Didn't pass all sample test cases</span>
        </div>;

    const testRunDiv = this.state.displaySampleRunData ?
      <SubmissionDetails
        details={sampleRunDetails}
        submission={null}
      /> : null;

    return (
      <Grid.Column width={8}>
        <div>
          <Dropdown
            button
            className='icon mini filter-dropdown-color'
            floating
            labeled
            icon='code'
            options={languageOptions}
            text={this.state.languageId ? selectedLanguage[0].text : 'Language'}
            onChange={this.onChangeLanguage}
          />
          <Dropdown
            button
            className='icon mini filter-dropdown-color'
            floating
            labeled
            icon='codepen'
            options={themeOptions}
            search
            text={this.state.theme ? selectedTheme[0].text : 'Theme'}
            onChange={this.onChangeTheme}
            style={{ marginLeft: "10px" }}
          />
          <Dropdown
            button
            className='icon mini filter-dropdown-color'
            floating
            labeled
            icon='font'
            options={fontSizes}
            search
            text={this.state.fontSize ? selectedFontSize[0].text : 'FontSize'}
            onChange={this.onChangeFontSize}
            style={{ marginLeft: "10px" }}
          />
          <Button icon
            className="button-style"
            size="mini"
            onClick={() =>
              this.onCloseIDE()
            }
            style={{ float: "right" }}
          >
            <Icon link name='close' />
            Close Editor
          </Button>
        </div>
        <Segment raised
          style={{
            minHeight: "850px",
            padding: "0px"
          }}
        >
          {isTestSubmissionDiv}
          <AceEditor
            value={this.state.code}
            mode={this.state.mode}
            theme={this.state.theme}
            fontSize={this.state.fontSize}
            name="uniqeIdName"
            height="800px"
            width="100%"
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            onChange={this.onChange}
            setOptions={{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              enableSnippets: true,
              showLineNumbers: true,
              showPrintMargin: false,
              tabSize: 2,
            }} />
          <Button className="button-style" style={{ marginTop: "10px", marginLeft: "5px" }}
            disabled={!this.props.isLoggedIn || this.state.disableSampleRunButton}
            onClick={() => this.onClickRunSamples()}
          >
            Run Samples
          </Button>
          <Button className="button-style" style={{ marginTop: "10px", marginLeft: "10px" }}
            onClick={() => this.onClickSubmit()}
            disabled={!this.props.isLoggedIn || this.state.disableSampleRunButton}
          >
            Submit
          </Button>
          {errorDiv}
          {testRunResultDiv}
          {testRunDiv}
        </Segment>
      </Grid.Column>
    );
  }
}

export default Ide;
