import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { routerMiddleware } from "react-router-redux";
import reducer from "./reducers";
// Development
import createLogger from "redux-logger";

export default function configureStore(initialState = {}, history) {
  const middlewares = [thunk, routerMiddleware(history)];

  if (/^local|development$/.test(process.env.NODE_ENV)) {
    // 開発環境ではコンソールを表示
    const loggerMiddleware = createLogger();
    middlewares.push(loggerMiddleware);
  }

  const middleware = applyMiddleware(...middlewares);

  const store = middleware(createStore)(reducer, initialState);

  if (module.hot) {
    module.hot.accept("./reducers", () => {
      const nextRootReducer = require("./reducers").default;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
