import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Container } from "semantic-ui-react";
import HomeContainer from "./containers/HomeContainer";
import ActivationContainer from "./containers/ActivationContainer";
import PasswordResetContainer from "./containers/PasswordResetContainer";
import ContestListContainer from "./containers/ContestListContainer";
import RanklistContainer from "./containers/RanklistContainer";
import ProblemListContainer from "./containers/ProblemListContainer";
import ProblemDetailContainer from "./containers/ProblemDetailContainer";
import ContestDetailContainer from "./containers/ContestDetailContainer";
import SubmissionDetailContainer from "./containers/SubmissionDetailContainer";
import UserContainer from "./containers/UserContainer";
import ProfileEditContainer from "./containers/ProfileEditContainer";
import PrivacyPolicy from "./containers/PrivacyPolicy";
import TermsAndConditions from "./containers/TermsAndConditions";
import ResponsiveHeader from "./Components/Header/ResponsiveHeader";
import SubmissionListContainer from "./containers/SubmissionListContainer";
import Footer from "./Components/Footer";
import AdminContestContainer from "./containers/admin/AdminContestListContainer";
import AdminContestDetail from "./containers/admin/AdminContestDetail";
import AdminProblemCreateContainer from "./containers/admin/AdminProblemCreateContainer";
import NotFound from "./Components/Error/NotFound";
import AdminProblemDetailContainer from "./containers/admin/AdminProblemDetailContainer";
import ProtectedRoute from "./Components/ProtectedRoute";
import ServiceOffline from "./Components/ServiceOffline";

function App() {
  return (
    <BrowserRouter>
      <ResponsiveHeader />
      <Container id="containerId">
        <Switch>
          <ProtectedRoute path="/" exact component={HomeContainer} />
          <ProtectedRoute path="/passwordReset" exact component={PasswordResetContainer} />
          <ProtectedRoute path="/activation" exact component={ActivationContainer} />
          <ProtectedRoute path="/problems" exact component={ProblemListContainer} />
          <ProtectedRoute path="/problems" component={ProblemDetailContainer} />
          <ProtectedRoute
            path="/submissions"
            exact
            component={SubmissionListContainer}
          />
          <ProtectedRoute path="/submissions" component={SubmissionDetailContainer} />
          <ProtectedRoute path="/contests" exact component={ContestListContainer} />
          {/* <Route path="/contests/details" exact component={ContestDetailContainer} /> */}
          <ProtectedRoute path="/contests" component={ContestDetailContainer} />
          <ProtectedRoute path="/ranks" exact component={RanklistContainer} />
          <ProtectedRoute path="/users" component={UserContainer} />
          <ProtectedRoute path="/profile" exact component={ProfileEditContainer} />
          <ProtectedRoute path="/privacy" exact component={PrivacyPolicy} />
          <ProtectedRoute path="/terms" exact component={TermsAndConditions} />
          <ProtectedRoute
            path="/admin-contests"
            exact
            component={AdminContestContainer}
          />
          <ProtectedRoute
            path="/admin-problems/new"
            exact
            component={AdminProblemCreateContainer}
          />
          <ProtectedRoute path="/admin-problems" component={AdminProblemDetailContainer} />
          <ProtectedRoute path="/admin-contests" component={AdminContestDetail} />
          <Route path="/service-offline" component={ServiceOffline} />
          <ProtectedRoute component={NotFound} />
        </Switch>
      </Container>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
