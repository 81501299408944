import React, { Component } from "react";
import { Header, Label } from "semantic-ui-react";
import googleIcon from '../../assets/icons/google.png';

class OauthLogin extends Component {
  constructor(props) {
    super(props);
    this.close = this.close.bind(this);
  }

  close = () => this.props.close(false);

  render() {

    const redirectURL = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/api/oauth2/google' : 'https://api.codepanja.com/api/oauth2/google';
    const googleUrl = 'https://accounts.google.com/o/oauth2/v2/auth?client_id=283817945181-kregkriuivejqe21gab17o0b26g9hejm.apps.googleusercontent.com&scope=openid%20email%20profile&redirect_uri=' + redirectURL + '&response_type=code';

    return (
      <div className="social-login">
        <div style={{textAlign: 'center'}}>
            <div>
                <Label as='a' href={googleUrl} size='medium' style={{fontSize: '20px'}}>
                    <img src={googleIcon} style={{fontSize: '13px'}}/>
                    <b style={{marginLeft: '10px'}}>Google</b>
                </Label>
            </div>
            <div style={{marginTop: '20px', padding: '20px'}}>
                <p className="base-color">By signing in, you accept CodePanja's Terms of Service and Privacy Policy.</p>
            </div>
        </div>
      </div>
    );
  }
}

export default OauthLogin;
