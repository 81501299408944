import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import assign from "lodash/assign";
import { Segment, Grid, Menu, Dropdown, Button, Input } from "semantic-ui-react";
import { Link } from "react-router-dom";

import * as ContestActions from "../../actions/ContestActions";
import ContestProblems from "../../Components/Contest/ContestProblems";
import Countdown from "../../Components/Countdown";
import CountdownFinished from "../../Components/CountdownFinished";
import ContestDetailEdit from "../../Components/Contest/ContestDetailEdit";
import { isLoggedIn } from "../../utils/cookieUtil";
import NotFound from "../../Components/Error/NotFound";
import ContestImages from "../../Components/Contest/ContestImages";

class AdminContestDetail extends Component {
  constructor(props) {
    super(props);
    // This binding is needed.
    // As we will access state value of this class inside the function
    this.handleDropdown = this.handleDropdown.bind(this);
    this.removeProblemsFromContest = this.removeProblemsFromContest.bind(this);
    this.addProblem = this.addProblem.bind(this);
    this.onChangeSerialName = this.onChangeSerialName.bind(this);

    // Need to bind this function, as it is passed to child component
    // Otherwise props can not be accessed inside this function
    this.updateContestDetail = this.updateContestDetail.bind(this);
    this.uploadContestImage = this.uploadContestImage.bind(this);
    this.deleteContestInfo = this.deleteContestInfo.bind(this);

    this.state = {
      activeItem: 'detail',
      contestId: '',
      selectedProblemId: '',
      selectedProblemSerialName: ''
    };
  }

  componentDidMount() {
    const pathname =this.props.history.location.pathname;
    const contestId = pathname.substring(pathname.lastIndexOf('/') + 1);

    this.setState({ contestId: contestId })
    if (contestId !== 'new') {
      this.props.fetchContestForAdmin(contestId);
      this.props.fetchAllProblemsForAdmin(contestId);
      this.props.fetchContestExtraInfo(contestId);
    }
  }

  onClickMenu(itemName) {
    this.setState({ activeItem: itemName })
    if (itemName === "problems") {
      if (isLoggedIn()) {
        this.props.fetchContestProblemsForLoggedInUser(this.state.contestId);
      } else {
        this.props.fetchContestProblems(this.state.contestId);
      }
    }
  }

  onClickProblem(problemId, contestId, ended) {
    if (ended) {
      window.location.href = "/problems/" + problemId;
    } else {
      window.location.href = "/problems/" + problemId + "?contestId=" + contestId;
    }
  };

  removeProblemsFromContest(problemId) {
    this.props.removeProblem(this.state.contestId, problemId);
  }

  handleDropdown(event, data) {
    this.setState({ selectedProblemId: data.value });
  }

  addProblem() {
    if (this.state.selectedProblemId !== "") {
      this.props.addProblem(this.state.contestId, this.state.selectedProblemId, this.state.selectedProblemSerialName);
    }
  }

  updateContestDetail(newData) {
    if (this.state.contestId === 'new') {
      this.props.createContestDetail(newData);
    } else {
      this.props.updateContestDetail(this.state.contestId, newData);
    }
  }

  uploadContestImage(data) {
    this.props.uploadContestImage(data);
  }

  deleteContestInfo(image) {
    this.props.deleteContestInfo(image);
  }

  onChangeSerialName(event, data) {
    this.setState({ selectedProblemSerialName: data.value });
  }

  render() {
    const { activeItem } = this.state;
    const { detail, problems, allProblems, user, extraInfo } = this.props.data;
    const name = detail != null ? detail.name : '';
    const endTime = detail != null ? detail.endTime : '';
    const ended = detail != null ? detail.ended : false;
    let problemsOptions = [];

    allProblems.forEach(element => {
      const obj = {
        key: element.id, value: element.id, text: element.name
      };
      problemsOptions.push(obj);
    });

    const contestMenu = (
      <Menu secondary className="contest-menu">
        <Menu.Item
          className="contest-page-menu"
          as={Link}
          to="#"
          name='Detail'
          active={activeItem === 'detail'}
          onClick={() => this.onClickMenu('detail')}
        />
        <Menu.Item
          className="contest-page-menu"
          as={Link}
          to="#"
          name='Problems'
          active={activeItem === 'problems'}
          onClick={() => this.onClickMenu('problems')}
          style={{ display: this.state.contestId === 'new' ? 'none' : 'block' }}
        />
        <Menu.Item
          className="contest-page-menu"
          as={Link}
          to="#"
          name='Images'
          active={activeItem === 'images'}
          onClick={() => this.onClickMenu('images')}
        />
      </Menu>
    );

    let displayItem = null;

    if (activeItem === "problems") {
      displayItem =
        <ContestProblems
          problems={problems}
          ended={ended}
          contestId={this.state.contestId}
          registered={false}
          isAdmin={true}
          onClickProblem={this.onClickProblem}
          removeProblemsFromContest={this.removeProblemsFromContest}
        />;
    } else if (activeItem === "detail") {
      displayItem =
        <ContestDetailEdit
          detail={detail}
          updateContestDetail={this.updateContestDetail}
        />;
    } else if (activeItem === "images") {
      displayItem =
        <ContestImages
          contest={detail}
          images={extraInfo}
          uploadContestImage={this.uploadContestImage}
          deleteContestInfo={this.deleteContestInfo}
        />;
    }

    const countdownDiv = ended ? <CountdownFinished /> : detail ? <Countdown contest={detail} /> : null;

    return isLoggedIn() && user.permission === '10' ? (
      <div className="main-container">
        <Grid stackable columns={1}>
          <Grid.Column width={16}>
            <Segment raised>
              <div className="foc-header">
                <div className="title" style={{ textAlign: "center" }}>
                  <span className="text-style" style={{ fontSize: "22px" }}>{name}</span>
                </div>
                <div className="contest-timer" style={{ float: "right" }}>
                  {countdownDiv}
                </div>
              </div>
              {contestMenu}
              {activeItem === "detail" || activeItem === "images" ? null :
                <div>
                  <Dropdown
                    placeholder='Search problem'
                    clearable
                    search
                    selection
                    options={problemsOptions}
                    onChange={this.handleDropdown}
                  />
                  <Input
                    placeholder="Serial Name"
                    value={this.state.selectedProblemSerialName}
                    onChange={this.onChangeSerialName}
                    style={{ marginLeft: "10px" }}
                  />
                  <Button
                    className="button-style"
                    onClick={this.addProblem}
                    style={{ marginLeft: "10px" }}
                  >
                    Add
                  </Button>
                </div>
              }
              {displayItem}
            </Segment>
          </Grid.Column>
        </Grid>

        <div
          style={{
            textAlign: "center",
            marginTop: "30px",
            marginBottom: "30px",
          }}
        >
        </div>
      </div >
    ) : 
    <div className="main-container">
      <NotFound statusCode={401} />
    </div>
  }
}

// Connect with "contest" reducer
const mapStateToProps = (state) => {
  return { data: assign({}, state.contest, { user: state.user }) };
};

// Connect with "ContestActions"
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(assign(ContestActions), dispatch);
};

// Connect reducer and action together
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminContestDetail);
