import React, { Component } from "react";
import { Header, Statistic } from "semantic-ui-react";

class ServiceOffline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeRemaining: this.calculateTimeRemaining()
    };
  }

  componentDidMount() {
    // Update the time remaining every second
    this.interval = setInterval(() => {
      this.setState({ timeRemaining: this.calculateTimeRemaining() });
    }, 1000);
  }

  componentWillUnmount() {
    // Clear the interval on component unmount
    clearInterval(this.interval);
  }

  calculateTimeRemaining() {
    const now = new Date();
    const currentUTCHours = now.getUTCHours();

    // Determine the next available hour
    let nextAvailableHour;
    if (currentUTCHours >= 16 || currentUTCHours < 0) {
      // If it's past 4 PM UTC, next available time is 12 AM (00:00) the next day
      nextAvailableHour = 0 + 24;  // Adding 24 hours for the next day
    } else if (currentUTCHours < 0 || currentUTCHours >= 12) {
      // If it's between 12 AM and 4 PM UTC, set the next available time to 12 AM UTC
      nextAvailableHour = 16;
    }

    // Calculate time remaining
    const nextAvailableDate = new Date(now);
    nextAvailableDate.setUTCHours(nextAvailableHour, 0, 0, 0);
    const timeRemainingMs = nextAvailableDate - now;

    // Convert to hours, minutes, seconds
    const hours = Math.floor(timeRemainingMs / (1000 * 60 * 60));
    const minutes = Math.floor((timeRemainingMs % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeRemainingMs % (1000 * 60)) / 1000);

    return { hours, minutes, seconds };
  }

  addLeadingZeros(value) {
    value = String(value);
    while (value.length < 2) {
      value = '0' + value;
    }
    return value;
  }

  render() {
    const { hours, minutes, seconds } = this.state.timeRemaining;

    const statisticValue = {
      textAlign: "center"
    };

    const timerDiv =
      <div className="Countdown">

        <span className="Countdown-col">
          <span className="Countdown-col-element">
            <strong>{this.addLeadingZeros(hours)}</strong>
            <span>Hours</span>
          </span>
        </span>

        <span className="Countdown-col">
          <span className="Countdown-col-element">
            <strong>{this.addLeadingZeros(minutes)}</strong>
            <span>Min</span>
          </span>
        </span>

        <span className="Countdown-col">
          <span className="Countdown-col-element">
            <strong>{this.addLeadingZeros(seconds)}</strong>
            <span>Sec</span>
          </span>
        </span>
      </div>;

    return (
      <div className="main-container">
        <div className="login-buttons">
          <div style={{ marginLeft: "30%", marginRight: "30%" }}>
            <Header as="h3" textAlign="center" className="base-color">
              The service is temporarily offline.
            </Header>
            <Header as="h3" textAlign="center" className="base-color">
              It will be available again in:
            </Header>
            <div style={{ textAlign: "center" }}>
              <Statistic size="mini">
                <div style={statisticValue}>{timerDiv}</div>
              </Statistic>
            </div>
            <Header as="h4" textAlign="center" className="base-color">
              The operational hour is 12 AM ~ 4 PM UTC.
            </Header>
          </div>
        </div>
      </div>
    );
  }
}

export default ServiceOffline;
