import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import assign from "lodash/assign";
import { Segment, Grid, Button, Label, Statistic, Icon } from "semantic-ui-react";
import Countdown from "../../Components/Countdown";
import CountdownFinished from "../../Components/CountdownFinished";

import { Pagination, Dropdown } from "semantic-ui-react";

import * as ContestActions from "../../actions/ContestActions";
import { isLoggedIn } from "../../utils/cookieUtil";
import NotFound from "../../Components/Error/NotFound";

class AdminContestContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
    };
  }

  componentDidMount() {
    this.props.fetchAllContestsForAdmin();
  }

  setPageNum = (event, { activePage }) => {
    this.setState({ page: activePage });
  };

  onClickContest(contestId) {
    window.location.href = "/admin-contests/" + contestId;
  };

  onClickCreateRating(contestId) {
    this.props.createContestRating(contestId);
  }

  render() {
    let allContests = this.props.data.list;
    const user = this.props.data.user;
    const itemsPerPage = 15;
    const totalPages = parseInt((allContests.length / itemsPerPage) + ((allContests.length % itemsPerPage) > 0 ? 1 : 0));
    const items = allContests.slice(
      (this.state.page - 1) * itemsPerPage,
      (this.state.page - 1) * itemsPerPage + itemsPerPage
    );

    const filterDiv = (
      <Dropdown
        text="Filtering"
        search selection
        button
        className="tiny filter-dropdown-color"
      >
        <Dropdown.Menu>
          <Dropdown.Item text="All" />
          <Dropdown.Item text="Running" />
          <Dropdown.Item text="Upcoming" />
          <Dropdown.Item text="Finished" />
        </Dropdown.Menu>
      </Dropdown>
    );

    const statisticStyle = {
      fontWeight: "600 !important",
      color: "#C4C5C6",
      fontSize: ".8em"
    };

    const statisticValue = {
      fontSize: "1.2rem !important",
      textAlign: "center",
    };

    const contestTimeStatusDiv = (contest) => contest.ended ? <CountdownFinished /> :
      <Countdown contest={contest} />;

    const contestList = items.map((contest, index) => {
      const okIcon = <Icon name="check circle" color="green" />;
      const closeIcon = <Icon name="close" color="brown" style={{ fontSize: ".7em" }} />;

      const contestItemClassName = index === items.length - 1 ? '' : 'map-item-border-bottom';

      return (
        <Segment raised key={contest.name} className={contestItemClassName}>
          <div>
            <Grid stackable columns={2} className="no-padding-left-right-bottom">
              <Grid.Column width={6} className="no-padding-left-right-bottom">
                <div>
                  <div>
                    <a href="#" onClick={() => this.onClickContest(contest.id)}>
                      <h4 className="title-name">{contest.name}</h4>
                    </a>
                  </div>
                  <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                    <span>{contest.description}</span>
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column width={2}>
                <Statistic size="mini">
                  <Statistic.Label style={statisticStyle}>
                    Rated
                  </Statistic.Label>
                  <div style={statisticValue}>{contest.rated ? okIcon : closeIcon}</div>
                </Statistic>
              </Grid.Column>

              <Grid.Column width={2}>
                <Statistic size="mini">
                  <Statistic.Label style={statisticStyle} textTransformation="none">
                    Prize Money
                  </Statistic.Label>
                  <div style={statisticValue}>{contest.prizeMoney ? contest.prizeMoney : closeIcon}</div>
                </Statistic>
              </Grid.Column>
              <Grid.Column width={3}>
                {contest.rated ?
                  <Button onClick={() => this.onClickCreateRating(contest.id)}>
                    Update Rating
                  </Button> : null 
                }
              </Grid.Column>
              <Grid.Column width={2} className="no-padding-left-right-bottom" style={{ textAlign: "right" }}>
                {contestTimeStatusDiv(contest)}
              </Grid.Column>
            </Grid>
          </div>
        </Segment>
      );
    });

    return isLoggedIn() && user.permission === '10' ? (
      <div className="main-container">
        <Segment raised>
          <div className="foc-header">
            <div className="title">
              <h2 className="text-style">Contest List</h2>
            </div>
            <div className="filter-item">{filterDiv}</div>
          </div>
          {contestList}
        </Segment>
        <div
          style={{
            textAlign: "center",
            marginTop: "30px",
            marginBottom: "30px",
          }}
        >
          <Pagination
            style={{ border: "none" }}
            activePage={this.state.page}
            totalPages={totalPages}
            size='mini'
            siblingRange={1}
            onPageChange={this.setPageNum}
          />
        </div>
      </div>
    )  : 
    <div className="main-container">
      <NotFound statusCode={401} />
    </div>;
  }
}

// Connect with "contest" reducer
const mapStateToProps = (state) => {
  return { data: assign({}, state.contest, { user: state.user }) };
};

// Connect with "ContestActions"
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(assign(ContestActions), dispatch);
};

// Connect reducer and action together
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminContestContainer);
