import { HEADER } from "../actions/actions";

const inititalState = {
    pathStack: []
};

const headerReducer = (state = inititalState, action) => {
    switch (action.type) {
        case HEADER.SET_PATH:
            return {
                ...state,
                pathStack: [...state.pathStack, action.payload]
            }
        default:
            return state;
    }
};

export default headerReducer;
