import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function ProtectedRoute({ component: Component, ...rest }) {
  const currentTime = new Date();
  const currentUTCHours = currentTime.getUTCHours();

  // service is available from 6 AM to 10 PM (Bangladesh Time)
  // const isServiceAvailableHours = currentUTCHours >= 0 && currentUTCHours < 16;

  const isServiceAvailableHours = true;

  return (
    <Route
      {...rest}
      render={props =>
        !isServiceAvailableHours ? (
          <Redirect to="/service-offline" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
}

export default ProtectedRoute;
