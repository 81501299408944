import React from "react";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';

export default class PrismCode extends React.Component {
    constructor(props) {
    super(props)
    }

    render() {
        // Example Theme : https://k8shiro.github.io/ReactCompareCodeHighlighter/
        // Lanugae List : https://www.redmine.org/projects/redmine/wiki/RedmineCodeHighlightingLanguages
        const { code, language } = this.props;
        return (
            <SyntaxHighlighter className="codeSnippet" language={language} style={atomOneLight}>
                {code}
            </SyntaxHighlighter>
        )
    }
}