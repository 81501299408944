import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import assign from "lodash/assign";
import {
  Segment,
  Grid,
  Button,
  Label,
  Icon,
  Popup,
  Menu,
  Input,
} from "semantic-ui-react";
import Countdown from "../Components/Countdown";
import { isLoggedIn } from "../utils/cookieUtil";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { Pagination, Dropdown } from "semantic-ui-react";

import * as ContestActions from "../actions/ContestActions";
import NotFound from "../Components/Error/NotFound";
import ContestListSideBar from "../Components/Contest/ContestListSideBar";
import ratingIconImage from '../assets/icons/rating_new1.png';
import prizeMoneyImage from '../assets/icons/taka_new3.png';
import participatedImage from '../assets/icons/participated_1.png';

class ContestListContainer extends Component {
  constructor(props) {
    super(props);
    this.onClickContest = this.onClickContest.bind(this);
    this.onChangeDropDown = this.onChangeDropDown.bind(this);
    this.onClickRegister = this.onClickRegister.bind(this);
    this.confirmRegister = this.confirmRegister.bind(this);

    this.state = {
      page: 1,
      filterValue: "All",
      contestName: ''
    };
  }

  componentDidMount() {
    document.title = "Contests";
    this.props.updatePath();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const searchParams = {
      status: null,
    };

    if (urlParams.has("filter")) {
      this.setState({ filterValue: urlParams.get("filter") });
      searchParams.status = urlParams.get("filter");
    }

    if (isLoggedIn()) {
      this.props.fetchContestsForLoggedInUser(searchParams);
    } else {
      this.props.fetchContests(searchParams);
    }
    const totalUsersForRating = 10;
    this.props.fetchRatingUsers(totalUsersForRating);
  }

  handleChange = (e) => this.setState({ contestName: e.target.value });

  keyPressed = (e) => {
    if (e.key === "Enter" && this.state.contestName) {
      window.location.href = "/contests?name=" + this.state.contestName;
    }
  };

  handleSearchIconClick = (e) => {
    if (this.state.contestName) {
      window.location.href = "/contests?name=" + this.state.contestName;
    }
  };

  onChangeDropDown(event, data) {
    window.location.href = "/contests" + "?filter=" + data.text;
  }

  setPageNum = (event, { activePage }) => {
    this.setState({ page: activePage });
  };

  onClickContest(contestId) {
    window.location.href = "/contests/" + contestId;
  }

  onClickStandings(contestId) {
    window.location.href = "/contests/" + contestId + "?filter=standings";
  }

  confirmRegister(contestId, userId) {
    this.props.registerUserToContest(contestId, userId);
  }

  onClickRegister(contest) {
    const loggedInUserId = this.props.data.user.loggedInUserId;
    Modal.confirm({
      title: "Register to participate in this contest.",
      icon: <ExclamationCircleOutlined />,
      content: "Name : " + contest.name,
      okText: "Yes",
      cancelText: "No",
      onOk: () => this.confirmRegister(contest.id, loggedInUserId),
    });
  }

  findContestTimeText = (utcStartTime) => {
    const localStartTime = new Date(utcStartTime).toLocaleString();
    const localDateObj = new Date(localStartTime);
    // Get the month name
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const monthName = monthNames[localDateObj.getMonth()];

    // Get the last two digits of the year
    const year = localDateObj.getFullYear() % 100;

    // Create the formatted date string
    const formattedDate = `${monthName} '${year}`;
    return formattedDate;
  }

  findContestTimeDetail = (utcStartTime) => {
    const localStartTime = new Date(utcStartTime).toLocaleString();
    // Extract date components
    const options = { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric', 
      hour: 'numeric', 
      minute: 'numeric', 
      hour12: true, 
      timeZoneName: 'short'
    };

    // Format the date
    const formattedDate = localStartTime.toLocaleString('en-US', options);
    return formattedDate;
  }

  render() {
    const errorCode = this.props.data.errorCode;
    let allContests = this.props.data.list;
    const ratingUsers = this.props.data.ratingUsers ? this.props.data.ratingUsers : [];
    const itemsPerPage = 10;
    const totalPages = parseInt(
      allContests.length / itemsPerPage +
      (allContests.length % itemsPerPage > 0 ? 1 : 0)
    );
    const items = allContests.slice(
      (this.state.page - 1) * itemsPerPage,
      (this.state.page - 1) * itemsPerPage + itemsPerPage
    );

    const statusFilterDiv = (
      <Dropdown
        text={this.state.filterValue}
        search selection
        button
        className="tiny filter-dropdown-color-margin-right"
      >
        <Dropdown.Menu>
          <Dropdown.Item className="filter-dropdown-color-item" text="All" onClick={this.onChangeDropDown} />
          <Dropdown.Item className="filter-dropdown-color-item" text="Running" onClick={this.onChangeDropDown} />
          <Dropdown.Item className="filter-dropdown-color-item" text="Upcoming" onClick={this.onChangeDropDown} />
          <Dropdown.Item className="filter-dropdown-color-item" text="Finished" onClick={this.onChangeDropDown} />
        </Dropdown.Menu>
      </Dropdown>
    );

    const contestSearchDiv = (
      <span>
        <Input
          icon={{ name: 'search', color: 'red', circular: true, link: true, onClick: this.handleSearchIconClick }}
          placeholder="Search contest..."
          onChange={this.handleChange}
          onKeyPress={this.keyPressed}
        />
      </span>
    );

    const filterDiv = (
      <div>
        <Grid stackable columns={1}>
          <Grid.Column width={10}>
            <span style={{marginRight: '5px'}}>Status</span>
            {statusFilterDiv}
          </Grid.Column>
          <Grid.Column width={6} textAlign="right">
            {/* {contestSearchDiv} */}
          </Grid.Column>
        </Grid>
      </div>
    );


    const iconDiv = (image, text) => {
      return (
        <Popup
            trigger={
              <img className="contest-list-page-icon" src={image} />
            }
            content={text}
            size='mini'
          />
      );
    };

    const contestTimeStatusDiv = (contest) =>
      contest.ended ?  <div style={{marginBottom: '30px'}}><span>{this.findContestTimeText(contest.startTime)}</span></div> : <Countdown contest={contest} />;

    const recruitContestText = () => <span className="button-style">Scheduled</span>

    const contestList = items.map((contest, index) => {

      const contestItemClassName =
        index === items.length - 1 ? "" : "contest-item-border-bottom";

      return (
        <Segment raised key={contest.name} className={contestItemClassName}>
          {contest.registered ||
            contest.ended ||
            contest.manualRegistration ||
            !isLoggedIn() ? null : (
            <Label attached="bottom left" style={{ padding: "0px" }} className="button-style">
              <Button
                size='large'
                onClick={() => this.onClickRegister(contest)}
                className="button-style"
              >
                Register
              </Button>
            </Label>
          )}

          <div>
            <Grid
              stackable
              columns={2}
              className="no-padding-left-right-bottom"
            >
              <Grid.Column
                width={9}
                className="no-padding-left-right-bottom"
              >
                <div>
                  <div>
                    <a href="#" onClick={() => this.onClickContest(contest.id)}>
                      <span className="contest-name">{contest.name}</span>
                    </a>
                  </div>
                  <div style={{ marginTop: "5px", marginBottom: "5px" }}>
                    {contest.status == 40 && !contest.started ? 
                      <span>
                        <a href="#" onClick={() => this.onClickContest(contest.id)}>
                          <span className="button-style">See details</span>
                        </a> and start the test before {this.findContestTimeDetail(contest.startTime)}
                      </span> 
                      : <span>@ {this.findContestTimeDetail(contest.startTime)}</span>}
                  </div>
                  <div style={{marginBottom: '5px'}}>
                    {contest.rated ? iconDiv(ratingIconImage, 'Rated Contest') : null}
                    {contest.prizeMoney ? iconDiv(prizeMoneyImage, 'Prize Money Available') : null}
                    {contest.registered ? iconDiv(participatedImage, 'Participated') :
                      !contest.ended && !isLoggedIn() ?
                        <span className="base-color">      
                          <p style={{fontWeight: "bold", marginTop: '20px'}}>
                            To participate, please login and register to the contest.
                          </p> 
                        </span>
                      : null
                    }
                  </div>
                </div>
              </Grid.Column>

              <Grid.Column
                width={7}
                style={{ textAlign: "right", padding: "0px" }}
              >
                {contest.status == 40 && !contest.started ? recruitContestText() : contestTimeStatusDiv(contest)}
                {contest.started && <div style={{marginBottom: '5px'}}>
                  <a href="#" onClick={() => this.onClickStandings(contest.id)}>
                    <span className="button-style" style={{fontSize: '.9em', lineHeight: '.9em'}}>Standings</span>
                  </a>
                </div>}
              </Grid.Column>
            </Grid>
          </div>
        </Segment>
      );
    });

    const paginationDiv =
      allContests.length <= itemsPerPage ? null : (
        <div className="pagination-div">
          <Pagination
            className="pagination-custom"
            activePage={this.state.page}
            totalPages={totalPages}
            size='mini'
            siblingRange={1}
            prevItem={false}
            nextItem={false}
            onPageChange={this.setPageNum}
          />
        </div>
      );

    const fetchingDataDiv = (
      <div style={{ textAlign: "center" }}>
        <Icon loading name="spinner" />
      </div>
    );

    const leftDiv = 
      <Grid.Column width={4}>
        <div>
          <ContestListSideBar 
            category={[]} 
            user={this.props.data.user} 
            ratingUsers={ratingUsers} />
        </div>
      </Grid.Column>;

    const rightDiv = 
      <Grid.Column width={12}>
        <Segment raised>
          {filterDiv}
        </Segment>
        <Segment raised>
          {items && items.length > 0 ? contestList : fetchingDataDiv}
        </Segment>
        {paginationDiv}
      </Grid.Column>;

    return (
      errorCode != null ? (
        <div className="main-container">
          <NotFound statusCode={errorCode} />
        </div>
      ) : (
          <div className="main-container">
            <Grid stackable columns={2}>
              {leftDiv}
              {rightDiv}
            </Grid>
          </div>
        )
    );
  }
}

// Connect with "contest" reducer
const mapStateToProps = (state) => {
  return { data: assign({}, state.contest, { user: state.user }) };
};

// Connect with "ContestActions"
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(assign(ContestActions), dispatch);
};

// Connect reducer and action together
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContestListContainer);
