import { PROBLEM, CATEGORY, HEADER, USER } from "./actions";
import callAPI from "../utils/callAPI";
import processCallApiException from "../utils/processCallAPIException";
import { isTeamUser } from "../utils/cookieUtil";

export const fetchProblems = (searchParams) => {
  return (dispatch) => {
    callAPI('/problems/search', 'GET', searchParams)
      .then(response => {
        dispatch({
          type: PROBLEM.SET_PROBLEM_LIST,
          payload: response.body.data,
        });
      }).catch((error) => {
        dispatch({
          type: PROBLEM.SET_STATUS_CODE,
          payload: error.status,
        });
      }).catch(processCallApiException(dispatch));
  };
};

export const fetchProblemForContest = (problemId, contestId) => {
  return (dispatch) => {
    const endpoint = "/contests/" + contestId + (isTeamUser()? "/team" : "") + `/problems/${problemId}`;
    callAPI(endpoint, 'GET')
      .then(response => {
        dispatch({
          type: PROBLEM.SET_PROBLEM_DETAIL,
          payload: response.body.data,
        });
      }).catch((error) => {
        dispatch({
          type: PROBLEM.SET_STATUS_CODE,
          payload: error.status,
        });
      }).catch(processCallApiException(dispatch));
  };
};

export const fetchProblemForAdminUser = (problemId, userId) => {
  return (dispatch) => {
    callAPI('/problems/' + problemId + '/users/' + userId, 'GET')
      .then(response => {
        dispatch({
          type: PROBLEM.SET_PROBLEM_DETAIL,
          payload: response.body.data,
        });
      }).catch((error) => {
        dispatch({
          type: PROBLEM.SET_STATUS_CODE,
          payload: error.status,
        });
      }).catch(processCallApiException(dispatch));
  };
};

export const fetchProblem = (problemId) => {
  return (dispatch) => {
    callAPI('/problems/' + problemId, 'GET')
      .then((response) => {
        dispatch({
          type: PROBLEM.SET_PROBLEM_DETAIL,
          payload: response.body.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: PROBLEM.SET_STATUS_CODE,
          payload: error.status,
        });
      }).catch(processCallApiException(dispatch));
  };
};

export const fetchProblemForAdmin = (problemId) => {
  return (dispatch) => {
    callAPI('/admin/problems/' + problemId, 'GET')
      .then((response) => {
        dispatch({
          type: PROBLEM.SET_PROBLEM_DETAIL,
          payload: response.body.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: PROBLEM.SET_STATUS_CODE,
          payload: error.status,
        });
      }).catch(processCallApiException(dispatch));
  };
};

export const fetchProblemSetters = () => {
  return (dispatch) => {
    callAPI('/users/problem-setters', 'GET')
      .then((response) => {
        dispatch({
          type: USER.SET_PROBLEM_SETTERS,
          payload: response.body.data,
        });
      })
      .catch(processCallApiException(dispatch));
  };
};

export const fetchCategories = () => {
  return (dispatch) => {
    callAPI('/problems/categories', 'GET')
      .then((response) => {
        dispatch({
          type: CATEGORY.SET_CATEGORY_LIST,
          payload: response.body.data,
        });
      })
      .catch(processCallApiException(dispatch));
  };
};

export const submitSolutionFromIDE = (problem) => {
  const code = problem.code;
  const problemId = problem.problemId;
  const languageId = problem.languageId;
  const contestId = problem.contestId;
  const isTestSubmission = problem.isTest;
  const isSampleTest = problem.isSampleTest;
  const submissionObject = {
    code: code,
    contestId: contestId,
    languageId: languageId,
    isTest: isTestSubmission,
    isSampleTest: isSampleTest
  };

  return (dispatch) => {
    const endpoint = "/submissions/" + (isTeamUser()? "team" : "") + `/problem/${problemId}/ide`;
    callAPI(endpoint, 'POST', submissionObject)
      .then((response) => {
        const data = response.body.data;
        if (isSampleTest === '0') {
          // redirect to submission detail page
          window.location.href = '/submissions/' + data.id;
        } else {
          dispatch({
            type: PROBLEM.SET_PROBLEM_SAMPLE_TEST_RUN_SUBMISSION_ID,
            payload: data.id,
          });
        }
      }).catch(processCallApiException(dispatch));
  };
};

export const cleanPrevData = () => {
  return (dispatch) => {
    dispatch({
      type: PROBLEM.SET_PROBLEM_SAMPLE_TEST_RUN_DATA,
      payload: null,
    });
  }
};

export const submitProblem = (problem) => {
  return (dispatch) => {
    const sourceFile = problem.sourceFile;
    const problemId = problem.problemId;
    const languageId = problem.languageId;
    const contestId = problem.contestId;
    const isTestSubmission = problem.isTest;

    const submissionObject = {
      file: sourceFile,
      contestId: contestId,
      userId: problem.userId,
      languageId: languageId,
      isTest: isTestSubmission
    };
    const endpoint = "/submissions/" + (isTeamUser()? "team" : "") + `/problem/${problemId}`;
    callAPI(endpoint, 'POST', submissionObject)
      .then((response) => {
        const data = response.body.data;
        // redirect to submission detail page
        window.location.href = '/submissions/' + data.id;
      })
      .catch((error) => {
        dispatch({
          type: PROBLEM.SET_PROBLEM_SUBMISSION_ERROR,
          payload: "a",
        });
      }).catch(processCallApiException(dispatch));
  };
};

export const getSampleRunData = (submissionId) => {
  if (submissionId == null) return;
  return (dispatch) => {
    callAPI(`/submissions/sample-run/${submissionId}/detail`, 'GET')
      .then((response) => {
        const data = response.body.data;
        dispatch({
          type: PROBLEM.SET_PROBLEM_SAMPLE_TEST_RUN_DATA,
          payload: data,
        });
      }).catch(processCallApiException(dispatch));
  };
};

export const updatePath = () => {
  return (dispatch) => {
    dispatch({
      type: HEADER.SET_PATH,
      payload: "problems",
    });
  };
};


export const createProblem = ({name, timeLimit, memoryLimit }) => {
  return (dispatch) => {
    callAPI(`/admin/problems`, 'POST', { name, memoryLimit, timeLimit })
      .then((response) => {
        const data = response.body.data;
        window.location.href = '/admin-problems/' + data.id;
      })
  };
};

export const updateProblem = (id, request) => {
  return (dispatch) => {
    callAPI(`/admin/problems/${id}/update`, 'POST', request)
      .then((response) => {
        const data = response.body.data;
        window.location.href = '/admin-problems/' + data.id;
      })
  };
};

export const addProblemSetter = (problemId, problemSetterId) => {
  return (dispatch) => {
    callAPI(`/admin/problems/${problemId}/problem-setters/${problemSetterId}`, 'POST')
      .then((response) => {
        const data = response.body.data;
        window.location.href = '/admin-problems/' + data.id;
      })
  };
};

export const deleteTestCase = (problemId, testCaseId) => {
  return (dispatch) => {
    callAPI(`/admin/problems/${problemId}/testcases/${testCaseId}`, 'DELETE')
      .then((response) => {
        window.location.href = '/admin-problems/' + problemId;
      })
  };
};

export const addTestCase = (id, request) => {
  const formData = new FormData();
  formData.append('inputFile', request.inputFile);
  formData.append('outputFile', request.outputFile);
  formData.append('isSampleTestCase', request.isSampleTestCase);
  
  return (dispatch) => {
    const endpoint = `/admin/problems/${id}/testcases`;
    callAPI(endpoint, 'POST', formData, { 'Content-Type': 'multipart/form-data' })
      .then((response) => {
        window.location.reload();
      });
  };
};

export const addProblemFile = (id, request) => {
  const formData = new FormData();
  formData.append('problemFile', request.problemFile);
  
  return (dispatch) => {
    const endpoint = `/admin/problems/${id}/problemFile`;
    callAPI(endpoint, 'POST', formData, { 'Content-Type': 'multipart/form-data' })
      .then((response) => {
        window.location.reload();
      }).catch((error) => {
        dispatch({
          type: PROBLEM.SET_STATUS_CODE,
          payload: error.status,
        });
      }).catch(processCallApiException(dispatch));
  };
};
