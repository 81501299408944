import React, { Component } from 'react';
import { Label } from 'semantic-ui-react'

class CountdownFinished extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const isAlignCenter = this.props.textAlign;
    return (
      <div className="title" style={{ textAlign: isAlignCenter ? "center" : "none" }}>
        <Label size='big'>
          Finished
        </Label>
      </div>
    );
  }
}

export default CountdownFinished;
