import React from "react";
import { Segment, Grid, Table, Icon} from "semantic-ui-react";
import StatusLabel from "./StatusLabel";
export default class SubmissionDetails extends React.Component {
    constructor(props) {
    super(props)
    }

    render() {
        const {details, submission} = this.props;
        const sourceCode = submission ? submission.sourceCode : '';

        const memoryDiv = (submission) => submission && submission.status !== 'In Queue' ?
            <div>
                {submission.memory} KB
            </div> : <Icon loading name='spinner' />;

        const timeDiv = (submission) => submission && submission.status !== 'In Queue' ?
            <div>
                {submission.time} sec
            </div> : <Icon loading name='spinner' />;
        
        const submissionDetailList = details && details.map((detail) => {
            return (
              <Table.Body key={detail.testCaseNo} className="base-color">
                <Table.Row>
                  <Table.Cell>{detail.testCaseNo}</Table.Cell>
                  <Table.Cell>{memoryDiv(detail)}</Table.Cell>
                  <Table.Cell>{timeDiv(detail)}</Table.Cell>
                  <Table.Cell textAlign={"right"}>
                    <StatusLabel 
                      status = {detail.status}
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            );
          });

        const submissionDetailListDiv = details && details.length > 0 ? 
            <Segment raised>
                <Table basic='very' unstackable className="table-scrollable">
                    <Table.Header>
                        <Table.Row>
                        <Table.HeaderCell>Test Case</Table.HeaderCell>
                        <Table.HeaderCell>Memory</Table.HeaderCell>
                        <Table.HeaderCell>CPU Time</Table.HeaderCell>
                        <Table.HeaderCell textAlign={"right"}>Status</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    {submissionDetailList}
                </Table>
            </Segment> : null; 

        return (
            <Grid.Column width={sourceCode ? 9 : 16}>
                {submissionDetailListDiv}
            </Grid.Column>
        )
    }
}