import React, { Component } from "react";
import { Grid, Icon } from "semantic-ui-react";
import graphImage from '../../assets/icons/graph_new1.png';
import searchImage from '../../assets/icons/search1.png';
import stringImage from '../../assets/icons/string_new1.png';
import numberTheoryImage from '../../assets/icons/number-theory.png';
import mathImage from '../../assets/icons/math2.png';
import dpImage from '../../assets/icons/dp1.png';
import geometryImage from '../../assets/icons/geometry2.png';

class ProblemCategoryList extends Component {
  constructor(props) {
    super(props);
    this.onClickHeader = this.onClickHeader.bind(this);
    this.state = {
      expandCategoryNames: [],
    };
  }

  onClickHeader(categoryName) {
    let names = this.state.expandCategoryNames;
    if (names.length > 0) {
      if (names.includes(categoryName)) {
        names = this.state.expandCategoryNames.filter(
          (item) => item !== categoryName
        );
      } else {
        names.push(categoryName);
      }
      this.setState({ expandCategoryNames: names });
    } else {
      names.push(categoryName);
      this.setState({ expandCategoryNames: names });
    }
  }

  onClickSubCategory(mainCategoryName, subCategoryName) {
    window.location.href = "/problems" + "?category=" + mainCategoryName + "&subCategory=" + subCategoryName;
  }

  findCategoryImage(categoryName) {
    if (categoryName === 'Graph') {
      return graphImage;
    } else if (categoryName === 'Search') {
      return searchImage;
    } else if (categoryName === 'String') {
      return stringImage;
    } else if (categoryName === 'Number Theory') {
      return numberTheoryImage;
    } else if (categoryName === 'Math') {
      return mathImage;
    } else if (categoryName === 'DP') {
      return dpImage;
    } else if (categoryName === 'Geometry') {
      return geometryImage;
    }
  }

  render() {
    const { category } = this.props;
    const names = this.state.expandCategoryNames;

    const listSubCategories = (mainCategory, subCategories, isLastCategory) =>
      subCategories.map((category, index) => {
        let itemClassName = "map-item-border-bottom";

        if (isLastCategory && index === 0) {
          itemClassName = "category-item";
        } else {
          itemClassName = "subcategory-item";
        }

        const categoryCountStyle = {
          paddingTop: isLastCategory && index === 0 ? "none" : "0px",
          fontWeight: "700"
        };

        const firstValueStyle = {
          paddingTop: isLastCategory && index === 0 ? "none" : "0px",
          textAlign: "right",
        };

        const midValueStyle = {
          paddingTop: isLastCategory && index === 0 ? "none" : "0px",
          paddingLeft: "0px",
          fontWeight: "700"
        };

        return (
          <Grid stackable columns={3} key={index} className={itemClassName}>
            <Grid.Column width={4} style={firstValueStyle}>
              <Icon name="angle right" />
            </Grid.Column>
            <Grid.Column width={8} style={midValueStyle}>
              <a
                className="title-name"
                href="#"
                onClick={() => this.onClickSubCategory(mainCategory, category.name)}
              >
                {category.name}
              </a>
            </Grid.Column>
            <Grid.Column width={4} textAlign="right" style={categoryCountStyle}>
              {category.count}
            </Grid.Column>
          </Grid>
        );
    });

    const listDiv = category.list.map((item, index) => {
      let itemClassName = "map-item-border-bottom";

      if (index === 0) {
        itemClassName = "category-first-item";
      } else {
        itemClassName = "category-item";
      }

      const catNameStyle = {
        fontWeight: "500 !important",
        color: "#C4C5C6",
      };

      return (
        <div key={index}>
          <Grid
            stackable
            columns={3}
            className={itemClassName}
            onClick={() => this.onClickHeader(item.name)}
          >
            <Grid.Column width={11} style={catNameStyle}>
              <img width={38} height={38} src={this.findCategoryImage(item.name)} /> 
              <span className="title-name" style={{marginLeft: "10px"}}>{item.name}</span>
            </Grid.Column>
            <Grid.Column width={2} style={{marginTop: "6px", fontWeight: "700"}}>{item.count}</Grid.Column>
            <Grid.Column width={3} textAlign="right" style={{marginTop: "6px"}}>
              <Icon name="angle down" />
            </Grid.Column>
          </Grid>
          {names.includes(item.name)
            ? listSubCategories(
              item.name,
              item.subCategories,
              index === category.list.length - 1
            )
            : null}
        </div>
      );
    });

    const noDataDiv = (
      <div style={{ textAlign: "center" }}>
        <Icon loading name="spinner" />
      </div>
    );

    return (
      <div>
        <h3 className="title-name" style={{ paddingBottom: "10px", textAlign: "center" }}>
          Categories
        </h3>
        {category.list.length > 0 ? listDiv : noDataDiv}
      </div>
    );
  }
}

export default ProblemCategoryList;
