import React, { Component } from "react";
import { Dropdown } from "semantic-ui-react";
import { getCookie } from "../../utils/cookieUtil";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import assign from "lodash/assign";

import * as UserActions from "../../actions/userActions";
import Avatar from "../Avatar";
import DefaultAvatar from "../DefaultAvatar";
import { LOCAL_STORAGE_ITEM_AVATAR_URL, USER_GOOGLE_PROFILE_PIC } from "../../constants";

class LogoutMenu extends Component {
  constructor(props) {
    super(props);
    this.onChangeDropDown = this.onChangeDropDown.bind(this);

    this.state = {
      userName: "",
      avatarUrl: null
    }
  }

  componentDidMount() {
    this.setState({
      userName: getCookie("userName"),
      avatarUrl: localStorage.getItem(LOCAL_STORAGE_ITEM_AVATAR_URL) || getCookie(USER_GOOGLE_PROFILE_PIC)
    });
  }

  onChangeDropDown(event, data) {
    const userId = getCookie("id");
    if (data.text === "Logout") {
      this.props.logout(userId);
    } else if (data.text === "Profile") {
      window.location.href = "/users/" + userId;
    }
  }

  render() {
    const avatarDiv = this.state.avatarUrl !== null ? <Avatar url={this.state.avatarUrl} /> : <DefaultAvatar />;
    return (
      <div>
        {avatarDiv}
        <Dropdown
          text={this.state.userName}
          search selection
          button
          className="tiny filter-dropdown-color-min-width"
        >
          <Dropdown.Menu>
            <Dropdown.Item id="profile-id" text="Profile" onClick={this.onChangeDropDown} />
            <Dropdown.Item id="logout-id" text="Logout" onClick={this.onChangeDropDown} />
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}

// Connect with "user" reducer
const mapStateToProps = (state) => {
  return { data: assign({}, state.user) };
};

// Connect with "UserActions"
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(assign(UserActions), dispatch);
};

// Connect reducer and action together
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LogoutMenu);
