import React, { Component } from "react";
import { Container } from "semantic-ui-react";

class Footer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="footer-div">
        <Container>
          <div className="footer">
            <div className="legal">
              <a href="/privacy">Privacy</a>
              <a href="/terms">Terms</a>
            </div>
            <div className="copyright">
              <span>© 2021 CodePanja</span>
              <span style={{ marginLeft: "10px" }}>(Developed by <a href="https://www.linkedin.com/in/atul-kumar-pk-6591b4b4/">Atul Kumar PK</a>)</span>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

export default Footer;
