import React from "react";
import { Segment, Icon, Table, Button } from "semantic-ui-react";

import StatusLabel from "../../Components/Submission/StatusLabel";

const ContestProblems = (props) => {
  const { problems, ended, registered, frozen, contestId, isAdmin } = props;
  const problemListRow = problems.map((problem) => {
    return (
      <Table.Body key={problem.problemId}>
        <Table.Row>
          <Table.Cell className="base-color">
            <h4 className="text-style">{problem.serialName}</h4>
          </Table.Cell>
          <Table.Cell>
            <h4 className="text-style">
              <a
                href="#"
                onClick={() =>
                  props.onClickProblem(problem.problemId, contestId, ended)
                }
              >
                <h4 className="text-style">{problem.problemName}</h4>
              </a>
            </h4>
          </Table.Cell>
          <Table.Cell textAlign="center" className="base-color"><b>{problem.totalSubmitted}</b></Table.Cell>
          <Table.Cell textAlign="center" className="base-color"><b>{problem.totalAccepted}</b></Table.Cell>
          {registered && !frozen ? (
            <Table.Cell textAlign="right" className="base-color">
              <StatusLabel
                status={problem.accepted ? "Accepted" : "Unsolved"}
              />
            </Table.Cell>
          ) : null}

          {isAdmin ? (
            <Table.Cell textAlign="right" className="base-color">
              <Button
                className="button-style"
                onClick={() =>
                  props.removeProblemsFromContest(problem.problemId)
                }
              >
                Remove
              </Button>
            </Table.Cell>
          ) : null}
        </Table.Row>
      </Table.Body>
    );
  });

  const problemListDiv =
    problems.length > 0 ? (
      <Segment raised className="no-padding-left-right-bottom">
        <Table basic="very" unstackable className="table-scrollable">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="base-color">#</Table.HeaderCell>
              <Table.HeaderCell className="base-color">Problem</Table.HeaderCell>
              <Table.HeaderCell className="base-color" textAlign="center">Total Submitted</Table.HeaderCell>
              <Table.HeaderCell className="base-color" textAlign="center">Total Accepted</Table.HeaderCell>
              {registered && !frozen ? (
                <Table.HeaderCell className="base-color" style={{ textAlign: "right" }}>
                  Status
                </Table.HeaderCell>
              ) : null}
            </Table.Row>
          </Table.Header>
          {problemListRow}
        </Table>
      </Segment>
    ) : (
      <div style={{ textAlign: "center" }}>
        <Icon loading name="spinner" />
      </div>
    );
  return problemListDiv;
};

export default ContestProblems;
