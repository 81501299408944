import React, { Component } from 'react'
import { Button, Form } from 'semantic-ui-react'
import { CONTEST_RATED_STATUS, CONTEST_STATUS, CONTEST_REGISTRATION_WAYS } from "../../constants";


class ContestDetailEdit extends Component {
  constructor(props) {
    super(props);
    this.onClickSave = this.onClickSave.bind(this);
    this.handleStatusDropdown = this.handleStatusDropdown.bind(this);
    this.handleRatedStatusDropdown = this.handleRatedStatusDropdown.bind(this);
    this.handleRegistrationFlg = this.handleRegistrationFlg.bind(this);

    this.state = {
      name: null,
      prizeMoney: null,
      status: null,
      manualRegistration: false,
      ratedStatus: null,
      description: null,
      nameEdited: false,
      prizeMoneyEdited: false,
      descriptionEdited: false
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.detail !== this.props.detail) {
      this.setState({ 
        name: this.props.detail.name,
        prizeMoney: this.props.detail.prizeMoney,
        status: this.props.detail.status,
        ratedStatus: this.props.detail.rated ? 10 : 20,
        description: this.props.detail.description,
        manualRegistration: this.props.detail.manualRegistration
      });
    }
}
  handleStatusDropdown(event, data) {
    this.setState({status: data.value});
  }

  handleRegistrationFlg(event, data) {
    this.setState({manualRegistration: data.value});
  }

  handleRatedStatusDropdown(event, data) {
    this.setState({ratedStatus: data.value});
  }

  onChangeName = (event) => this.setState({ name: event.target.value, nameEdited: true });
  onChangePrizeMoney = (event) => this.setState({ prizeMoney: event.target.value, prizeMoneyEdited: true });
  onChangeDescription = (event) => this.setState({ description: event.target.value, descriptionEdited: true });

  onClickSave() {
    this.props.updateContestDetail(this.state);
  }

  render() {
    const { detail } = this.props;

    const name = this.state.name;
    const prizeMoney = this.state.prizeMoney;
    const status = this.state.status;
    const ratedStatus = this.state.ratedStatus;
    const description = this.state.description;
    const manualRegistration = this.state.manualRegistration;

    return (
      <Form className="profile-edit">
        <Form.Group widths='equal'>
          <Form.Input fluid label='Name' value={name} onChange={this.onChangeName}/>
          <Form.Input fluid label='Prize Money' value={prizeMoney} onChange={this.onChangePrizeMoney}/>
          <Form.Select
            fluid
            label='Status'
            options={CONTEST_STATUS}
            value={status}
            onChange={this.handleStatusDropdown}
          />
          <Form.Select
            fluid
            label='Registration'
            options={CONTEST_REGISTRATION_WAYS}
            value={manualRegistration}
            onChange={this.handleRegistrationFlg}
          />
          <Form.Select
            fluid
            label='Rated status'
            options={CONTEST_RATED_STATUS}
            value={ratedStatus}
            onChange={this.handleRatedStatusDropdown}
          />
        </Form.Group>
        <Form.TextArea label='Description' value={description} onChange={this.onChangeDescription} />
        <Button className="button-style" onClick={this.onClickSave}>Save</Button>
      </Form>
    )
  }
}

export default ContestDetailEdit;