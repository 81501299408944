import React, { Component } from 'react';
import { Segment, Grid, Table, Icon, Popup, Label } from "semantic-ui-react";
import { COUNTRY, USER_TYPE_VALUE_TEAM } from "../constants";
import DefaultAvatar from './DefaultAvatar';
import acceptedStatusImage from '../assets/icons/accepted2.png';
import firstAcceptedStatusImage from '../assets/icons/accepted11.png';
import pendingStatusImage from '../assets/icons/pending2.png';
import wrongStatusImage from '../assets/icons/wrong5.png';
import AvatarWithFlag from './AvatarWithFlag';

class Standings extends Component {
  constructor(props) {
    super(props);
  }

  onClickUser(userId, userType) {
    if (userType == null || userType !== USER_TYPE_VALUE_TEAM) {
      window.location.href = "/users/" + userId;
    }
  }

  getCountryName(countryCode) {
    const countryInfo = COUNTRY.filter(function (country) { return country.countryCode === countryCode });
    const countryName = countryInfo && countryInfo.length > 0 ? countryInfo[0].name : "";
    return countryName;
  }

  render() {
    const { standings } = this.props;

    const solutions = (submissions) => submissions.map(((submission, index) => {
      let statusImage = submission.accepted ? acceptedStatusImage : wrongStatusImage;
      let popupContent = submission.accepted ? "Solved" : "Tried but failed";

      if (!submission.accepted) {
          if (submission.frozenCount > 0) {
            popupContent = "In Judge Queue";
            statusImage = pendingStatusImage;
          } else if (submission.pendingCount > 0) {
            popupContent = "In Processing";
            statusImage = pendingStatusImage;
          }
      }

        if (submission.firstSolved) {
          popupContent = "First solved"
          statusImage = firstAcceptedStatusImage;
        }
        
      const iconDiv = submission.totalSubmission <= 0 ? null :
        <Popup
          trigger={
            <img className="standing-status-icon" src={statusImage} /> 
          }
          content={popupContent}
          size='mini'
        />;

      const firstSolvedIconDiv =
        <Popup
          trigger={
            <img className="standing-status-icon" src={statusImage} /> 
          }
          content={popupContent}
          size='mini'
        />;

      const labelValue = submission.totalSubmission + ' (' + submission.acTimeAfterContestStartInMinutes + ')';

      return (
        <Table.Cell textAlign="center" key={index}>
          <div>
            {submission.firstSolved ? firstSolvedIconDiv : iconDiv}
          </div>
          <div>
            {submission.totalSubmission > 0 ?
              <Label
                size="mini"
                style={{ 
                  padding: '3px',
                  fontSize: '0.7rem'
                }}>
                {labelValue}
              </Label> : null
            }
          </div>
        </Table.Cell>
      );
    }));

    const standingList = standings.map((submission, index) => {
      const user = submission.userDto;
      const submissions = submission.submissions;
      // const avatarDiv = user.avatarUrl ? 
      //   <AvatarWithFlag url={user.avatarUrl} countryCode={user.countryCode} /> 
      //   : <DefaultAvatar />;

      return (
        <Table.Body key={index} className="base-color">
          <Table.Row>
            <Table.Cell style={{ width: "5%" }}>
              {index + 1}
            </Table.Cell>
            <Table.Cell style={{ width: "30%" }}>
              <>
                {/* {avatarDiv} */}
                <a
                  style={{ display: "inline-block" }}
                  href="#"
                  onClick={() => this.onClickUser(user.id)}
                >
                  <span className="author-name">
                    {user.userName}
                  </span>
                </a>
              </>
            </Table.Cell>
            <Table.Cell>
              <span style={{fontWeight: "bold"}}>{submission.totalAccepted} / {submission.totalTimeInMinutesForAc}</span>
            </Table.Cell>
            {solutions(submissions)}
          </Table.Row>
        </Table.Body>
      );
    });

    const solutionsHeaders = standings.length > 0 && standings[0].submissions.length > 0 ?
      standings[0].submissions.map(((submission, index) => {
        const problemSerialName = submission.problemSerialName;
        return (
          <Table.HeaderCell className="base-color" textAlign="center" key={index}>{problemSerialName}</Table.HeaderCell>
        );
      })) : null;

    const standingListDiv = standings.length > 0 ?
      <Table basic="very" unstackable className="table-scrollable">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className="base-color">#</Table.HeaderCell>
            <Table.HeaderCell className="base-color">User</Table.HeaderCell>
            <Table.HeaderCell className="base-color">Solved/Time</Table.HeaderCell>
            {solutionsHeaders}
          </Table.Row>
        </Table.Header>
        {standingList}
      </Table> :
      <div style={{ textAlign: "center" }}>
        <Icon loading name='spinner' />
      </div>

    return (
      <Grid stackable columns={1}>
        <Grid.Column className="no-padding-left-right-bottom">
          <Segment raised>
            {standingListDiv}
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }
}

export default Standings;
