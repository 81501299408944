import React from "react";
import Slider from "../Slide/Slider";
import defaultAvatarUrl from '../../../src/default_avatar.png';
import AvatarPopup from "../AvatarPopup";
import { Grid } from "semantic-ui-react";

// Function to calculate the duration
const calculateDuration = (startTime, endTime) => {
  const durationInMillis = endTime - startTime;
  const hours = Math.floor(durationInMillis / (1000 * 60 * 60));
  const minutes = Math.floor((durationInMillis % (1000 * 60 * 60)) / (1000 * 60));
  
  let durationString = `${hours} hour(s)`;
  if (minutes > 0) {
    durationString += ` ${minutes} minute(s)`;
  }
  
  return durationString;
};

const ContestOverview = (props) => {
  const { detail, problemSetters, problems, extraInfo } = props;

  // Calculate the duration string if detail is available
  const durationString = detail && detail.startTime && detail.endTime 
    ? calculateDuration(detail.startTime, detail.endTime) 
    : '';

  const prizeMoney = detail && detail.prizeMoney ? detail.prizeMoney : null;

  const contestDetailDiv = detail ?
    <div style={{ marginTop: "30px", marginBottom: "30px", marginLeft: "5px" }}>
      <h3 className="base-color" style={{marginBottom: '10px', fontWeight: 'bold'}}>About</h3>
      <span>{detail.description}</span>
    </div> : null;

  const problemSettersDiv = problemSetters.map((user) => {
    const url = user.avatarUrl || user.googleProfilePictureUrl || defaultAvatarUrl;
    return (
      <span style={{marginRight: '15px'}}>
        <AvatarPopup key={user.id} url={url} popupValue={user.fullName} id={user.id} />
      </span>
    );
  });

  return (
    <div>
      {extraInfo && extraInfo.length > 0 ? <Slider images = {extraInfo}/> : null}
      <div style={{marginTop: '50px'}}>
        <Grid>
            <Grid.Column width={2}>
              <div>
                <h3 className="base-color" style={{fontWeight: 'bold'}}>Duration</h3>
                <span>{durationString}</span>
              </div>
            </Grid.Column>
            <Grid.Column width={3}>
              <div style={{borderLeft: "1px solid #5E5E5E", paddingLeft: "20px", textAlign: 'center'}}>
                <h3 className="base-color" style={{fontWeight: 'bold'}}>Problem Count</h3>
                <span>{problems.length}</span>
              </div>
            </Grid.Column>
            <Grid.Column width={2}>
              {prizeMoney ?
              <div style={{borderLeft: "1px solid #5E5E5E", paddingLeft: "50px", textAlign: 'center'}}>
                <h3 className="base-color" style={{fontWeight: 'bold'}}>prizeMoney</h3>
                <span>{prizeMoney} TK </span>
              </div> : null}
            </Grid.Column>
        </Grid>
      </div>
      {contestDetailDiv}
      <div style={{marginTop: '20px', marginBottom: '50px'}}>
        <h3 className="base-color" style={{marginBottom: '10px', fontWeight: 'bold'}}>Problem Setters</h3>
        {problemSettersDiv}
      </div>
    </div>
  );
};

export default ContestOverview;
