import React, { Component } from "react";
import { Header, Modal } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import assign from "lodash/assign";

import SigninForm from "../Components/Auth/SigninForm";

import * as SigninActions from "../actions/SigninActions";
import OauthLogin from "../Components/Auth/OauthLogin";
import { SIGNIN_MODAL } from "../constants";

class AuthContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageType: 'login'
    };
    this.close = this.close.bind(this);
    this.doSignIn = this.doSignIn.bind(this);
    this.doSignUp = this.doSignUp.bind(this);
    this.sendPasswordChangeEmail = this.sendPasswordChangeEmail.bind(this);
  }

  close = () => this.props.close(false);

  doSignIn = (mail, password, userType) => {
    this.props.doSignIn(mail, password, userType);
  };

  doSignUp = (signUpRequestObj) => {
    this.props.doSignUp(signUpRequestObj);
  };

  sendPasswordChangeEmail = (email) => {
    this.props.sendPasswordChangeEmail(email);
  };

  setPageType = (pageName) => {
    this.setState({pageType: pageName});
  }

  isForgotPasswordPage = () => {
    return this.state.pageType === 'forgot_password';
  }

  render() {
    const { dimmer, isOpenLoginModal } = this.props;

    const displayHeaderText = this.state.pageType === 'login'
      ? SIGNIN_MODAL.signInTitle
      : this.state.pageType === 'create_account'
        ? SIGNIN_MODAL.signUpTitle
        : SIGNIN_MODAL.forgotPasswordTitle;

    return (
      <Modal
        id="login-modal"
        dimmer={dimmer}
        open={isOpenLoginModal}
        onClose={this.close}
        closeIcon
      >
        <Modal.Content id="login-modal-content" scrolling>
          <Modal.Description>
            <Header as="h2" className="base-color" style={{marginTop: '20px', textAlign: 'center'}}>
              {displayHeaderText}
            </Header>
            <div className="login-container">
              <SigninForm
                doSignIn={(mail, password, userType) => this.doSignIn(mail, password, userType)}
                doSignUp={(signUpRequestObj) => this.doSignUp(signUpRequestObj)}
                resetErrorStatus={() => this.props.resetErrorStatus()}
                isSignInFailed={this.props.data.isSignInFailed}
                signUpError={this.props.data.signUpError}
                signInError={this.props.data.signInError}
                sendPasswordChangeEmail={(email) =>
                  this.sendPasswordChangeEmail(email)
                }
                setPageType={(page) =>
                  this.setPageType(page)
                }
              />
              {this.isForgotPasswordPage() ? null : <OauthLogin />}
            </div>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

// Connect with "signIn" reducer
const mapStateToProps = (state) => {
  return { data: assign({}, state.signIn) };
};

// Connect with "SigninActions"
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(assign(SigninActions), dispatch);
};

// Connect reducer and action together
export default connect(mapStateToProps, mapDispatchToProps)(AuthContainer);
