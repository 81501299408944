import { AUTH } from "../actions/actions";

const initialState = {
  isSignInFailed: false,
  signInError: "",
  signUpError: "",
};

const signInReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_MODEL":
      return { ...state, isModalOpen: action.payload };
    case AUTH.SET_SIGN_IN_STATUS:
      return { ...state, isSignInFailed: action.payload.isSignInFailed, signInError: action.payload.error };
    case AUTH.SET_SIGN_UP_ERROR:
      return { ...state, signUpError: action.payload };
    default:
      return state;
  }
};

export default signInReducer;
