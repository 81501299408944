import React, { Component } from "react";

class Exception extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const statusCode = this.props.statusCode;
        let msg = "";

        if (statusCode) {
            if (statusCode === 401) {
                msg = "You are not authorized to see the content right now."
            } else if (statusCode === 404) {
                msg = "Content is unavailable right now."
            } else {
                msg = "Something went wrong right now."
            }
        }
        return (
            <div id="notfound">
                <div className="notfound">
                    <div className="notfound-bg">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <h1>oops!</h1>
                    <h2>{msg}</h2>
                </div>
            </div>
        );
    }
}

export default Exception;
