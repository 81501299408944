import React from "react";

const WarningMessage = (props) => (
  <div className="ui icon warning message">
    <div className="content">
      <div className="header">{props.title}</div>
      <p>{props.message}</p>
    </div>
  </div>
);

export default WarningMessage;
