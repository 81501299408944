import React, { Component } from "react";
import { Button, Form, Header, Divider, Checkbox, Icon, Radio } from "semantic-ui-react";

import { SIGNIN_MODAL, USER_TYPE_VALUE_NORMAL, USER_TYPE_VALUE_TEAM } from "../../constants";

import WarningMessage from "../../Components/Warning/WarningMessage";

class SigninForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      fullName: "",
      userName: "",
      countryCode: "",
      isSignInError: false,
      signInError: "",
      signUpError: "",
      isSigninPage: true,
      isSignupPage: false,
      isForgotPasswordPage: false,
      signinLoading: false,
      signupLoading: false,
      forgotPasswordLoading: false,
      subscribed: false,
      userType: "normal_user"
    };

    this.onChanageMail = this.onChanageMail.bind(this);
    this.onChanagePassword = this.onChanagePassword.bind(this);
    this.onChanageConfirmPassword = this.onChanageConfirmPassword.bind(this);
    this.onChanageFullName = this.onChanageFullName.bind(this);
    this.onChanageUserName = this.onChanageUserName.bind(this);
    this.signInClick = this.signInClick.bind(this);
    this.signUpClick = this.signUpClick.bind(this);
    this.sendPasswordChangeEmail = this.sendPasswordChangeEmail.bind(this);
    this.onChangeLoginUserType = this.onChangeLoginUserType.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isSignInFailed !== this.props.isSignInFailed) {
      this.setState({
        isSignInError: this.props.isSignInFailed,
        signInError: this.props.signInError,
        signinLoading: false
      });
    } else if (prevProps.signUpError !== this.props.signUpError) {
      this.setState({ signUpError: this.props.signUpError, signupLoading: false });
    }
  }

  showSignupInput = () => {
    this.setState({
      isSignupPage: true,
      isSigninPage: false,
      signInError: "",
      signUpError: "",
    });
    this.props.resetErrorStatus();
    this.props.setPageType('create_account');
  };

  showSigninInput = () => {
    this.setState({
      isSignupPage: false,
      isSigninPage: true,
      signInError: "",
      signUpError: "",
    });
    this.props.resetErrorStatus();
    this.props.setPageType('login');
  };

  showForgotPasswordInput = () => {
    this.setState({
      isSignupPage: false,
      isSigninPage: false,
      isForgotPasswordPage: true,
    });
    this.props.resetErrorStatus();
    this.props.setPageType('forgot_password');
  };

  signInClick = () => {
    if (this.state.email !== "" && this.state.password !== "") {
      this.setState({ signinLoading: true });
      this.props.doSignIn(this.state.email, this.state.password, this.state.userType);
    } else {
      this.setState({ signInError: "All fields are required." });
    }

    setTimeout(function () {
      this.setState({ signinLoading: false });
    }.bind(this), 5000);
  };

  signUpClick = () => {
    if (
      this.state.email !== "" &&
      this.state.password !== "" &&
      this.state.fullName !== "" &&
      this.state.userName !== "" &&
      this.state.confirmPassword !== ""
    ) {
      if (this.state.password !== this.state.confirmPassword) {
        this.setState({ signUpError: "Password is not matched." });
      } else {
        this.setState({ signupLoading: true });
        this.props.doSignUp(this.state);
      }
    } else {
      this.setState({ signUpError: "All fields are required." });
    }

    setTimeout(function () {
      this.setState({ signupLoading: false });
    }.bind(this), 5000);
  };

  sendPasswordChangeEmail = () => {
    this.setState({ forgotPasswordLoading: true });
    this.props.sendPasswordChangeEmail(this.state.email);
  };

  onChanageMail = (event) => this.setState({ email: event.target.value });

  onChanagePassword = (event) => {
    if (event.target.value.length <= 50) {
      this.setState({ password: event.target.value });
    }
  }

  onChangeLoginUserType = (e, { value }) => {
    this.setState({ userType: value });
  }

  onChanageFullName = (event) => {
    if (event.target.value.length <= 45) {
      this.setState({ fullName: event.target.value });
    }
  }

  onChanageUserName = (event) => {
    if (event.target.value.length <= 45) {
      this.setState({ userName: event.target.value });
    }
  }

  onChanageConfirmPassword = (event) => {
    if (event.target.value.length <= 50) {
      this.setState({ confirmPassword: event.target.value });
    }
  }

  onClickSubscribed = () => this.setState({ subscribed: !this.state.subscribed });

  render() {
    const signInError = (
      <WarningMessage title="Login failed!" message={this.state.signInError} />
    );

    const signUpError = (
      <WarningMessage
        title="Create Account failed!"
        message={this.state.signUpError}
      />
    );

    const bottomLink = this.state.isSigninPage ? (
      <div>
        <div className="base-color">
          Don't have an account?{" "}
          <a href="#" onClick={this.showSignupInput} className="button-style">
            Create account
          </a>
        </div>
        <div className="base-color">
          Have you forgot Password?{" "}
          <a href="#" onClick={this.showForgotPasswordInput} className="button-style">
            Reset password
          </a>
        </div>
      </div>
    ) : (
      <div className="base-color">
        Already have an account?{" "}
        <a href="#" onClick={this.showSigninInput} className="button-style">
          Login
        </a>
      </div>
    );

    const loadingDiv = (
      <div style={{ textAlign: "center" }}>
        <Icon loading name='spinner' />
      </div>
    );

    const signinButton = this.state.signinLoading ? loadingDiv : (
      <div>
        <Button
            className="button-style"
            onClick={this.signInClick}
        >
            Continue
        </Button>
      </div>
    );

    const signupButton = this.state.signupLoading ? loadingDiv : (
      <div>
        <Button
            className="button-style"
            onClick={this.signUpClick}
        >
            Create account
        </Button>
      </div>
    );

    const signinInputForm = (
      <div>
        <Form.Input
          icon="mail"
          iconPosition="left"
          placeholder="Email or UserName"
          value={this.state.email}
          onChange={this.onChanageMail}
        />
        <Form.Input
          icon="lock"
          iconPosition="left"
          placeholder="Password"
          type="password"
          value={this.state.password}
          onChange={this.onChanagePassword}
        />
        {signinButton}
      </div>
    );

    const signupInputForm = (
      <div>
        <Form.Input
          icon="mail"
          iconPosition="left"
          placeholder="Email"
          value={this.state.email}
          onChange={this.onChanageMail}
        />
        <Form.Input
          icon="user"
          iconPosition="left"
          placeholder="Full Name"
          value={this.state.fullName}
          onChange={this.onChanageFullName}
        />
        <Form.Input
          icon="user"
          iconPosition="left"
          placeholder="User Name"
          value={this.state.userName}
          onChange={this.onChanageUserName}
        />
        <Form.Input
          icon="lock"
          iconPosition="left"
          placeholder="Password"
          type="password"
          value={this.state.password}
          onChange={this.onChanagePassword}
        />
        <Form.Input
          icon="lock"
          iconPosition="left"
          placeholder="Confirm password"
          type="password"
          value={this.state.confirmPassword}
          onChange={this.onChanageConfirmPassword}
        />
        <div style={{ marginTop: "5px", marginBottom: "10px" }}>
          <Checkbox onClick={this.onClickSubscribed} label={SIGNIN_MODAL.checkBoxText} />
        </div>
        {signupButton}
      </div>
    );

    const forgotPasswordButton = this.state.forgotPasswordLoading ? loadingDiv : (
      <div>
        <Button
            className="button-style"
            style={{ width: "100%"}}
            onClick={this.sendPasswordChangeEmail}
        >
            Continue
        </Button>
      </div>
    );

    const forgotPasswordInputForm = (
      <div>
        <Form.Input
          icon="mail"
          iconPosition="left"
          placeholder="Email"
          value={this.state.email}
          onChange={this.onChanageMail}
        />
        {forgotPasswordButton}
      </div>
    );

    const loginOptions = (
      <div style={{ marginBottom: "15px" }}>
        <Form>
          <Form.Field>
            <Radio
              className="base-color"
              label='General User'
              name='radioGroup'
              value={USER_TYPE_VALUE_NORMAL}
              checked={this.state.userType === USER_TYPE_VALUE_NORMAL}
              onChange={this.onChangeLoginUserType}
            />
          </Form.Field>
          <Form.Field>
              <Radio
                label='Team User'
                name='radioGroup'
                value={USER_TYPE_VALUE_TEAM}
                checked={this.state.userType === USER_TYPE_VALUE_TEAM}
                onChange={this.onChangeLoginUserType}
              />
            </Form.Field> 
        </Form>
      </div>
    );

    const displayInputForm = this.state.isSigninPage
      ? signinInputForm
      : this.state.isSignupPage
        ? signupInputForm
        : forgotPasswordInputForm;

    return (
      <div className="login-buttons">
        <div>
          {this.state.isSigninPage ? loginOptions : null}
          {this.state.signInError !== "" ? signInError : null}
          {this.state.signUpError !== "" ? signUpError : null}
          <Form>
            {displayInputForm}
            <Divider />
            {bottomLink}
          </Form>
        </div>
      </div>
    );
  }
}

export default SigninForm;
