import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import assign from "lodash/assign";
import { Segment, Grid, Label, Icon, Input, Table, TableHeader, TableRow, TableHeaderCell, TableBody, TableCell } from "semantic-ui-react";
import { Pagination, Dropdown } from "semantic-ui-react";

import * as ProblemActions from "../actions/ProblemActions";
import ProblemCategoryList from "../Components/Problem/ProblemCategoryList";
import StatusLabel from "../Components/Submission/StatusLabel";
import { isLoggedIn } from "../utils/cookieUtil";
import NotFound from "../Components/Error/NotFound";
import userIconImage from '../assets/icons/user_new1.png';
import totalSubmissionImage from '../assets/icons/submit_new2.png';
import totalAcceptedImage from '../assets/icons/success_new3.png';

class ProblemListContainer extends Component {
  constructor(props) {
    super(props);
    this.onClickProblem = this.onClickProblem.bind(this);
    this.onChangeStatusDropDown = this.onChangeStatusDropDown.bind(this);
    this.onChangeDifficultyDropDown = this.onChangeDifficultyDropDown.bind(this);

    this.state = {
      page: 1,
      category: '',
      subCategory: '',
      statusFilterValue: 'All',
      difficultyFilterValue: 'All',
      problemName: ''
    };
  }

  handleChange = (e) => this.setState({ problemName: e.target.value });

  handleSearchIconClick = (e) => {
    if (this.state.problemName) {
      window.location.href = "/problems?name=" + this.state.problemName;
    }
  };

  keyPressed = (e) => {
    if (e.key === "Enter" && this.state.problemName) {
      window.location.href = "/problems?name=" + this.state.problemName;
    }
  };

  componentDidMount() {
    document.title = "Problems";
    this.props.updatePath();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.get("status")) {
      this.setState({ statusFilterValue: urlParams.get("status") })
    }
    if (urlParams.get("difficulty")) {
      this.setState({ difficultyFilterValue: urlParams.get("difficulty") })
    }

    let searchParams = {
      userId: null,
      categoryName: null,
      categorySubName: null,
      submissionStatus: null,
      difficultyLevel: null,
      orderBy: null,
      name: null
    };

    searchParams.categoryName = urlParams.get("category") || null;
    searchParams.categorySubName = urlParams.get("subCategory") || null;
    searchParams.submissionStatus = urlParams.get("status") || null;
    searchParams.difficultyLevel = urlParams.get("difficulty") || null;
    searchParams.orderBy = urlParams.get("orderBy") || null;
    searchParams.name = urlParams.get("name") || null;
    searchParams.userId = this.props.data.user.loggedInUserId || null;

    this.setState({ category: searchParams.categoryName });
    this.setState({ subCategory: searchParams.categorySubName });

    this.props.fetchProblems(searchParams);
    this.props.fetchCategories();
  }

  onChangeStatusDropDown(event, data) {
    this.addExtraQueryParams('status', data.text)
  }

  onChangeDifficultyDropDown(event, data) {
    this.addExtraQueryParams('difficulty', data.text)
  }

  addExtraQueryParams(key, value) {
    const url = new URL(window.location);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    urlParams.set(key, value);

    // Construct the new URL without the query parameters
    const newUrl = `${url.pathname}?${urlParams.toString()}`;
        
    // Update the browser's address bar
    window.history.replaceState(null, '', newUrl);

    // Reload the page
    window.location.reload();
  }

  setPageNum = (event, { activePage }) => {
    this.setState({ page: activePage });
  };

  onClickProblem(problemId) {
    window.location.href = "/problems/" + problemId;
  }

  onClickAuthorId(authorId) {
    window.location.href = "/users/" + authorId;
  }

  onClickCategoryDelete() {
    const url = new URL(window.location);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    urlParams.delete('category');
    urlParams.delete('subCategory');

    // Construct the new URL without the query parameters
    const newUrl = `${url.pathname}${urlParams}`;
        
    // Update the browser's address bar
    window.history.replaceState(null, '', newUrl);

    // Reload the page
    window.location.reload();
  }

  findCategoryName() {
    if (this.state.category) {
      return this.state.category + ' - ' + this.state.subCategory;
    } else {
      return 'All';
    }
  }

  render() {
    const statusCode = this.props.data.statusCode;
    let allProblems = this.props.data.list;
    const itemsPerPage = 25;
    const totalPages = parseInt(
      allProblems.length / itemsPerPage +
      (allProblems.length % itemsPerPage > 0 ? 1 : 0)
    );
    const items = allProblems.slice(
      (this.state.page - 1) * itemsPerPage,
      (this.state.page - 1) * itemsPerPage + itemsPerPage
    );

    const statisticValue = {
      marginLeft: "10px",
      textAlign: "center",
      fontSize: ".85vw",
      fontWeight: "900",
      color: "#C4C5C6"
    };

    const statusFilterDiv = (
      <Dropdown
        text={this.state.statusFilterValue}
        search selection
        button
        placeholder='Solved Status'
        className="tiny filter-dropdown-color-margin-right"
      >
        <Dropdown.Menu>
          <Dropdown.Item className="filter-dropdown-color-item" text="All" onClick={this.onChangeStatusDropDown} />
          <Dropdown.Item className="filter-dropdown-color-item" text="Solved" onClick={this.onChangeStatusDropDown} />
          <Dropdown.Item className="filter-dropdown-color-item" text="Unsolved" onClick={this.onChangeStatusDropDown} />
        </Dropdown.Menu>
      </Dropdown>
    );

    const categoryTagDiv = (
      <Label className="category-tag">
          {this.findCategoryName()}
          <Icon name='delete' onClick={this.onClickCategoryDelete}/>
        </Label>
    );

    const difficultyFilterDiv = (
      <Dropdown
        text={this.state.difficultyFilterValue}
        search selection
        button
        placeholder='Difficulty Level'
        className="tiny filter-dropdown-color-margin-right"
      >
        <Dropdown.Menu>
          <Dropdown.Item className="filter-dropdown-color-item" text="All" onClick={this.onChangeDifficultyDropDown} />
          <Dropdown.Item className="filter-dropdown-color-item" text="Easy" onClick={this.onChangeDifficultyDropDown} />
          <Dropdown.Item className="filter-dropdown-color-item" text="Medium" onClick={this.onChangeDifficultyDropDown} />
          <Dropdown.Item className="filter-dropdown-color-item" text="Hard" onClick={this.onChangeDifficultyDropDown} />
        </Dropdown.Menu>
      </Dropdown>
    );

    const problemSearchDiv = (
      <span style={{float: "right"}}>
        <Input
          icon={{ name: 'search', color: 'red', circular: true, link: true, onClick: this.handleSearchIconClick }}
          placeholder="Search problem..."
          onChange={this.handleChange}
          onKeyPress={this.keyPressed}
        />
      </span>
    );

    const filterDiv = (
      <div>
        {/* <span style={{marginRight: '5px'}}>Difficulty Level</span>
        {difficultyFilterDiv} */}
        <span style={{marginRight: '5px'}}>Solved Status</span>
        {statusFilterDiv}
        {this.state.category ? categoryTagDiv : null}
        {problemSearchDiv}
      </div>
    );

    const problemListTableRows = items.map((problem, index) => {
      return (
        <TableRow key={index}>
            <TableCell>
              <div>
                <a href="#" onClick={() => this.onClickProblem(problem.id)}>
                  <span style={{color: '#C4C5C6', fontSize: '1.2em', fontWeight: "bold"}}>{problem.name}</span>
                </a>
              </div>
            </TableCell>
            <TableCell>
              <div>
                {/* <img width={30} height={30} src={userIconImage} /> */}
                {problem.authorId ? 
                  <a href="#" onClick={() => this.onClickAuthorId(problem.authorId)}>
                    <span size="mini" className="author-name">
                      {problem.authorName}
                    </span>
                  </a> : 
                  <span size="mini" className="author-name">
                    {problem.authorName}
                  </span>
                }
              </div>
            </TableCell>
            <TableCell>
              <div>
                    <img width={25} height={25} src={totalSubmissionImage} />
                    <span style={statisticValue}>{problem.totalSubmission}</span>
              </div>
            </TableCell>
            <TableCell>
              <div>
                    <img width={25} height={25} src={totalAcceptedImage} />
                    <span style={statisticValue}>{problem.totalAccepted}</span>
              </div>
            </TableCell>
          </TableRow>
      );
    });

    const problemListTable = (
      <div id="problem-list-div">
        <Table stackable>
          <TableHeader>
            <TableRow>
              <TableHeaderCell>Name</TableHeaderCell>
              <TableHeaderCell>Author</TableHeaderCell>
              <TableHeaderCell>Submissions</TableHeaderCell>
              <TableHeaderCell>Accepted</TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {problemListTableRows}
          </TableBody>
        </Table>
      </div>
    );

    const problemList = items.map((problem, index) => {
      const problemItemClassName =
        index === items.length - 1 ? "" : "problem-item-border-bottom";
      return (
        <Segment key={problem.name}>
          <div>
            <Grid stackable columns={2} className={problemItemClassName}>
              <Grid.Column width={4} className="no-padding-left-right-bottom">
                <div>
                  <a href="#" onClick={() => this.onClickProblem(problem.id)}>
                    <h4 className="title-name" style={{fontSize: '1.4em', lineHeight: '1.4em'}}>{problem.name}</h4>
                  </a>
                </div>
              </Grid.Column>

              <Grid.Column
                width={3}
                className="no-padding-left-right-bottom"
                textAlign="center"
              >
                <div>
                  {/* <img width={30} height={30} src={userIconImage} /> */}
                  {problem.authorId ? 
                    <a href="#" onClick={() => this.onClickAuthorId(problem.authorId)}>
                      <span size="mini" className="author-name">
                        {problem.authorName}
                      </span>
                    </a> : 
                    <span size="mini" className="author-name">
                      {problem.authorName}
                    </span>
                  }
                </div>
              </Grid.Column>

              <Grid.Column
                width={isLoggedIn() ? 3 : 5}
                className="no-padding-left-right-bottom"
                textAlign="center"
              >
                <div>
                    <img width={25} height={25} src={totalSubmissionImage} />
                    <span style={statisticValue}>{problem.totalSubmission}</span>
                </div>
              </Grid.Column>

              <Grid.Column
                width={isLoggedIn() ? 3 : 4}
                className="no-padding-left-right-bottom"
                textAlign="center"
              >
                <div>
                    <img width={25} height={25} src={totalAcceptedImage} />
                    <span style={statisticValue}>{problem.totalAccepted}</span>
                </div>
              </Grid.Column>
              {!isLoggedIn() ? null : (
                <Grid.Column
                  width={3}
                  className="no-padding-left-right-bottom"
                  style={{ textAlign: "right" }}
                >
                  <StatusLabel
                    status={
                      problem.solvedByLoggedInUser ? "Accepted" : "Unsolved"
                    }
                  />
                </Grid.Column>
              )}
            </Grid>
          </div>
        </Segment>
      );
    });

    const paginationDiv =
      allProblems.length <= itemsPerPage ? null : (
        <div className="pagination-div">
          <Pagination
            className="pagination-custom"
            activePage={this.state.page}
            totalPages={totalPages}
            size='mini'
            siblingRange={1}
            prevItem={false}
            nextItem={false}
            onPageChange={this.setPageNum}
          />
        </div>
      );

    const noDataDiv = (
      <div style={{ textAlign: "center" }}>
        <Icon loading name="spinner" />
      </div>
    );

    return (
      statusCode != null ? (
        <div className="main-container">
          <NotFound statusCode={statusCode} />
        </div>
      ) :
        <div className="main-container">
          <Grid stackable columns={2}>
            <Grid.Column width={4}>
              <div>
                <Segment raised>
                  <ProblemCategoryList category={this.props.data.category} />
                </Segment>
              </div>
            </Grid.Column>
            <Grid.Column width={12}>
              <Segment raised>
                {filterDiv}
              </Segment>
              {problemList.length > 0 ? problemListTable : noDataDiv}
              {paginationDiv}
            </Grid.Column>
          </Grid>
        </div>
    );
  }
}

// Connect with "problem" reducer
const mapStateToProps = (state) => {
  return {
    data: assign({}, state.problem, {
      category: state.category,
      user: state.user,
      header: state.header
    }),
  };
};

// Connect with "ProblemActions"
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(assign(ProblemActions), dispatch);
};

// Connect reducer and action together
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProblemListContainer);
