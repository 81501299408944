import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import assign from "lodash/assign";
import {
  Segment,
  Grid,
  Statistic,
  Icon,
  ListItem,
  ListDescription,
  ListContent,
  List,
  Image
} from "semantic-ui-react";
import * as HomeActions from "../actions/HomeActions";
import top_page_banner from '../top_page_banner.png';
import c_lang_logo from '../assets/icons/language_logo/logo_c.png';
import cpp_lang_logo from '../assets/icons/language_logo/logo_cpp.jpg';
import java_lang_logo from '../assets/icons/language_logo/logo_java_new.png';
import go_lang_logo from '../assets/icons/language_logo/logo_golang.png';
import python_lang_logo from '../assets/icons/language_logo/logo_python.png';
import ruby_lang_logo from '../assets/icons/language_logo/logo_ruby.png';
import bash_lang_logo from '../assets/icons/language_logo/logo_bash.png';
import javascript_lang_logo from '../assets/icons/language_logo/logo_javascript.png';
import typescript_lang_logo from '../assets/icons/language_logo/logo_typescript.png';


class HomeContainer extends Component {
  constructor(props) {
    super(props);
    this.onClickUser = this.onClickUser.bind(this);
    this.state = {
      modal: "",
    };
  }

  componentDidMount() {
    document.title = "CodePanja";
    this.props.fetchStatistics();
  }

  setPageNum = (event, { activePage }) => {
    this.setState({ page: activePage });
  };

  onClickUser(userId) {
    window.location.href = "/users/" + userId;
  }

  render() {
    const statistics = this.props.data;

    const statisticValue = {
      textAlign: "center",
      fontWeight: "520"
    };

    return (
        <div className="main-container">
          <Segment raised>
            <Grid columns={2} stackable>
              <Grid.Row style={{paddingTop: '0px'}}>
                <img src={top_page_banner}></img>
              </Grid.Row>
            </Grid>
            <Grid columns={4} stackable>
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <h2 className="title-name" style={{fontSize: '1.85em', lineHeight: '1.85em'}}>
                    Users
                  </h2>
                  <h2 className="title-name">{statistics.countUser}</h2>
                </Grid.Column>
                <Grid.Column textAlign="center">
                  <h2 className="title-name" style={{fontSize: '1.85em', lineHeight: '1.85em'}}>
                    Problems
                  </h2>
                  <h2 className="title-name">{statistics.countProblem}</h2>
                </Grid.Column>
                <Grid.Column textAlign="center">
                  <h2 className="title-name" style={{fontSize: '1.85em', lineHeight: '1.85em'}}>
                    Contests
                  </h2>
                  <h2 className="title-name">{statistics.countContest}</h2>
                </Grid.Column>
                <Grid.Column textAlign="center">
                  <h2 className="title-name" style={{fontSize: '1.85em', lineHeight: '1.85em'}}>
                    Submissions
                  </h2>
                  <h2 className="title-name">{statistics.countSubmission}</h2>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          <Segment>
            <Grid columns={1} stackable>
              <Grid.Column textAlign="left">
                <h2 className="title-name" style={{fontSize: '1.85em', lineHeight: '1.85em'}}>Supported Languages</h2>
                <List horizontal>
                  <ListItem style={{marginRight: '15px'}}>
                    <Image avatar src={c_lang_logo} style={{fontSize: '10px'}}/>
                    <ListContent>
                      <ListDescription className="base-color">
                        <b>C</b>
                      </ListDescription>
                    </ListContent>
                  </ListItem>
                  <ListItem style={{marginRight: '15px'}}>
                    <Image avatar src={cpp_lang_logo} style={{fontSize: '10px'}}/>
                    <ListContent>
                      <ListDescription className="base-color">
                        <b>C++</b>
                      </ListDescription>
                    </ListContent>
                  </ListItem>
                  <ListItem style={{marginRight: '15px'}}>
                    <Image avatar src={java_lang_logo} style={{fontSize: '10px'}}/>
                    <ListContent>
                      <ListDescription className="base-color">
                        <b>Java</b>
                      </ListDescription>
                    </ListContent>
                  </ListItem>
                  <ListItem style={{marginRight: '15px'}}>
                    <Image avatar src={go_lang_logo} style={{fontSize: '10px'}}/>
                    <ListContent>
                      <ListDescription className="base-color">
                        <b>Go</b>
                      </ListDescription>
                    </ListContent>
                  </ListItem>
                  <ListItem style={{marginRight: '15px'}}>
                    <Image avatar src={python_lang_logo} style={{fontSize: '10px'}}/>
                    <ListContent>
                      <ListDescription className="base-color">
                        <b>Python</b>
                      </ListDescription>
                    </ListContent>
                  </ListItem>
                  <ListItem style={{marginRight: '15px'}}>
                    <Image avatar src={ruby_lang_logo} style={{fontSize: '10px'}}/>
                    <ListContent>
                      <ListDescription className="base-color">
                        <b>Ruby</b>
                      </ListDescription>
                    </ListContent>
                  </ListItem>
                  <ListItem style={{marginRight: '15px'}}>
                    <Image avatar src={javascript_lang_logo} style={{fontSize: '10px'}}/>
                    <ListContent>
                      <ListDescription className="base-color">
                        <b>JavaScript</b>
                      </ListDescription>
                    </ListContent>
                  </ListItem>
                  <ListItem style={{marginRight: '15px'}}>
                    <Image avatar src={typescript_lang_logo} style={{fontSize: '10px'}}/>
                    <ListContent>
                      <ListDescription className="base-color">
                        <b>TypeScript</b>
                      </ListDescription>
                    </ListContent>
                  </ListItem>
                  <ListItem style={{marginRight: '15px'}}>
                    <Image avatar src={bash_lang_logo} style={{fontSize: '10px'}}/>
                    <ListContent>
                      <ListDescription className="base-color">
                        <b>Bash</b>
                      </ListDescription>
                    </ListContent>
                  </ListItem>
                </List>
              </Grid.Column>
            </Grid>
          </Segment>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {data: assign({}, state.statistics) };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(assign(HomeActions), dispatch);
};

// Connect reducer and action together
export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);
