import React from "react";
import { Segment, Table, Icon, Pagination, Button, Dropdown, Popup, Label } from "semantic-ui-react";
import moment from 'moment';
import { COUNTRY } from "../../constants";
import { isLoggedIn, isTeamUser } from "../../utils/cookieUtil";
import DefaultAvatar from "../DefaultAvatar";
import acceptedStatusImage from '../../assets/icons/accepted2.png';
import wrongStatusImage from '../../assets/icons/wrong5.png';
import pendingStatusImage from '../../assets/icons/pending2.png';
import submissionDetailImage from '../../assets/icons/detail_new1.png';
import AvatarWithFlag from "../AvatarWithFlag";

export default class SubmissionList extends React.Component {
  constructor(props) {
    super(props)
    this.onChangeSubmissionDropDown = this.onChangeSubmissionDropDown.bind(this);
    this.onChangeDropDown = this.onChangeDropDown.bind(this);
    this.state = {
      page: 1,
      status: "All",
      of: 'All Submissions'
    };
  }

  componentDidMount() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get("status")) {
      this.setState({ status: urlParams.get("status") })
    }
    if (urlParams.get("of")) {
      this.setState({ of: urlParams.get("of") })
    }
  }

  onChangeSubmissionDropDown(event, data) {
    window.location.href = "/submissions" + "?status=" + this.state.status + "&of=" + data.text;
  }

  onChangeDropDown(event, data) {
    window.location.href = "/submissions" + "?status=" + data.text + "&of=" + this.state.of;
  }

  onClickProblem(problemId, contestId) {
    if (contestId) {
      window.location.href = "/problems/" + problemId + "?contestId=" + contestId;
    } else {
      window.location.href = "/problems/" + problemId;
    }
  };

  onClickUser(userId) {
    if (!isTeamUser()) {
      window.location.href = "/users/" + userId;
    }
  };

  onClickDetail(submissionId) {
    window.location.href = "/submissions/" + submissionId;
  }

  setPageNum = (event, { activePage }) => {
    this.setState({ page: activePage });
  };

  getCountryName(countryCode) {
    const countryInfo = COUNTRY.filter(function (country) { return country.countryCode === countryCode });
    const countryName = countryInfo && countryInfo.length > 0 ? countryInfo[0].name : "";
    return countryName;
  }

  findStatusImage(status) {
    if (status === 'Accepted') {
      return acceptedStatusImage;
    } else if (status === 'Processing') {
      return pendingStatusImage;
    } else {
      return wrongStatusImage;
    }
  }

  render() {
    const { submissions, showFiltering, contestId } = this.props;
    const itemsPerPage = 15;
    const length = submissions ? submissions.length : 0;
    const totalPages = parseInt((length / itemsPerPage) + ((length % itemsPerPage) > 0 ? 1 : 0));
    const items = submissions ? submissions.slice(
      (this.state.page - 1) * itemsPerPage,
      (this.state.page - 1) * itemsPerPage + itemsPerPage
    ) : [];

    const frozenIconDiv =
      <Popup
        trigger={<Icon name={"wait"} color={"yellow"} />}
        content='In Judge Queue'
        size="mini"
      />;

    const memoryDiv = (submission) => submission.status !== 'In Queue' ?
      <div>
        {submission.memory} KB
      </div> : submission.frozen ? frozenIconDiv : <Icon loading name='spinner' color="yellow" />;

    const timeDiv = (submission) => submission.status !== 'In Queue' ?
      <div>
        {submission.time} sec
      </div> : submission.frozen ? frozenIconDiv : <Icon loading name='spinner' color="yellow" />;

    const colStyle = {
      fontSize: "0.8em"
    };

    const submissionList = items.map((submission) => {  
      const avatarDiv = submission.avatarUrl ? 
        <AvatarWithFlag url={submission.avatarUrl} countryCode={submission.countryCode} /> 
        : <DefaultAvatar />;

      return (
        <Table.Body key={submission.id} className="base-color">
          <Table.Row>
            <Table.Cell width={3}>
              <h4 className="text-style">
                {avatarDiv}
                <a href="#" onClick={() => this.onClickUser(submission.userId)}>
                  <span className="author-name">
                    {submission.userName}
                  </span>
                </a>
              </h4>
            </Table.Cell>
            <Table.Cell width={4}>
              <h4 className="text-style">
                <a href="#" onClick={() => this.onClickProblem(submission.problemId, contestId)}>
                  <h4 className="text-style">{submission.problemName}</h4>
                </a>
              </h4>
            </Table.Cell>
            <Table.Cell style={colStyle} width={2}>{submission.language}</Table.Cell>
            <Table.Cell style={colStyle} width={2}>{memoryDiv(submission)}</Table.Cell>
            <Table.Cell style={colStyle} width={2}>{timeDiv(submission)}</Table.Cell>
            <Table.Cell style={colStyle} width={2}>{moment(submission.submittedAt).fromNow()}</Table.Cell>
            <Table.Cell style={{ textAlign: "center" }} width={2}>
              <img className="submission-status-icon-list-page" src={this.findStatusImage(submission.status)} /> 
            </Table.Cell>
            <Table.Cell style={{ textAlign: "right" }} width={2}>
              <a href="#" onClick={() => this.onClickDetail(submission.id)}>
                <img className="submission-detail-icon" src={submissionDetailImage} /> 
              </a>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      );
    });

    const submissionListDiv = submissions && submissions.length > 0 ?
      <Table basic="very" unstackable className="table-scrollable">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className="base-color">User</Table.HeaderCell>
            <Table.HeaderCell className="base-color">Problem</Table.HeaderCell>
            <Table.HeaderCell className="base-color">Language</Table.HeaderCell>
            <Table.HeaderCell className="base-color">Memory</Table.HeaderCell>
            <Table.HeaderCell className="base-color">CPU Time</Table.HeaderCell>
            <Table.HeaderCell className="base-color">Submitted At</Table.HeaderCell>
            <Table.HeaderCell className="base-color" style={{ textAlign: "center" }}>Status</Table.HeaderCell>
            <Table.HeaderCell className="base-color" style={{ textAlign: "right" }}>Detail</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        {submissionList}
      </Table> : null;

    const paginationDiv = (submissions?.length > itemsPerPage) && (
      <div className="pagination-div">
        <Pagination
          className="pagination-custom"
          activePage={this.state.page}
          totalPages={totalPages}
          size='mini'
          siblingRange={1}
          prevItem={false}
          nextItem={false}
          onPageChange={this.setPageNum}
        />
      </div>
    );

    const filterMySubmissionDiv = (
      <Dropdown
        text={this.state.of}
        search selection
        button
        className="tiny filter-dropdown-color-margin-right"
      >
        <Dropdown.Menu>
          <Dropdown.Item className="filter-dropdown-color-item" text="My Submissions" onClick={this.onChangeSubmissionDropDown} />
          <Dropdown.Item className="filter-dropdown-color-item" text="All Submissions" onClick={this.onChangeSubmissionDropDown} />
        </Dropdown.Menu>
      </Dropdown>
    );

    const filterDiv = (
      <Dropdown
        text={this.state.status}
        search selection
        button
        className="tiny filter-dropdown-color-no-margin"
      >
        <Dropdown.Menu>
          <Dropdown.Item className="filter-dropdown-color-item" text="All" onClick={this.onChangeDropDown} />
          <Dropdown.Item className="filter-dropdown-color-item" text="In Queue" onClick={this.onChangeDropDown} />
          <Dropdown.Item className="filter-dropdown-color-item" text="Processing" onClick={this.onChangeDropDown} />
          <Dropdown.Item className="filter-dropdown-color-item" text="Accepted" onClick={this.onChangeDropDown} />
          <Dropdown.Item className="filter-dropdown-color-item" text="Wrong Answer" onClick={this.onChangeDropDown} />
          <Dropdown.Item className="filter-dropdown-color-item" text="Time Limit Exceeded" onClick={this.onChangeDropDown} />
          <Dropdown.Item className="filter-dropdown-color-item" text="Compilation Error" onClick={this.onChangeDropDown} />
          <Dropdown.Item className="filter-dropdown-color-item" text="Runtime Error" onClick={this.onChangeDropDown} />
        </Dropdown.Menu>
      </Dropdown>
    );

    const headerDiv = !showFiltering ? null :
      <div className="foc-header">
        <div className="title">
          <h3 className="title-name" style={{paddingBottom: "5px"}}>Submissions</h3>
        </div>
        <div className="filter-item">
          <span style={{marginRight: '5px'}}>Status</span>
          {filterDiv}
        </div>
        <div className="filter-item">
          {isLoggedIn() ? <><span style={{marginRight: '5px'}}>Type</span>{filterMySubmissionDiv}</> : null}
        </div>
      </div>;

    const noDataDiv =
      <div style={{ textAlign: "center", marginBottom: '20px' }}>
        <h3 className="base-color">Search result is empty.</h3>
      </div>;

    const dataFetchingDiv =
      <div style={{ textAlign: "center" }}>
        <Icon loading name='spinner' />
      </div>;
    
    return (
      <div>
        <Segment raised>
          {headerDiv}
          {submissions ? submissions.length == 0 ? noDataDiv : submissionListDiv : dataFetchingDiv}
        </Segment>
        {paginationDiv}
      </div>
    )
  }
}