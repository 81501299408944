import callAPI from "../utils/callAPI";
import processCallAPIException from "../utils/processCallAPIException";
import { SUBMISSION, HEADER } from "./actions";


export const updatePath = () => {
  return (dispatch) => {
    dispatch({
      type: HEADER.SET_PATH,
      payload: "submissions",
    });
  };
};

export const fetchSubmissions = (params) => {
  return (dispatch) => {
    callAPI("/submissions/search", "GET", params)
      .then((response) => {    
        dispatch({
          type: SUBMISSION.SET_SUBMISSION_LIST,
          payload: response.body.data,
        });
      }).catch((error) => {
        dispatch({
          type: SUBMISSION.SET_STATUS_CODE,
          payload: error.status
        });
      }).catch(processCallAPIException(dispatch));
  };
};

export const fetchSubmissionDetails = (submissionId) => {
  return (dispatch) => {
    callAPI("/submissions/" + submissionId + "/detail", "GET")
      .then((response) => {
        dispatch({
          type: SUBMISSION.SET_SUBMISSION_DETAILS,
          payload: response.body.data,
        });
      })
      .catch((error) => {
      });
  };
};

export const fetchSubmission = (submissionId, params) => {
  return (dispatch) => {
    callAPI("/submissions/" + submissionId, "GET", params)
      .then((response) => {
        dispatch({
          type: SUBMISSION.SET_SUBMISSION_DETAIL,
          payload: response.body.data,
        });
      })
      .catch((error) => {
      });
  };
};
