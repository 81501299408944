import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import assign from "lodash/assign";
import { Button, Form, Header, Icon } from "semantic-ui-react";
import WarningMessage from "../Components/Warning/WarningMessage";
import * as PasswordResetActions from "../actions/PasswordResetActions";

class PasswordResetContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmPassword: "",
      passwordResetError: "",
      token: "",
      resetLoading: false
    };

    this.onChanagePassword = this.onChanagePassword.bind(this);
    this.onChanageConfirmPassword = this.onChanageConfirmPassword.bind(this);
    this.passwordResetClick = this.passwordResetClick.bind(this);
  }

  componentDidMount() {
    document.title = "Password Reset";
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has("t")) {
      const token = urlParams.get("t");
      this.setState({ token: token });
    }
  }

  passwordResetClick = () => {
    if (this.state.password === "" || this.state.confirmPassword === "") {
      this.setState({ passwordResetError: "All fields are required." });
    } else if (this.state.password !== this.state.confirmPassword) {
      this.setState({ passwordResetError: "Password doesn't match." });
    } else if (this.state.token === "") {
      this.setState({ passwordResetError: "URL is incorrect. Please follow the URL sent to email." });
    } else {
      this.setState({ resetLoading: true });
      this.props.resetPassword(this.state.password, this.state.token);
    }
  };

  onChanagePassword = (event) =>
    this.setState({ password: event.target.value });

  onChanageConfirmPassword = (event) =>
    this.setState({ confirmPassword: event.target.value });

  render() {
    const error = (
      <WarningMessage title="Password reset failed!" message={this.state.passwordResetError} />
    );

    const loadingDiv = (
      <div style={{ textAlign: "center" }}>
        <Icon loading name='spinner' />
      </div>
    );

    const passwordResetButton = this.state.resetLoading ? loadingDiv : (
      <div>
        <Button content="Continue" primary fluid onClick={this.passwordResetClick} />
      </div>
    );

    const passwordResetForm = (
      <div>
        <Form.Input
          icon="lock"
          iconPosition="left"
          placeholder="Password"
          type="password"
          value={this.state.password}
          onChange={this.onChanagePassword}
        />
        <Form.Input
          icon="lock"
          iconPosition="left"
          placeholder="Confirm Password"
          type="password"
          value={this.state.confirmPassword}
          onChange={this.onChanageConfirmPassword}
        />
        {passwordResetButton}
      </div>
    );

    return (
      <div className="main-container">
        <div className="login-buttons">
          <div style={{ marginLeft: "30%", marginRight: "30%" }}>
            <Header as="h4" textAlign="center">
              Reset password
            </Header>
            {this.state.passwordResetError !== "" ? error : null}
            <Form>
              {passwordResetForm}
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

// Connect with "RanklistActions"
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(assign(PasswordResetActions), dispatch);
};

// Connect action, no need of reducer
export default connect(null, mapDispatchToProps)(PasswordResetContainer);
