import React, { Component } from "react";
import parse from "html-react-parser";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import assign from "lodash/assign";
import {
  Segment,
  Grid,
  List,
  Statistic,
  Button,
  Form,
  Select,
  Icon,
  Modal,
  Dimmer,
  Loader,
  Popup
} from "semantic-ui-react";

import * as ProblemActions from "../actions/ProblemActions";
import WarningMessage from "../Components/Warning/WarningMessage";
import Countdown from "../Components/Countdown";
import CountdownFinished from "../Components/CountdownFinished";
import NotFound from "../Components/Error/NotFound";
import { isLoggedIn } from "../utils/cookieUtil";

import { LANGUAGE } from "../constants";
import Ide from "../Components/Problem/Ide";
import DocumentViewer from "../Components/PDF/DocumentViewer";

class ProblemDetailContainer extends Component {
  constructor(props) {
    super(props);
    this.onOpenIDE = this.onOpenIDE.bind(this);
    this.onCloseIDE = this.onCloseIDE.bind(this);
    this.onSubmitFromIDE = this.onSubmitFromIDE.bind(this);

    this.state = {
      showFileSizeError: false,
      showLanguageMissingError: false,
      showFileMissingError: false,
      showIsLoggedInError: false,
      languageId: "",
      contestId: "",
      problemId: "",
      isSubmitted: false,
      languageNote: '',
      ideOpened: false,
      screenClientWidthBeforeIdeOpen: null
    };
  }

  componentDidMount() {
    this.props.updatePath();
    const pathname = window.location.pathname;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const problemId = pathname.substring(pathname.lastIndexOf("/") + 1);
    const user = this.props.data.user;

    this.setState({ problemId: problemId });

    if (urlParams.has("contestId")) {
      const contestId = urlParams.get("contestId");
      this.setState({ contestId: contestId });
      this.props.fetchProblemForContest(problemId, contestId);
    } else if (user && user.permission === '10') {
      this.props.fetchProblemForAdminUser(problemId, user.loggedInUserId);
    } else {
      this.props.fetchProblem(problemId);
    }
  }

  componentWillReceiveProps(nextProps) {
    const detail = nextProps.data.detail;
    if (detail) {
      document.title = 'Problems | ' + detail.name;
    }
  }

  fileInputRef = React.createRef();

  onFormSubmit = (e) => {
    e.preventDefault(); // Stop form submit
    // this.state.file contains the file
  };

  fileChange = (e) => {
    this.setState(
      { file: e.target.files[0], fileSizeInKB: e.target.files[0].size / 1024 },
      () => {
        if (this.state.fileSizeInKB > 10000) {
          this.setState({ showFileSizeError: true });
          this.setState({ showFileMissingError: true });
        } else {
          this.setState({ showFileMissingError: false });
        }
      }
    );
  };

  setFileSizeErrorModalOpen(flag) {
    this.setState({ showFileSizeError: flag });
  }

  onOpenIDE() {
    this.setState({ ideOpened: true });
    this.setState({ screenClientWidthBeforeIdeOpen: document.getElementById("containerId").clientWidth });
    document.getElementById("containerId").style.width = (window.screen.width * 90) / 100 + "px";
  }

  onCloseIDE() {
    this.setState({ ideOpened: false });
    document.getElementById("containerId").style.width = this.state.screenClientWidthBeforeIdeOpen + "px";
  }

  onSubmitFromIDE = (code, languageId) => {
    const problem = this.props.data.detail;
    const data = {
      code: code,
      languageId: languageId,
      problemId: this.state.problemId,
      contestId: this.state.contestId,
      isTest: problem.shouldTreatSubmissionAsTest ? '1' : '0',
      isSampleTest: '0'
    };
    this.props.submitSolutionFromIDE(data);
  }

  onSubmitSolution(problemId, userId) {
    const problem = this.props.data.detail;
    if (!isLoggedIn()) {
      this.setState({ showIsLoggedInError: true });
    } else if (this.state.fileSizeInKB > 10000) {
      this.setState({ showFileSizeError: true });
    } else if (this.state.languageId === "") {
      this.setState({ showLanguageMissingError: true });
    } else if (!this.state.file) {
      this.setState({ showFileMissingError: true });
    } else {
      const data = {
        sourceFile: this.state.file,
        problemId: problemId,
        contestId: this.state.contestId,
        userId: userId,
        languageId: this.state.languageId,
        isTest: problem.shouldTreatSubmissionAsTest ? '1' : '0'
      };
      this.setState({ isSubmitted: true });
      this.props.submitProblem(data);
    }
  }

  onRunSamples = (code, languageId) => {
    const problem = this.props.data.detail;
    const data = {
      code: code,
      languageId: languageId,
      problemId: this.state.problemId,
      contestId: this.state.contestId,
      isTest: problem.shouldTreatSubmissionAsTest ? '1' : '0',
      isSampleTest: '1'
    };
    this.props.cleanPrevData();
    this.props.submitSolutionFromIDE(data);
  }

  fetchSampleTestRunData = () => {
    if (this.props.data.sampleTestRunSubmissionId) {
      this.props.getSampleRunData(this.props.data.sampleTestRunSubmissionId);
    }
  }

  findProblemStatusName(statusId) {
    if (statusId === 10) {
        return 'Public';
    } else if (statusId === 15) {
        return 'Contest only';
    } if (statusId === 50) {
        return 'Admin review pending';
    } else {
        return 'Hidden';
    }
  }

  onClickAllSubmissions(problemId) {
    window.location.href = "/submissions?problemId=" + problemId;
  }

  onClickMySubmissions(problemId, userId) {
    window.location.href =
      "/submissions?problemId=" + problemId + "&userId=" + userId;
  }

  onClickContest(contestId) {
    window.location.href = "/contests/" + contestId;
  }

  onClickAuthorId(authorId) {
    window.location.href = "/users/" + authorId;
  }

  handleChange = (e, result) => {
    const { value } = result;
    this.setState({ languageId: value, showLanguageMissingError: false });
    if (value === 62) {
      this.setState({ languageNote: "The Class name has to be 'Main' for Java" })
    } else {
      this.setState({ languageNote: '' })
    }
  };

  render() {
    const user = this.props.data.user;
    const problem = this.props.data.detail;
    const name = problem != null ? problem.name : "";
    const description = problem != null ? problem.description : "";
    const contest = problem != null ? problem.contestDto : null;
    const contestName =
      problem != null && problem.contestDto != null
        ? problem.contestDto.name
        : "";
    const isEnded =
      problem != null && problem.contestDto != null
        ? problem.contestDto.ended
        : false;
    const memoryLimit = problem != null ? problem.memoryLimit : "";
    const timeLimit = problem != null ? problem.timeLimit : "";
    const testCases = problem != null ? problem.sampleTestCaseList : [];
    const statusCode = this.props.data.statusCode;
    const authorId = problem != null ? problem.authorId : "";
    const authorName = problem != null ? problem.authorName : "";

    const statisticStyle = {
      fontWeight: "700",
      color: "#C4C5C6",
      fontSize: ".8em",
      textAlign: "center",
    };

    const listStyle = {
      borderTop: "1px solid #f9fafc",
      paddingTop: "5px",
      paddingBottom: "5px",
    };

    const fileSizeErrorModal = (
      <Modal
        closeIcon
        open={this.state.showFileSizeError}
        onClose={() => this.setFileSizeErrorModalOpen(false)}
        onOpen={() => this.setFileSizeErrorModalOpen(true)}
        size="tiny"
        dimmer="blurring"
      >
        <Modal.Content>
          <WarningMessage
            title="File size error"
            message="Please submit a file less than 200 KB"
          />
        </Modal.Content>
      </Modal>
    );

    const sampleTestCases = testCases.map((testCase, index) => {
      return (
        <div key={index}>
          <div>
            <strong>{"Sample Input #" + (index + 1)} </strong>
          </div>
          <div>
            <pre>{testCase.stdin}</pre>
          </div>

          <div>
            <strong>{"Sample Output #" + (index + 1)} </strong>
          </div>
          <div>
            <pre>{testCase.stdout}</pre>
          </div>
          <div>
            {testCase.explanation ? (
              <div>
                <Icon color="brown" name="angle double up" />
                <strong>Explanation</strong>
                <div className="testcase-explanation">
                  {testCase.explanation}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      );
    });

    const ListExampleLink = (
      <>
        {!isLoggedIn() ? null : (
          <Button
            className="button-style"
            style={{ width: "100%", marginBottom: '10px'}}
            onClick={() =>
              this.onClickMySubmissions(problem.id, user.loggedInUserId)
            }
          >
            My Submissions
          </Button>
        )}
        <Button
            className="button-style"
            style={{ width: "100%"}}
            onClick={() =>
              this.onClickAllSubmissions(problem.id)
            }
          >
            All Submissions
          </Button>
        </>
    );

    const languageOptions = LANGUAGE;

    const submitProblemDiv = (
      <Select
        placeholder="Select Language"
        options={languageOptions}
        onChange={this.handleChange}
        className="filter-select-color"
      />
    );

    const submitFileNameDiv =
      this.state.file == null ? null : (
        <div style={{ marginTop: "10px" }}>
          <Icon color="brown" name="angle double up" /> {this.state.file.name}
        </div>
      );

    const submitFileDiv = this.state.isSubmitted ?
      <Dimmer active inverted>
        <Loader inverted>Submitting</Loader>
      </Dimmer> : (
        <div style={{ marginTop: "10px" }}>
          <Form onSubmit={this.onFormSubmit}>
            <Form.Field>
              <Button
                className="button-style"
                id="file-upload-button"
                size="medium"
                content="Source Code File"
                labelPosition="left"
                icon="file"
                onClick={() => this.fileInputRef.current.click()}
              />
              <input
                ref={this.fileInputRef}
                type="file"
                hidden
                onChange={this.fileChange}
              />
            </Form.Field>
          </Form>
        </div>
      );

    const contestTimerDiv = isEnded ? (
      <CountdownFinished textAlign={true} />
    ) : contest ? (
      <Countdown contest={contest} />
    ) : null;

    const topLeftDiv =
      this.state.contestId === "" ? (
        <Segment raised>
          <div className="problem-submissions-div">
            <h3 className="text-style" style={{ textAlign: "center", fontWeight: 'bold', paddingBottom: '10px' }}>
              Submissions
            </h3>
            {ListExampleLink}
          </div>
        </Segment>
      ) : (
        <Segment raised>
          <div className="problem-submissions-div">
            <h3
              className="text-style"
              style={{
                textAlign: "center",
                paddingBottom: "5px",
                borderBottom: "1px solid #f3f5f8",
              }}
            >
              Contest
            </h3>
            <h4
              className="text-style"
              style={{
                textAlign: "center",
                paddingBottom: "5px",
                borderBottom: "1px solid #f3f5f8",
              }}
            >
              <a
                style={{ color: "#C4C5C6" }}
                href="#"
                onClick={() => this.onClickContest(this.state.contestId)}
              >
                {contestName}
              </a>
            </h4>
            <div style={{ textAlign: "center" }}>{contestTimerDiv}</div>
          </div>
        </Segment>
      );

    const languageMissingError = !this.state.showLanguageMissingError ? null : (
      <div style={{ marginTop: 5, color: "#C4C5C6" }}>
        <Icon name="hand point right" />
        Please select a language
      </div>
    );

    const fileMissingError = !this.state.showFileMissingError ? null : (
      <div style={{ marginTop: 5, color: "#C4C5C6" }}>
        <Icon name="hand point right" />
        Please select a file
      </div>
    );

    const notLoggedInError = !this.state.showIsLoggedInError ? null : (
      <div style={{ marginTop: 5, color: "#C4C5C6" }}>
        <Icon name="hand point right" />
        Please login to submit
      </div>
    );

    const languageNoteDiv = !this.state.languageNote ? null : (
      <div style={{ marginTop: 5, color: "#C4C5C6" }}>
        <Icon name="hand point right" />
        {this.state.languageNote}
      </div>
    );

    const problemSubmitDiv =
      isEnded && this.state.contestId !== "" ? null : (
        <Segment raised>
          <div className="problem-submit-div">
            <h3 className="text-style" style={{ textAlign: "center" }}>
              Submit
            </h3>
            {submitProblemDiv}
            {submitFileDiv}
            {submitFileNameDiv}
            <div style={{ marginTop: "10px" }}>
              <Button
                className="button-style"
                id="solution-submit-button"
                style={{ background: "#ededed" }}
                size="medium"
                content="Submit"
                labelPosition="left"
                icon="cloud upload"
                onClick={() =>
                  this.onSubmitSolution(problem.id, user.loggedInUserId)
                }
              />
            </div>
            {languageNoteDiv}
            {notLoggedInError}
            {languageMissingError}
            {fileMissingError}
          </div>
        </Segment>
      );

    const isTestSubmissionDiv =
      problem && problem.shouldTreatSubmissionAsTest ? (
        <>
        <Segment raised style={{ background: "#E39A9A" }}>
          <p>Problem Status : {this.findProblemStatusName(problem.status)}</p>
        </Segment>
        <Segment raised style={{ background: "#E39A9A" }}>
          <p>You are the Author of this problem. Submission will be considered as test</p>
        </Segment>
        </>
      ) : null;

    const ideButtonDiv = isEnded ? null :
      <Button 
        className="button-style"
        icon 
        labelPosition='left'
        onClick={() =>
          this.onOpenIDE()
        }
      >
        <Icon name='codepen' />
        Code Editor
      </Button>;


    const leftDiv = this.state.ideOpened ? null :
      <Grid.Column width={3}>
        {topLeftDiv}
        {problemSubmitDiv}
        {isTestSubmissionDiv}
        <div style={{textAlign: 'center'}}>{ideButtonDiv}</div>
      </Grid.Column>;

    const ideDiv = !this.state.ideOpened ? null :
      <Ide
        userId={this.props.data.user.loggedInUserId}
        problemId={this.state.problemId}
        sampleTestRunData={this.props.data.sampleTestRunData}
        fetchSampleTestRunData={() => this.fetchSampleTestRunData()}
        isLoggedIn={isLoggedIn()}
        onCloseIDE={this.onCloseIDE}
        contestEnded={isEnded}
        onRunSamples={(code, languageId) => this.onRunSamples(code, languageId)}
        onSubmitFromIDE={(code, languageId) => this.onSubmitFromIDE(code, languageId)}
        shouldTreatSubmissionAsTest={problem && problem.shouldTreatSubmissionAsTest}
        problemStatusName={this.findProblemStatusName(problem.status)}
      />;

    const renderDiv =
      statusCode != null ? (
        <NotFound statusCode={statusCode} />
      ) : memoryLimit === "" ? (
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      ) : (
        <Grid stackable columns={this.state.ideOpened ? 1 : 2}>
          {leftDiv}
          <Grid.Column width={this.state.ideOpened ? 8 : 13}>
            <Segment raised>
              <Grid>
                <Grid.Column width={6}>
                  <div style={{marginBottom: '5px'}}>
                    <h2 className="text-style">{name}</h2>
                  </div>
                </Grid.Column>
                <Grid.Column width={10} textAlign="right">
                    {authorId ? 
                      <>
                        <div style={{marginBottom: '5px'}}>Created By</div>
                        <a href="#" onClick={() => this.onClickAuthorId(authorId)}>
                          <span size="mini" className="author-name">
                            {problem.authorName}
                          </span>
                        </a>
                      </> : null
                    }
                </Grid.Column>
              </Grid>
              {fileSizeErrorModal}
              <div>
                <Popup
                  trigger={
                    <div style={{paddingTop: '3px', paddingBottom: '3px'}}>
                      <span className="limit">
                        Limits : {timeLimit}s, {memoryLimit} MB
                      </span>
                    </div>
                  }
                  content={'Time: ' + timeLimit + ' sec, Memory : ' + memoryLimit + ' MB'}
                  size='mini'
                />
              </div>
      
              <div className="border-1px" style={{ marginTop: "10px"}}></div>

              {problem.docFile ?
                <DocumentViewer 
                  uri={problem.docFile} 
                  fileType='pdf'
                  name={problem.name}
                /> 
                : 
                description &&
                  <div>
                    <div className="problem-description">{parse(description)}</div>
                    <div className="sample-test-case">{sampleTestCases}</div>
                  </div>
              }
            </Segment>
          </Grid.Column>
          {ideDiv}
        </Grid>
      );

    return <div className="main-container">{renderDiv}</div>;
  }
}

// Connect with "problem" reducer
const mapStateToProps = (state) => {
  return { data: assign({}, state.problem, { user: state.user }) };
};

// Connect with "ProblemActions"
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(assign(ProblemActions), dispatch);
};

// Connect reducer and action together
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProblemDetailContainer);
